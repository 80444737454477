{
    "REACH OUT TO US": "REACH OUT TO US",
    "HOTLINE": "HOTLINE",
    "INQUIRY": "INQUIRY",
    "ADDRESS": "ADDRESS",
    "UPCOMING LEASING EVENT CONTACT": "UPCOMING LEASING EVENT",
    "SEND A MESSAGE": "SEND A MESSAGE",
    "NAME": "NAME",
    "SALUTATION": "SALUTATION",
    "EMAIL": "EMAIL",
    "PHONE NO.": "PHONE NO.",
    "YOUR MESSAGE": "YOUR MESSAGE",
    "SEND": "SEND",
    "PRIVACY POLICY": "privacy policy.",
    "POLICY CONTENT": "By checking this box, you have agreed to our ",
    "PRIVACY POLICY CUSTOM": "Blank"
}
