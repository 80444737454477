import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { HeroLanding, LeasingDisplay, LeasingJustifyContent, LeasingParagraphsHero, LeasingDivElement } from "../../../style/LeasingScreen";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const HeroMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);
    return (
        <HeroLanding id="hero" bgurl="3-hero.png">
            <LeasingDisplay padding={"150px 0px 0px 0px"}>
                <LeasingDivElement>
                    <LeasingParagraphsHero languagecode={currentLanguageCode} stroke={"1px #1b2e59"} color={"#CBE7F2"} fsize={"47px"} fweight={"700"} linehg={"57px"} shadow={"0px 2px 4px #353535"}>
                        {parse(t("SEIZE THE SPACE EMPOWER YOUR VISION"))}
                    </LeasingParagraphsHero>
                </LeasingDivElement>
                <LeasingJustifyContent padding={"50px 0px 0px 0px"} tablet={"10px 0"}>
                    <Link to="/contact">
                        <ContactUsButtonComponent text={t("CONTACT US")} color={"#F4F4F4"} />
                    </Link>
                </LeasingJustifyContent>
            </LeasingDisplay>
        </HeroLanding>
    );
};

export default HeroMobile;
