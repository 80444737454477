import styled from "styled-components";
import { Carousel as CarouselSlide } from "antd";

export const VirtualTourDisplay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const VirtualTourGrid = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    padding: 0px 20px 30px 20px;
`;

export const VirtualTourLanding = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

export const HeadLineWrapper = styled.span`
    background-color: #fb0946;
    color: #f4f4f4;
    font-family: "Barlow, Helvetica";
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    white-space: nowrap;
    letter-spacing: 0;
    text-align: center;
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ContentWrapper = styled.div`
    width: 293px;
    height: 193px;
    display: flex;
    gap: ${(props) => props.gap};
    background-color: ${(props) => props.bgcolor};
    border: ${(props) => props.border};
`;

export const TitleWrapper = styled.div`
    color: ${(props) => props.color};
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-weight: ${(props) => props.fweight ?? "700"};
    font-size: ${(props) => props.fsize};
    line-height: ${(props) => props.lineheight};
    white-space: ${(props) => props.wspace ?? "nowrap"};
    letter-spacing: 0;
    padding: ${(props) => props.padding};
    text-align: ${(props) => props.align};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "47px"};
        line-height: ${(props) => props.tablet ?? "47px"};
    }
`;

export const VirtualTourGridItem = styled.div`
    width: 100%;
    height: 100px;
    background-color: ${(props) => props.bgcolor};

    @media (min-width: 768px) and (max-width: 1279px) {
        width: ${(props) => props.tablet ?? "100%"};
        height: ${(props) => props.tablet ?? "300px"};
    }
`;

export const VirtualTourImage = styled.img`
    height: ${(props) => props.height};
    left: ${(props) => props.left};
    object-fit: ${(props) => props.fit ?? "cover"};
    top: ${(props) => props.top};
    width: ${(props) => props.width};
    align-self: ${(props) => props.self};
    position: ${(props) => props.position};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    z-index: ${(props) => props.zindex};
    transform: ${(props) => props.transform};
    border: ${(props) => props.border};
    margin: ${(props) => props.margin};
    transition: transform 0.3s;

    @media (min-width: 768px) and (max-width: 1279px) {
        width: ${(props) => props.wtablet ?? "100%"};
        height: ${(props) => props.htablet ?? "300px"};
    }
`;

export const ImageWrapperHover = styled.div`
    width: ${(props) => props.width ?? "293px"};
    height: ${(props) => props.height ?? "193px"};
    background-size: cover;
    display: flex;
    justify-content: ${(props) => props.justify};
    align-items: ${(props) => props.items};
    background-image: url(${(props) => props.bgurl});
    background-color: ${(props) => props.bgcolor};
    transition: transform 0.3s;
    transform: scale(1);
    object-fit: cover;
    @media (max-width: 360px) {
        margin: 0;
        justify-content: center;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
        width: ${(props) => props.tablet ?? "100%"};
        height: ${(props) => props.tablet ?? "300px"};
    }

    & div {
        transform: scale(1.1);
        opacity: 0;
        transition: transform 0.3s;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: #f4f4f4;
        justify-content: center;
    }

    &:hover {
        transform: scale(1.1);
    }

    &:hover div {
        opacity: 1;
    }
`;

export const ImageContainer = styled.div`
    transition: transform 0.3s;
    cursor: pointer;
    overflow: hidden;
    &:hover img {
        transform: scale(1.1);
    }
    &:hover div {
        transform: scale(1.1);
    }
`;
export const GalleryContent = styled.div`
    width: ${(props) => props.width ?? "1366px"};
    height: ${(props) => props.height ?? "auto"};
    display: flex;
    flex-wrap: wrap;
    padding: ${(props) => props.padding};
    margin-bottom: ${(props) => props.margin};
    background-size: cover;
    gap: 10px;
`;

export const SliderShowVirtualTour = styled(CarouselSlide)`
    position: relative;
    .slick-dots li.slick-active button {
        background: #154dac;

        width: 50px;
    }
    .slick-dots li button {
        background: #444444;
        width: 50px;
    }
    .slick-dots li {
        width: 50px;
    }
    .slick-dots li.slick-active {
        width: 50px;
    }
    .slick-dots {
        margin: 25px 0 0 0 !important;
        position: relative !important;
    }
`;

export const ModalContent = styled.div`
    position: relative;
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    display: flex;
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    text-align: center;
    background-color: #353535;
    box-shadow: 0px 2px 4px #353535;
    margin: 0 0 40px 0;
    cursor: pointer;
`;

export const ButtonCloseSlider = styled.div`
    padding: 3px 0 0 0;
    width: 130px;
    height: 40px;
    box-shadow: 0px 2px 4px #353535;
    background-color: #858585;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    font-family: "Barlow, Helvetica";
    letter-spacing: 0px;
    color: #f4f4f4;
    opacity: 1;
`;
