import React, { useState } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import * as MobileSections from "./Mobile.Sections";
import { useWindowWidth } from "../../breakpoints";

const LeasingScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };

    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight}px`}>
                    <Sections.HeroSection onHeightChange={handleHeightChange} />
                    <Sections.OfficeSection onHeightChange={handleHeightChange} />
                    <Sections.RetailSection onHeightChange={handleHeightChange} />
                </DesktopLayout>
            ) : (
                <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                    <MobileSections.HeroMobile />
                    <MobileSections.OfficeMobile />
                    <MobileSections.RetailMobile />
                </DesktopLayout>
            )}
        </>
    );
};

export default LeasingScreen;
