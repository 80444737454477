import axios from "./customize-axios";

const getMatterPort = () => {
    return axios.get(`/api/VirtualTour/getMatterPort`);
};

const getGallery = () => {
    return axios.get(`/api/VirtualTour/getGallery`);
};

export { getMatterPort, getGallery };
