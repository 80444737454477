{
    "SEIZE THE SPACE EMPOWER YOUR VISION": "SEIZE THE SPACE,<br />EMPOWER<br />YOUR VISION",
    "OFFICE SPACE FEATURES": "オフィスの特徴",
    "2.7m of Clearance Ceiling Height Allows Better Air Circulation": "2.7m の天井高さによる広々空間",
    "Column Free Layout Maximizes Space use Efficiency": "無住レイアウトで実現する最大限の空間利用",
    "Air Conditioning System with Air Quality Control": "室内空気環境を整える<br />空調システム",
    "LED Panel Lighting on Finished Concealed Ceiling": "LED パネル照明を搭載したシステム天井",
    "100m Raised Floor System Eases Out Renovation/ Alteration": "改修を容易にする <br /> OA フロアシステム",
    "100% Power Backup for Seamless Operation": "100％ バックアップ可能な非常用電源",
    "TEST FIT": "オフィス内装イメージ",
    "SPECS SHEET": "オフィス仕様書",
    "CONTACT US": "お問い合わせ",
    "RETAIL SPACE": "リテール"
}
