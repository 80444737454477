import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DeveloperImage, DeveloperWrapper, HeadLineWrapper, ParagraphWrapper } from "../../../style/DeveloperScreen";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { useTranslation } from "react-i18next";

const DevelopmentSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    return (
        <DeveloperWrapper id="developer" height={"870px"} margin={"60px 0 0 0"} direction={"column"} bgcolor={"#f4f4f4"} width={"100%"} align={"center"} ref={sectionRef}>
            <DeveloperWrapper width={"798px"} direction={"column"} align={"center"} margin={"74px 0 0 0"}>
                <HeadLineWrapper languagecode={currentLanguageCode} color={"#2C53AF"} align={"center"} wspace={"nowrap"} fsize={"47px"} linehg={"57px"} fweight={"700"}>
                    {t("TAISEI DEVELOPMENT HANOI")}
                </HeadLineWrapper>
                <DeveloperWrapper margin={"22px 0 0 0"} width={"100%"} height={"100%"} bgcolor={"#fff"} border={"solid 1px #707070"}>
                    <DeveloperImage className="thd-team" src="img/THD-team.png" height={"100%"} fit={"cover"} transform={""} />
                </DeveloperWrapper>
                <ParagraphWrapper languagecode={currentLanguageCode} margin={"25px 0 0 0"} align={"justify"} fsize={"15px"} linehg={"24px"}>
                    {t("TAISEI DEVELOPMENT HANOI CONTENT")}
                </ParagraphWrapper>
                <DeveloperWrapper margin={"50px 0px"}>
                    <Link to="/contact">
                        <ContactUsButtonComponent width={"190px"} text={t("CONTACT US")} hovered />
                    </Link>
                </DeveloperWrapper>
            </DeveloperWrapper>
        </DeveloperWrapper>
    );
};

DevelopmentSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default DevelopmentSection;
