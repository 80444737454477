{
    "Taisei Corporation": "Tập đoàn Taisei",
    "Taisei Content": "Tập đoàn Taisei là một trong 5 nhà thầu xây dựng lớn hàng đầu Nhật Bản với 150 năm lịch sử. Trải qua quá trình hình thành và lớn mạnh của mình, Taisei đã phát triển và xây dựng nhiều dự án quy mô lớn ở Nhật Bản cũng như trên thế giới. <p style='padding-top: 10px'>Năm 2020, Taisei công bố dự án đầu tư đầu tiên vốn 100% tại Việt Nam, tòa tháp văn phòng Taisei Square Hanoi - được thực hiện hoàn toàn từ phát triển, thiết kế và xây dựng bởi các công ty thành viên của tập đoàn.</p>",
    "NOTABLE ACHIEVEMENTS IN VIETNAM": "THÀNH TỰU TẠI VIỆT NAM",
    "Developed and Constructed by Taisei": " Phát triển và Xây dựng",
    "ROYGENT PARKS HANOI": "ROYGENT PARKS HANOI",
    "ROYGENT PARKS HANOI CONTENT": "Khối khách sạn và căn hộ dịch vụ  24 tầng được phát triển cùng với một đối tác Nhật Bản khác, cho tới nay đã và đang giới thiệu, cung cấp dịch vụ lưu trú chất lượng Nhật Bản tới khách hàng.",
    "T2 NOI BAI INTL AIRPORT": "NHÀ GA T2 SÂN BAY QUỐC TẾ NỘI BÀI",
    "T2 NOI BAI INTL AIRPORT CONTENT": "Nhà ga mới với công suất phục vụ 15 triệu hành khách mỗi năm được dành riêng cho tất cả các đường bay quốc tế đến và đi từ Hà Nội. Taisei tham gia với tư cách là một trong những nhà thầu chính cho dự án ODA này.",
    "Constructed by Taisei": "Thi công",
    "CAN THO BRIDGE": "CẦU CẦN THƠ",
    "CAN THO BRIDG CONTENT": "Cây cầu dây văng lớn nhất khu vực, biểu tượng của Cần Thơ, như một dải lụa mềm vắt qua sông Hậu được Tập đoàn Taisei và 2 nhà thầu Nhật Bản nữa thực hiện.",
    "NOTABLE ACHIEVEMENTS IN JAPAN": "THÀNH TỰU TẠI NHẬT BẢN",
    "THE OKURA TOKYO": "THE OKURA TOKYO",
    "THE OKURA TOKYO CONTENT": "Được xây lại ở chính địa điểm nơi khách sạn Okura đầu tiên đã khai trương vào năm 1962, Okura Tokyo vẫn là kết tinh của lòng hiếu khách và sự sang trọng của người Nhật. Taisei giữ vai trò chủ đạo trong việc tái kiến thiết địa điểm lịch sử này.",
    "OCHANOMIZU SOLACITY": "OCHANOMIZU SOLA CITY",
    "OCHANOMIZU SOLACITY CONTENT": "Được thiết kế và xây dựng bởi Taisei, Sola City là công trình phức hợp thương mại - văn phòng cao 23 tầng với vẻ ngoài toát lên sự thông minh, thiết kế không rào cản thực sự dành cho mọi đối tượng sử dụng khu vực Ochanomizu giàu truyền thống văn hóa học thuật.",
    "NATIONAL STADIUM": "SÂN VẬN ĐỘNG QUỐC GIA",
    "NATIONAL STADIUM CONTENT": "Sân vận động quốc gia mới của Nhật Bản, thiết kế bởi kiến trúc sư nổi tiếng Kuma Kengo và được Taisei xây dựng trên chính địa điểm cũ. Gỗ được sử dụng làm thành phần kết cấu chính, và những cấu kiện tự nhiên này được lấy từ khắp 47 tỉnh thành của Nhật Bản.",
    "TAISEI DEVELOPMENT HANOI": "CÔNG TY PHÁT TRIỂN TAISEI HÀ NỘI",
    "TAISEI DEVELOPMENT HANOI CONTENT": "Công ty con thuộc Tập đoàn Taisei Nhật Bản - nhà phát triển đồng thời là đơn vị quản lý, vận hành tòa Taisei Square Hanoi. Quy mô tuy nhỏ nhưng nơi đây quy tụ đội ngũ nhân viên Nhật Bản và Việt Nam đầy tâm huyết, đã và đang nỗ lực hết mình để mang đến chất lượng đích thực và sự chuyên nghiệp của Nhật Bản tới khách hàng tại Việt Nam.",
    "OUR PARTNERS": "ĐỐI TÁC",
    "Main Contractor & Architect": "Nhà thầu thiết kế và thi công",
    "Design Consultant": "Tư vấn thiết kế",
    "M&E Contractor KINDEN": "Nhà thầu cơ điện",
    "M&E Contractor YURTEC": "Nhà thầu cơ điện",
    "Project Management & Site Supervision": "Tư vấn giám sát <br /> và Quản lý dự án",
    "Strategic Leasing & Marketing Consultant": "Tư vấn chiến lược <br /> cho thuê và tiếp thị",
    "Property Management Support": "Tư vấn quản lý <br /> tòa nhà",
    "Security Service": "Dịch vụ bảo vệ",
    "Cleaning Service": "Dịch vụ vệ sinh",
    "Developed and": "Đã phát triển và",
    "CONTACT US": "LIÊN HỆ",
    "EXPLORE": "EXPLORE"
}
