import "../../style/style.css";
import PropTypes from "prop-types";
import { FooterMenu, FooterContent, ParagraphsContent, SpanContent, IconWrapper, FooterIcon } from "../../style/Footer";
import { useEffect, useState } from "react";
import { getSocialNetworkContact } from "../../api/contact";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer({ screenWidth }) {
    const [dataSocial, setDataSocial] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const getDataSocialNetwork = async () => {
        const getData = await getSocialNetworkContact();
        if (getData) {
            setDataSocial(getData);
            setDataLoaded(true);
        }
    };
    useEffect(() => {
        if (!dataLoaded) {
            getDataSocialNetwork();
        }
    }, [dataLoaded]);

    return (
        <FooterMenu bottom={screenWidth >= 1280 ? "0" : ""} height={screenWidth >= 1280 ? "140px" : "230px"}>
            <FooterContent>
                <FooterIcon>
                    <Link>
                        <IconWrapper iconimage={"path-1.png"} onClick={() => window.open(dataSocial.zaloUrl, "_blank")} />
                    </Link>
                    <Link>
                        <IconWrapper iconimage={"path-2.png"} onClick={() => window.open(dataSocial.facebookUrl, "_blank")} />
                    </Link>
                    <Link>
                        <IconWrapper iconimage={"path-3.png"} onClick={() => window.open(dataSocial.linkedInUrl, "_blank")} />
                    </Link>
                </FooterIcon>
                <ParagraphsContent>
                    <SpanContent className="text-footer">
                        Copyright © Taisei Square Hanoi All Rights Reserved
                        {/* <br />
                        Last updated: {buildDateTime ? buildDateTime : ""} */}
                    </SpanContent>
                </ParagraphsContent>
            </FooterContent>
            <FooterContent width={screenWidth >= 1280 ? "1202px" : "100%"} height={screenWidth >= 1280 ? "40px" : "auto"}>
                <ParagraphsContent languagecode={currentLanguageCode} wspace={""}>
                    <SpanContent fweight={"700"}>{t("Disclaimer")}: </SpanContent>
                    <SpanContent fweight={"400"}>{t("ContentFooter")}</SpanContent>
                </ParagraphsContent>
            </FooterContent>
        </FooterMenu>
    );
}

Footer.propTypes = {
    screenWidth: PropTypes.number
};

export default Footer;
