{
    "NEWS & EVENTS": "NEWS & EVENTS",
    "NEWS": "NEWS",
    "PAST NEWS": "PAST NEWS",
    "READ MORE": "READ MORE",
    "PREV": "PREV",
    "NEXT": "NEXT",
    "UPCOMING LEASING EVENT": "UPCOMING LEASING EVENT",
    "PAST EVENTS": "PAST EVENTS"
}
