import React, { useRef, useEffect } from "react";
import { HeroLanding, HeroPTag, HeroWrapper, HeroTitle, HeroTextWrapper, OverviewButtonDisplay, ContactButtonComponent } from "../../../style/OverviewScreen";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeroSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 500);
        }
    };

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <HeroLanding id="hero" ref={sectionRef} position="relative" bgurl="1building_hero.png">
            <HeroWrapper>
                <HeroTextWrapper>
                    <HeroTitle>TAISEI SQUARE HANOI</HeroTitle>
                    <HeroPTag>{t("A Japanese dedication to the first LEED certified Grade A office building in West Hanoi ")}</HeroPTag>
                </HeroTextWrapper>
                <OverviewButtonDisplay>
                    <Link to="/leasing#office" onClick={() => scrollToSection("office")}>
                        <ContactButtonComponent>{t("EXPLORE")}</ContactButtonComponent>
                    </Link>
                </OverviewButtonDisplay>
            </HeroWrapper>
        </HeroLanding>
    );
};

HeroSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default HeroSection;
