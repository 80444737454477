import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import parse from "html-react-parser";
import { sustainabilitys } from "../Data/sustainabilitys";
import { animated } from "@react-spring/web";
import { TextAnimation, TextAnimationOnClick } from "../../../breakpoints";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { FadeInOut, HoverImageZoom, TheBuildingSectionWrapper, TheBuildingContent, TheBuildingJustifyContent, TheBuildingPositionDisplay, TheBuildingParagraphs, TheBuildingGridIcon, TheBuildingImage, TheBuildingDisplayAlignItems, TheBuildingDisplay, ParagraphText, TheBuildingCarousel } from "../../../style/TheBuildingScreen";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const descriptionEnergyEfficiencys = ["Facade", "Ventilation System", "Automatic Lighting Control"];

const Sustainability = ({ kf, handleHover, handleLeave }) => {
    const { key, title, description, image } = kf;
    const checkTab = key === "energy-efficiency";

    const [isDescriptionHovered, setIsDescriptionHovered] = useState(false);
    const descriptionSpring = TextAnimationOnClick(key);
    const imageSpring = TextAnimationOnClick(key);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const handleHoverDescription = () => {
        setIsDescriptionHovered((prev) => !prev);
    };

    const renderDescriptionEnergyEfficiencys = () =>
        descriptionEnergyEfficiencys.map((description, index) => (
            <TheBuildingParagraphs key={index} fweight={"400"} fsize={currentLanguageCode === "jp" ? "13px" : "16px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} onMouseLeave={handleHoverDescription} onMouseEnter={handleHoverDescription} align={"justify"} font={"Segoe UI',Helvetica"}>
                {parse(t(description))}
            </TheBuildingParagraphs>
        ));
    return (
        <FadeInOut key={key}>
            <TheBuildingDisplay direction={"row"} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                <TheBuildingDisplay margin={"0 30px 0 82px"} direction={"column"} width={"40%"} justify={"start"} align={"flex-start"}>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} color={"#2C53AF"} font={"Barlow, Helvetica"} align={"left"} fsize={currentLanguageCode === "jp" ? "28px" : "30px"} linehg={currentLanguageCode === "jp" ? "28px" : "36px"} fweight={"600"} as={animated.div} style={descriptionSpring}>
                        {parse(t(title))}
                    </TheBuildingParagraphs>

                    {checkTab ? (
                        <>
                            <TheBuildingPositionDisplay style={descriptionSpring} as={animated.div} margin={"20px 0 0 0"} height={"163px"} onMouseLeave={handleHoverDescription} onMouseEnter={handleHoverDescription}>
                                <TheBuildingCarousel padding={"0"} width="500px" autoplay={!isDescriptionHovered} languagecode={currentLanguageCode} align={"left"} fweight={"400"} fsize={currentLanguageCode === "jp" ? "15px" : "18px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"}>
                                    {renderDescriptionEnergyEfficiencys()}
                                </TheBuildingCarousel>
                            </TheBuildingPositionDisplay>
                        </>
                    ) : (
                        <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"25px 0 0 0"} align={"justify"} fweight={"400"} fsize={currentLanguageCode === "jp" ? "13px" : "16px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} style={descriptionSpring} as={animated.div}>
                            {parse(t(description))}
                        </TheBuildingParagraphs>
                    )}
                </TheBuildingDisplay>
                <TheBuildingDisplay width={"60%"} as={animated.div} style={imageSpring}>
                    <TheBuildingImage height={"500px"} width={"779px"} border={"1px solid #535353"} src={image} />
                </TheBuildingDisplay>
            </TheBuildingDisplay>
        </FadeInOut>
    );
};

const SustainabilityIconComponent = ({ kf, className, tabKey, changeTab, handleHover, handleLeave }) => {
    const { icon } = kf;
    const iconImage = tabKey === kf.key ? icon.activated : icon.normal;
    const colorActive = tabKey === kf.key ? "#16f0df" : "#2c53af";
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <HoverImageZoom>
            <TheBuildingDisplayAlignItems onMouseEnter={handleHover} onMouseLeave={handleLeave} direction={"column"} onClick={() => changeTab(kf.key)} onKeyDown={() => changeTab(kf.key)}>
                <TheBuildingImage fit={"contain"} width={"70px"} height={"70px"} className={`sustainability-icon ${className ?? ""}`} alt={iconImage} src={iconImage} />
                <TheBuildingJustifyContent align={"center"} width={""}>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"10px 0 0 0"} color={colorActive} fsize={currentLanguageCode === "jp" ? "12px" : "15px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} fweight={"600"} cursor={"pointer"}>
                        {parse(t(icon.title))}
                    </TheBuildingParagraphs>
                </TheBuildingJustifyContent>
            </TheBuildingDisplayAlignItems>
        </HoverImageZoom>
    );
};

const SustainabilitySection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [tabKey, setTabKey] = useState("construction");
    const [onMouseHover, setOnMouseHover] = useState(false);
    const [viewRef, inViewRef] = useInView();
    const descriptionSpring = TextAnimation(inViewRef);
    const [currentKey, setCurrentKey] = useState(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const updateCurrent = () => {
        const newTabKeyIndex = sustainabilitys.findIndex(({ key }) => key === tabKey);
        const nextTabKeyIndex = (newTabKeyIndex + 1) % sustainabilitys.length;
        const nextTabKey = sustainabilitys[nextTabKeyIndex].key;
        setTabKey(nextTabKey);
    };

    const startInterval = () => {
        setOnMouseHover(false);
    };
    const stopInterval = () => {
        setOnMouseHover(true);
    };

    const changeTab = (index) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Sustainability selected icon ${index}`
        });
        setTabKey(index);
    };

    useEffect(() => {
        const kf = sustainabilitys.find(({ key }) => key === tabKey);
        if (kf) {
            setCurrentKey(kf);
        }

        if (!onMouseHover && inViewRef) {
            const intervalId = setInterval(updateCurrent, 2000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [tabKey, onMouseHover, inViewRef]);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const SustainabilitySelectionPanel = () => {
        let kfs = sustainabilitys.map((item, index) => {
            return <SustainabilityIconComponent key={index} kf={item} changeTab={changeTab} handleHover={stopInterval} handleLeave={startInterval} tabKey={tabKey} />;
        });
        return (
            <TheBuildingGridIcon template={"170px 170px 170px 170px"} padding={"40px 0"}>
                {kfs}
            </TheBuildingGridIcon>
        );
    };

    return (
        <TheBuildingSectionWrapper id="sustainability" ref={sectionRef} inViewRef={inViewRef}>
            <TheBuildingContent height={currentLanguageCode === "en" ? "1903px" : "1964px"} align={"center"} ref={viewRef}>
                <TheBuildingParagraphs font={"'Barlow', Helvetica"} width={"700px"} padding={"97px 0 30px 0"} fsize={"37px"} linehg={"47px"} as={animated.div} style={descriptionSpring}>
                    {t("DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE")}
                </TheBuildingParagraphs>
                <TheBuildingJustifyContent padding={"60px 0 0 0"} align={"center"} direction={"column"} width="800px">
                    <TheBuildingImage className="logo-leed-certficate" width={"180px"} height={"180px"} src="./img/leed-silver-logo.png" />
                    <TheBuildingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} padding={"30px 0"} fsize={"35px"} linehg={"39px"} fweight={"600"} as={animated.div} style={descriptionSpring}>
                        {t("LEED CERTIFICATE")}
                    </TheBuildingParagraphs>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} fsize={"16px"} linehg={"24px"} fweight={"400"} as={animated.div} style={descriptionSpring}>
                        <ParagraphText>{t("LEED CERTIFICATE DESCRIPTION")}</ParagraphText>
                        <ParagraphText paddingtop={"10px"}>{parse(t("Taisei Square Hanoi Description"))}</ParagraphText>
                    </TheBuildingParagraphs>

                    <TheBuildingJustifyContent padding={"30px 0px 0px 0px"}>
                        <Link to="/contact">
                            <ContactUsButtonComponent text={t("CONTACT US")} />
                        </Link>
                    </TheBuildingJustifyContent>
                </TheBuildingJustifyContent>

                <TheBuildingDisplay align={"center"}>
                    <SustainabilitySelectionPanel />
                    {currentKey && <Sustainability kf={currentKey} handleHover={stopInterval} handleLeave={startInterval} />}
                </TheBuildingDisplay>
            </TheBuildingContent>
        </TheBuildingSectionWrapper>
    );
};

SustainabilitySection.propTypes = {
    onHeightChange: PropTypes.func
};

Sustainability.propTypes = {
    kf: PropTypes.object,
    handleHover: PropTypes.func,
    handleLeave: PropTypes.func
};

SustainabilityIconComponent.propTypes = {
    kf: PropTypes.object,
    className: PropTypes.string,
    tabKey: PropTypes.string,
    changeTab: PropTypes.func,
    handleHover: PropTypes.func,
    handleLeave: PropTypes.func
};

export default SustainabilitySection;
