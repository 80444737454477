{
    "SEIZE THE SPACE EMPOWER YOUR VISION": "NẮM BẮT CƠ HỘI, <br /> MỞ RỘNG <br /> TẦM NHÌN",
    "OFFICE SPACE FEATURES": "ĐẶC ĐIỂM NỔI BẬT CỦA KHỐI VĂN PHÒNG",
    "2.7m of Clearance Ceiling Height Allows Better Air Circulation": "Không gian thông thoáng với trần <br /> hoàn thiện cao 2.7m",
    "Column Free Layout Maximizes Space use Efficiency": "Mặt bằng không cột <br /> tối ưu hóa <br /> không gian sử dụng",
    "Air Conditioning System with Air Quality Control": "Hệ thống điều hòa <br /> không khí với <br /> tính năng lọc khuẩn",
    "LED Panel Lighting on Finished Concealed Ceiling": "Đèn tấm LED <br /> trên trần <br /> hoàn thiện",
    "100m Raised Floor System Eases Out Renovation/ Alteration": "Dễ dàng cải tạo <br /> và sửa chữa  <br /> với sàn nâng 100mm",
    "100% Power Backup for Seamless Operation": "Điện dự phòng 100% <br /> cho phép vận hành <br /> liền mạch",
    "TEST FIT": "Mặt bằng mẫu",
    "SPECS SHEET": "Thông số kỹ thuật",
    "CONTACT US": "LIÊN HỆ",
    "RETAIL SPACE": "KHỐI THƯƠNG MẠI"
}
