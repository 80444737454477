export const floor13to15 = [
    {
        id: 1,
        name: "15F",
        height: "37px"
    },
    {
        id: 2,
        name: "14F",
        height: "37px"
    },
    {
        id: 3,
        name: "13F",
        height: "37px"
    }
];
