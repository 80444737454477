import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import parse from "html-react-parser";
import { amenities } from "../Data/amenities";
import { animated } from "@react-spring/web";
import { TextAnimation, TextAnimationOnClick } from "../../../breakpoints";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { FadeInOut, HoverImageZoom, TheBuildingSectionWrapper, TheBuildingContent, TheBuildingJustifyContent, TheBuildingParagraphs, TheBuildingGridIcon, TheBuildingImage, TheBuildingDisplayAlignItems, TheBuildingDisplay } from "../../../style/TheBuildingScreen";
import ReactGA from "react-ga4";

import { useTranslation } from "react-i18next";

const Amenity = ({ kf, handleHover, handleLeave }) => {
    const { key, title, description, image } = kf;
    const descriptionSpring = TextAnimationOnClick(key);
    const imageSpring = TextAnimationOnClick(key);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <FadeInOut key={key}>
            <TheBuildingDisplay direction={"row"} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
                <TheBuildingDisplay width={"60%"} as={animated.div} style={imageSpring}>
                    <TheBuildingImage height={"500px"} width={"779px"} border={"1px solid #535353"} src={image} />
                </TheBuildingDisplay>
                <TheBuildingDisplay margin={"0 82px 0 35px"} direction={"column"} width={"40%"} justify={"start"} align={"flex-start"}>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fsize={currentLanguageCode === "jp" ? "25px" : "27px"} linehg={currentLanguageCode === "jp" ? "28px" : "36px"} color={"#2C53AF"} align={"left"} fweight={"600"} as={animated.div} style={descriptionSpring}>
                        {parse(t(title))}
                    </TheBuildingParagraphs>

                    <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"25px 0 0 0"} align={"justify"} fweight={"400"} fsize={currentLanguageCode === "jp" ? "13px" : "16px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} as={animated.div} style={descriptionSpring}>
                        {parse(t(description))}
                    </TheBuildingParagraphs>
                </TheBuildingDisplay>
            </TheBuildingDisplay>
        </FadeInOut>
    );
};

const AmenitiesIconComponent = ({ kf, className, tabKey, changeTab, handleHover, handleLeave }) => {
    const { icon } = kf;
    const iconImage = tabKey === kf.key ? icon.activated : icon.normal;
    const colorActive = tabKey === kf.key ? "#16f0df" : "#2c53af";
    const sizeIcon = kf.key === "car" ? "80px" : kf.key === "reception" ? "60px" : kf.key === "tap" ? "50px" : "70px";
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <HoverImageZoom>
            <TheBuildingDisplayAlignItems onMouseEnter={handleHover} onMouseLeave={handleLeave} direction={"column"} onClick={() => changeTab(kf.key)} onKeyDown={() => changeTab(kf.key)}>
                <TheBuildingImage fit={"contain"} width={sizeIcon} height={"80px"} className={`amenities-icon ${className ?? ""}`} alt={iconImage} src={iconImage} />
                <TheBuildingJustifyContent align={"center"} width={""}>
                    <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"10px 0 0 0"} color={colorActive} fsize={currentLanguageCode === "jp" ? "12px" : "15px"} linehg={currentLanguageCode === "jp" ? "24px" : "24px"} fweight={"600"} cursor={"pointer"}>
                        {parse(t(icon.title))}
                    </TheBuildingParagraphs>
                </TheBuildingJustifyContent>
            </TheBuildingDisplayAlignItems>
        </HoverImageZoom>
    );
};

const AmenitiesSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [tabKey, setTabKey] = useState("car");
    const [viewRef, inViewRef] = useInView();
    const [onMouseHover, setOnMouseHover] = useState(false);
    const descriptionSpring = TextAnimation(inViewRef);
    const [currentKey, setCurrentKey] = useState(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const updateCurrent = () => {
        const newTabKeyIndex = amenities.findIndex(({ key }) => key === tabKey);
        const nextTabKeyIndex = (newTabKeyIndex + 1) % amenities.length;
        const nextTabKey = amenities[nextTabKeyIndex].key;
        setTabKey(nextTabKey);
    };
    const startInterval = () => {
        setOnMouseHover(false);
    };
    const stopInterval = () => {
        setOnMouseHover(true);
    };

    const changeTab = (index) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Amenities selected icon ${index}`
        });
        setTabKey(index);
    };

    useEffect(() => {
        const kf = amenities.find(({ key }) => key === tabKey);
        if (kf) {
            setCurrentKey(kf);
        }

        if (!onMouseHover && inViewRef) {
            const intervalId = setInterval(updateCurrent, 2000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [tabKey, onMouseHover, inViewRef]);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const AmenitiesSelectionPanel = () => {
        let kfs = amenities.map((item, index) => {
            return <AmenitiesIconComponent key={index} kf={item} changeTab={changeTab} handleHover={stopInterval} handleLeave={startInterval} tabKey={tabKey} />;
        });
        return <TheBuildingGridIcon padding={"40px 0"}>{kfs}</TheBuildingGridIcon>;
    };

    return (
        <TheBuildingSectionWrapper id="amenities" bgcolor={"#f4f4f4"} width="100%" ref={sectionRef} inViewRef={inViewRef}>
            <TheBuildingContent height={"1132px"} align={"center"} ref={viewRef}>
                <TheBuildingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} padding={"97px 0 30px 0"} fsize={"37px"} linehg={"47px"} as={animated.div} style={descriptionSpring}>
                    {t("AMENITIES")}
                </TheBuildingParagraphs>
                <TheBuildingJustifyContent direction={"column"} width="800px" as={animated.div} style={descriptionSpring}>
                    <TheBuildingParagraphs align="justify" last="left" languagecode={currentLanguageCode} fsize={"16px"} linehg={"24px"} fweight={"400"}>
                        {t("AMENITIES DESCRIPTION")}
                    </TheBuildingParagraphs>

                    <TheBuildingJustifyContent padding={"30px 0px 0px 0px"}>
                        <Link to="/contact">
                            <ContactUsButtonComponent text={t("CONTACT US")} />
                        </Link>
                    </TheBuildingJustifyContent>
                </TheBuildingJustifyContent>

                <TheBuildingDisplay align={"center"} onMouseEnter={() => stopInterval()} onMouseLeave={() => startInterval()}>
                    <AmenitiesSelectionPanel />
                    {currentKey && <Amenity kf={currentKey} handleHover={stopInterval} handleLeave={startInterval} />}
                </TheBuildingDisplay>
            </TheBuildingContent>
        </TheBuildingSectionWrapper>
    );
};

AmenitiesSection.propTypes = {
    onHeightChange: PropTypes.func
};

Amenity.propTypes = {
    kf: PropTypes.object,
    handleHover: PropTypes.func,
    handleLeave: PropTypes.func
};

AmenitiesIconComponent.propTypes = {
    kf: PropTypes.object,
    className: PropTypes.string,
    tabKey: PropTypes.string,
    changeTab: PropTypes.func,
    handleLeave: PropTypes.func,
    handleHover: PropTypes.func
};

export default AmenitiesSection;
