export const floor3to7 = [
    {
        id: 1,
        name: "7F",
        height: "37px"
    },
    {
        id: 2,
        name: "6F",
        height: "37px"
    },
    {
        id: 3,
        name: "5F",
        height: "37px"
    },
    {
        id: 4,
        name: "4F",
        height: "37px"
    },
    {
        id: 5,
        name: "3F",
        height: "37px"
    }
];
