import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: row; */
    background-color: #122658;
    width: 100%;
    position: fixed;
    transition: height 0.6s ease-in-out;
    height: ${(props) => (props.scrolled === "true" ? "70px" : "95px")};
    opacity: ${(props) => (props.scrolled === "true" ? 1 : 0.9)};
    z-index: 999999999;
`;

export const HeaderContent = styled.div`
    position: relative;
    margin: auto;
    z-index: 999999999;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (min-width: 1280px) and (max-width: 1365px) {
        width: 1280px;
    }

    @media (min-width: 1366px) {
        width: 1366px;
    }
`;

export const HeaderMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #122658;
    position: fixed;
    z-index: 999999999;
    transition: height 0.6s ease-in-out;
    height: auto;
    opacity: ${(props) => (props.scrolled === "true" ? 1 : 0.9)};
    width: 100%;
`;

export const LogoContent = styled.div`
    position: relative;
    display: block;
`;

export const LogoContentMobile = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
`;

export const MenuMobile = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
    opacity: 1;
    background-color: #f4f4f4;
    overflow: hidden;
    transition: height 0.3s ease;
    height: 100%;
`;

export const MenuContentMobile = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-bottom: 1px solid #353535;
`;

export const MenuItemsMobile = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 30px;
`;

export const SubMenuContentMobile = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px 10px 20px;
    justify-content: flex-start;
    width: 100%;
    height: auto;
`;

export const MenuTitleMobile = styled.div`
    color: #353535;
    margin-top: 6px;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : "'Barlow', Helvetica")};
    font-size: ${(props) => props.fsize ?? "15px"};
    font-weight: 600;
    letter-spacing: 0;
    line-height: ${(props) => props.fsize ?? "18px"};
    text-align: ${(props) => props.align ?? ""};
    white-space: nowrap;
    width: 50%;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "18px"};
        line-height: ${(props) => props.tablet ?? "21px"};
    }
`;

export const MenuTitleLink = styled(Link)`
    font-family: inherit;
    text-decoration: none;
    color: ${(props) => (props.active === "true" ? "#a5d9fb" : "#353535")};
    &:hover {
        color: #a5d9fb;
    }
`;

export const SubMenuTitleMobile = styled.div`
    color: #353535;
    margin-top: 2px;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : "'Barlow', Helvetica")};
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    white-space: nowrap;
    opacity: 1;
    &:hover {
        font-weight: 700;
    }

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 20px;
        line-height: 20px;
    }
`;

export const SubMenuTitleLink = styled(Link)`
    font-family: inherit;
    text-decoration: none;
    color: #353535;
`;

export const MenuLanguageMobile = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
    width: 100%;
    height: 30px;
`;

export const MenuLanguageItemMobile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : "'Barlow', Helvetica")};
    color: ${(props) => (props.selected ? "#2C53AF" : "#353535")};
    font-size: 11px;
    line-height: 19px;
    font-weight: 700;
    text-align: center;
    width: 100px;
    height: 27px;
    text-shadow: 0px 1px 2px #353535a6;
    border: ${(props) => (props.selected ? "1px solid #a5d9fb" : "2px solid #707070")};
    box-shadow: 0px 1px 2px #353535a6;
    background-color: ${(props) => (props.selected ? "#A5D9FB" : "")};
    cursor: pointer;
`;

export const LogoImage = styled.img`
    width: ${(props) => (props.scrolled === "true" ? "145px" : "220px")};
    height: ${(props) => (props.scrolled === "true" ? "46px" : "70px")};
    margin-left: 82px;
    transition:
        width 0.7s ease,
        height 0.7s ease,
        margin 0.7s;

    @media (min-width: 1280px) and (max-width: 1365px) {
        margin-left: 50px;
    }

    @media (max-width: 1279px) {
        margin-left: 0;
    }
`;

export const MenuButton = styled.img`
    object-fit: cover;
    width: 42px;
    height: 42px;
    cursor: pointer;
`;

export const MenuFullContent = styled.div`
    position: absolute;
    background-color: #122658;
    width: 100%;
    top: 0;
    z-index: 99999999;
    opacity: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    transition:
        height 0.3s ease-in-out,
        opacity 0.1s ease-in-out;
    transition-delay: 0.1s; /* Thời gian chuyển đổi ban đầu là 0s */
    &.show {
        opacity: 1;
        height: ${(props) => (props.scrolled === "true" ? "160px" : "210px")};
        height:
            0.1s ease-in-out,
            opacity 0.1s ease-in-out;
        transition-delay: 0s;
        pointer-events: auto;
    }
`;

export const MenuWrapper = styled.div`
    position: absolute;
    left: 348px;
    top: ${(props) => (props.scrolled === "true" ? "14px" : "50px")};
    width: 100%;
    transition: top 0.5s ease-in-out;
    &:hover {
        height: 120px;
    }

    @media (min-width: 1280px) and (max-width: 1365px) {
        left: 280px;
    }
`;

export const MenuContent = styled.div`
    color: #fff;
    margin-left: 1px;
    left: ${(props) => props.left};
    position: absolute;
    width: ${(props) => props.width};
    cursor: pointer;
    text-align: ${(props) => props.align};
`;

export const SubMenuContent = styled.div`
    margin-top: 18px;
    background-color: #ffff;
    padding-top: 10px;
    margin-top: ${(props) => (props.isscroll === "false" ? "18px" : "30px")};
    display: none;
    opacity: 0.85;
    transition:
        opacity 0.7s ease,
        transform 0.7s ease;
    transition-delay: 0.5s;
    transform: translateY(20px);
    ${MenuContent}:hover & {
        display: block;
        transform: translateY(0);
    }
`;

export const MenuItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: ${(props) => props.align};
    width: 100%;
    opacity: 0;
    transition:
        height 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    transition-delay: 1s;

    ${MenuWrapper}:hover & {
        opacity: 1;
    }
`;

export const MenuLineFull = styled.div`
    position: absolute;
    top: 17px;
    display: none;
    width: 78.24%;
    left: 1px;
    ${MenuWrapper}:hover & {
        display: block;
    }
`;

export const MenuLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => (props.active === "true" ? "#a5d9fb" : "#f4f4f4")};
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : "'Barlow', Helvetica")};
    font-size: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
    font-weight: 400;
    letter-spacing: 0;
    line-height: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
    white-space: nowrap;
    opacity: 1;
    &:hover {
        color: #a5d9fb;
        font-size: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
        font-weight: 400;
        line-height: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
    }
`;

export const ImgLineLong = styled.img`
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 89.7%;
`;

export const ImgLineShort = styled.img`
    width: ${(props) => props.width};
    height: 4px;
    object-fit: cover;
    position: absolute;
    margin-top: ${(props) => (props.isscroll === "false" ? "6px" : "18px")};
    top: 27px;
    display: none;
    ${MenuContent}:hover & {
        display: block;
        align-items: center;
    }
`;

export const SubMenu = styled.div`
    width: ${(props) => props.width};
    margin-bottom: 10px;
`;

export const SubMenuLink = styled(Link)`
    text-decoration: none;
    color: #000;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : "'Barlow', Helvetica")};
    font-size: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
    letter-spacing: 0;
    line-height: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
    font-weight: 400;
    opacity: 1;
    white-space: nowrap;
    text-align: center;
    &:hover {
        color: #353535;
        font-size: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
        font-weight: 600;
        line-height: ${(props) => (props.languagecode === "jp" ? "15px" : "18px")};
        transition: visibility 1s;
    }
`;

export const LanguageDisplay = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 5px;
`;

export const LanguageMenu = styled.div`
    height: 24px;
    left: 1002px;
    position: absolute;
    top: 0px;
    width: 290px;
    opacity: ${(props) => (props.scrolled === "true" ? 0 : 1)};
    visibility: ${(props) => (props.scrolled === "true" ? "hidden" : "visible")};
    transition:
        visibility 0.3s linear,
        opacity 0.3s linear;
    transition-delay: 0.1s;

    @media screen and (min-width: 1280px) and (max-width: 1365px) {
        left: 935px;
    }
`;

export const HotLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    width: 144px;
    gap: 5px;
`;

export const IconPhone = styled.div`
    background-image: url(./img/telephone-call.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 21px;
    width: 21px;
`;

export const MenuPhone = styled.div`
    color: #a5d9fb;
    font-family: "Barlow", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
    text-align: right;
    white-space: nowrap;
`;

export const LanguageParagraph = styled.div`
    color: #a5d9fb;
    font-family: "Barlow", Helvetica;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
    white-space: nowrap;
`;

export const LanguageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #a5d9fb;
    border-radius: 8px;
    border: ${(props) => (props.selected ? "0px solid #a5d9fb" : "1px solid #a5d9fb")};
    height: 24px;
    width: 40px;
    background-color: ${(props) => (props.selected ? "#2c53af" : "")};
    cursor: pointer;
    &:hover {
        background: #2c53af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        border: 0px solid #a5d9fb;
    }
`;
