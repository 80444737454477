import React, { useEffect } from "react";
import { DeveloperImage, DeveloperWrapper, HeadLineWrapper, DeveloperSpan, DeveloperGrid } from "../../../style/DeveloperScreen";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const PartnersMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <DeveloperWrapper id="partners" direction={"column"} align={"center"}>
            <HeadLineWrapper languagecode={currentLanguageCode} tablet={"47px"} align={"center"} wspace={""} fsize={"27px"} linehg={"36px"} fweight={"700"} margin={"30px 0"}>
                {t("OUR PARTNERS")}
            </HeadLineWrapper>

            <DeveloperGrid gap={"30px"} margin={"0 0 30px 0"}>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-vinata.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"14px"}>
                        {t("Main Contractor & Architect")}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-vncc.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {t("Design Consultant")}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-kinden.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {t("M&E Contractor KINDEN")}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-yurtec.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {t("M&E Contractor YURTEC")}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-ibst.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {parse(t("Project Management & Site Supervision"))}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-jll.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {parse(t("Strategic Leasing & Marketing Consultant"))}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-visaho.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {parse(t("Property Management Support"))}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-alsok.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {t("Security Service")}
                    </DeveloperSpan>
                </DeveloperWrapper>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-meibi.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} width={"124px"} height={"38px"} fsize={"11px"} linehg={"21px"}>
                        {t("Cleaning Service")}
                    </DeveloperSpan>
                </DeveloperWrapper>
            </DeveloperGrid>
        </DeveloperWrapper>
    );
};

export default PartnersMobile;
