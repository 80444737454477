{
    "EMBRACE THE POWER OF THE SQUARE": "EMBRACE <br /> THE POWER OF THE SQUARE",
    "EMBRACE THE POWER OF THE SQUARE MOBILE": "EMBRACE <br /> THE POWER OF THE SQUARE",
    "KEY FEATURES": "主な特徴",
    "KEY FEATURES DESCRIPTION": "Taisei Square Hanoi は入居者や来訪者の皆さまに最高の快適性を提供するため、日本品質を追求しています。主要設備に日系ブランドを採用するだけでなく、大成建設の設計・施工チームと一団となり、デザイン・プラニングを細部まで検討し品質にこだわりました。",

    "Column Free": "ロング <br /> スパンの <br /> 無柱空間",
    "Column Free Title": "国際基準を満たす 2.7ｍ の天井高",
    "Column Free Description": "<p>2.7m の天井高さは、広々とした事務所空間を実現します。</p> <p style='padding-top: 10px;'>日本のオフィスビルの標準仕様であるシンプルなグリッド天井には、LED パネル照明、空調、スプリンクラー、煙感知器、非常用放送設備などが完備されています。</p> <p style='padding-top: 10px;'>また、無柱のフロアレイアウトは、賃借スペースを効率的に最大限にご活用頂けます。</p>",

    "Panoramic View": "開放的な <br /> 眺望",
    "Panoramic View Title": "公園のパノラマビューと豊かな自然光が得られるハイサッシ",
    "Panoramic View Description": "<p>Taisei Square Hanoi からは Thanh Xuan 公園の豊かな緑と Nhan Chinh 池の優雅な景色を望むことができます。</p><p style='padding-top: 10px;'>全面ガラスカーテンウォールの外装により眺望を確保するとともに、最大限の自然光を事務所内に取り込むことで日中の照明エネルギーを節約できるサスティナブルなデザインとなっています。</p>",

    "Air Quality": "室内空気 <br /> 環境",
    "Air Quality Title": "高効率の VRF 空調システムにより快適な執務空間をご提供",
    "Air Quality Description": "<p>VRF 空調（個別空調）システムは、テナントスペース内の細やかな温度調整に対応できるため、効率的で快適な空調運用が可能です。Taisei Square Hanoi ではパナソニック製の空調機器を採用しています。</p> <p style='padding-top: 10px;'>また、外気の取り込み口には高性能なフィルターを設置し、ビル内に PM2.5 等の汚染物質が入り込むことを防ぎます。さらに、本建物で採用しているパナソニックの空調には nanoe™ Xが標準装備されており、空気中や什器に長時間付着する細菌・ウイルスを抑制することができます。これらの対策により、Taisei Square Hanoi は入居者の皆様に快適でクリーンな執務空間を提供します。</p>",

    "Raised Floor": "OAフロア",
    "Raised Floor Title": "容易で安価なレイアウト変更を実現する OA フロア",
    "Raised Floor Description": "<p>事務所内の二重床（100mm底上げ）は、強固で安定した高強度鉄筋コンクリートパネルと二段ゴムガスケットロックシステムにより高い歩行の快適性を確保しているニチアス製のOAフロアを採用しています。</p><p style='padding-top: 10px;'>このＯＡフロアは、事務所内の改修やレイアウト変更際に容易に取り外しができるため、コストと工期を削減できます。</p>",

    "Elevator": "エレベー <br /> ター",
    "Elevator Title": "待ち時間を軽減しスムーズな移動を実現する高速エレベーター",
    "Elevator Description": "<p>Taisei Square Hanoi ではスマート群管理システムを備えたフジテックの高速エレベーターを採用しています。エレベーターは常用8台、地下用1台、人荷用1台と、十分な台数を確保することにより、待ち時間を最小限に抑え、スムーズで効率的な運用を実現します。</p><p style='padding-top: 10px;'>また、エレベーターの操作ボタンは、細菌やウイルスによる感染防止のためタッチレスボタンを採用しています。さらに、エレベーターにはシャープのIONFULLを導入しており、プラズマクラスターイオンがカゴ内の空気を浄化し、利用者の皆様の健康を守ります。</p>",

    "Power Backup": "100％ バック <br /> アップ可能 <br /> な非常用 <br /> 電源",
    "Power Backup Title": "非常時にも安心の 100% バックアップ電源",
    "Power Backup Description": "３台の三菱重工業製 1,650KVA 発電機が、エアコンを含む 24 時間フルパワーバックアップを可能とします。ハノイの暑い季節には珍しくない電力不足でも、安心して業務を続けて頂けます。",

    "Water Quality": "水質",
    "Water Quality Title": "全館に３ステップ浄水システムを採用",
    "Water Quality Description": "Taisei Square Hanoi では水もテナントの皆様の健康に大きく影響する重要な要素であると考えています。そのため、中川ケミカルの水浄化システムを清掃・洗浄用水も含めて全館に導入しています。本建物で使用される水は10ミクロン以上の不純物、有害な有機化合物、残留塩素が除去されています。",

    "AMENITIES": "施設アメニティ",
    "AMENITIES DESCRIPTION": "Taisei Square Hanoi は施設の利便性、快適性を追求し、入居者、来館者の皆様に、日本の「おもてなし」を提供します。",

    "Grand Entrance": "グランド <br /> エントランス",
    "Grand Entrance Title": "広々とした屋根付き車寄せエリア",
    "Grand Entrance Description": "<p>車両と乗客の両方を覆う大型の庇により、天気に左右されることなく​、降車から入館までスムーズかつ安全・快適に移動できます。</p><p style='padding-top: 10px;'>また、Taisei Square Hanoi の 1 つの特徴は、ハノイの建物ではまだ珍しい風除室があることです。風除室は室内と室外の温度差を調整し、天候が荒れている日には風雨が建物内に侵入するのを防ぐ機能があります。</p>",

    "Welcome Lobby": "エントランスロビー",
    "Welcome Lobby Title": "洗練された日本のデザインを取り入れたエントランスロビー",
    "Welcome Lobby Description": "<p>Taisei Square Hanoi のエントランスを入ると、木と天然石を使った温かみのあるアースカラーのインテリアに、植物がアクセントとなった、心地よい空間が訪問される皆様を歓迎します。</p><p style='padding-top: 10px;'></p>",

    "Retail Podium": "店舗フロア",
    "Retail Podium Title": "あらゆるニーズに応え利便性を向上させる店舗フロア",
    "Retail Podium Description": "店舗フロアには、カフェ、レストラン、ミニマート、銀行などを予定しています。オフィステナントの皆様のニーズに応え、利便性を向上させるだけでなく、近隣からの来客を惹きつけることで地域コミュニティと繋がった活気のある施設となります。",

    "Japanese Standard Restroom": "日本基準のトイレ",
    "Japanese Standard Restroom Title": "日本の標準仕様を取り入れたトイレ",
    "Japanese Standard Restroom Description": "<p>計画、設計、仕上材から TOTO の衛生器具に至るまで、館内のトイレは日本の基準に従い作られています。</p> <p style='padding-top: 10px;'>特に各便器にはベトナムのオフィスビルではまだ珍しい温水洗浄機（ウォシュレット）が装備されています。また、女性トイレにはパウダーコーナーがあり、女性利用者への配慮としています。</p>",

    "Nursing Room": "授乳室",
    "Nursing Room Title": "働くママのための授乳室",
    "Nursing Room Description": "<p>授乳室は女性のための清潔で快適なプライベート空間となっており、業務時間中の搾乳に利用したり、赤ちゃんを連れてきた場合には安心して赤ちゃんの世話ができます。</p><p style='padding-top: 10px;'>Taisei Square Hanoi はあらゆるユーザーの皆様のニーズに配慮しています。</p>",

    "Wet Pantry": "シンク付きパントリー",
    "Wet Pantry Title": "オフィスの各フロアにゆったりとしたシンク付きのパントリーを設置",
    "Wet Pantry Description": "各オフィスフロアには、パントリーが用意されているため、オフィステナントの皆様は食器を洗う場所に困ったり、執務室内の貴重なスペースを犠牲にして独自に洗い場を設ける必要はありません。パントリーの窓際にはカウンターが設置されており、気軽に立ち寄り、ちょっとした休憩をとったりリフレッシュするには最適な空間です。適切な清掃スケジュール管理によりパントリー内は常に清潔な状態を保ちます。",

    "Parking": "駐車場",
    "Parking Title": "地下全 4フロアに駐車場を完備",
    "Parking Description": "<p>Taisei Square Hanoi は、テナントの従業員の皆様の毎日の通勤ニーズに応え、10,000㎡を超える地下 4 層の駐車場を計画しました。</p><p style='padding-top: 10px;'>それ以外にも、屋外駐車スペースを設けており、一時的な訪問者の利便性にも考慮しています。</p>",

    "DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE": "DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE",
    "LEED CERTIFICATE": "LEED 環境認証",
    "LEED CERTIFICATE DESCRIPTION": "Leadership in Energy and Environmental Design (LEED) は、国際的に最も認知度が高く、広く使用されているグリーンビルディング評価（建物の環境性能評価）システムです。LEED 認証は現在、新築の商業施設、住宅、工場等の様々な用途の建物、および内装工事から既存建物の改修に至るまで、様々なタイプのプロジェクトに適用されています。",
    "Taisei Square Hanoi Description": "<p>Taisei Square Hanoi では、建設段階から建物完成後の運営に至るまで二酸化炭素排出量を削減し、室内環境を改善することにより、テナントの皆様の健康に配慮し、かつ高効率でエネルギーコストを削減できるサスティナブルな施設とすることに努めています。</p><p style='padding-top: 10px;'>このような取り組みの証として、Taisei Square HanoiはLEED 認証を取得する予定であり、ハノイ西部地区では初めて国際的な環境認証を取得したサスティナブルなオフィスビルとなります。</p>",

    "First Taisei Square Hanoi": "Taisei Square Hanoi では、建設段階から建物完成後の運営に至るまで二酸化炭素排出量を削減し、室内環境を改善することにより、テナントの皆様の健康に配慮し、かつ高効率でエネルギーコストを削減できるサスティナブルな施設とすることに努めています。",
    "Second Taisei Square Hanoi": "このような取り組みの証として、Taisei Square Hanoi は LEED 認証を取得する予定となっており、ハノイ西部地区では初めて国際的な環境認証を取得したサスティナブルなオフィスビルとなります。",

    "Eco Construction": "環境配慮型の建設",
    "Eco Construction Title": "環境配慮型の建設",
    "Eco Construction Description": "建物の建設段階では、建設工事による環境への影響を最小限に抑えるために、環境汚染防止計画、廃棄物管理計画、室内空気環境保全計画が作成され、これらの計画に従った現場運用をしています。また、建物のライフサイクルアセスメントを実施し、構造設計および選定材料による二酸化炭素排出量削減により、410tの削減効果が得られました。",

    "Energy Efficiency": "省エネルギー",
    "Energy Efficiency Title": "省エネルギー",
    "Facade": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>外装</span>&nbsp;&nbsp;外装全面に採用されているLow-E(低放射)複層ガラスは断熱層として機能しており、冬には室内からの熱の損失を防ぐことで室内を暖かく保ち、暑い夏には太陽光からの放射熱が室内に流入することを防ぎます。どちらの効果も冷暖房のエネルギー消費量を削減すると共に建物の二酸化炭素排出量を削減します。",
    "Ventilation System": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>換気システム</span>&nbsp;&nbsp;最新の全熱交換機の採用により、排気する屋内の空気の熱を効率的に利用して、吸気口から取り入れた外気と中和することで、エアコンの負荷を軽減します。",
    "Automatic Lighting Control": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>自動センサー照明</span>&nbsp;&nbsp;非常階段やトイレの照明は、人感センサー制御により利用者がいない場合は消灯し、省エネルギーに繋げています。",

    "Water Efficiency": "節水",
    "Water Efficiency Title": "節水",
    "Water Efficiency Description": "水栓やトイレには節水型器具を採用し使用水量を削減するとともに、スマートビル管理システムにより水の消費量を監視・管理します。Taisei Square Hanoiは館内の水の消費量を基準より42%削減しています。",

    "Accessible Design": "アクセシブ <br /> ルデザイン",
    "Accessible Design Title": "アクセシブルデザイン",
    "Accessible Design Description": "Taisei Square Hanoi はすべてのお客様が施設を利用しやすいように、体の不自由な方にも配慮し、道路からエントランスロビーまでアクセス可能なスロープ、多目的トイレ、車いす対応エレベーターを備えた計画としています。",

    "CONTACT US": "お問い合わせ",
    "EXPLORE": "EXPLORE"
}
