import React, { useCallback, useEffect, useRef, useState } from "react";
import { LeasingImage, ElementLeasing, LeasingSection, LeasingParagraphs, LeasingDivElement, LeasingContent, LeasingGrid, LeasingFloorPlan, LeasingEveryFloor, LeasingHoverFloor, LeasingGroup, LeasingModalImage, LeasingButtonWrapper, DropdownList, DropdownItem, LeasingSpanWrapper, LinkItem, LeasingIcon, SliderShowLeasing, ModalContent } from "../../../style/LeasingScreen";
import PropTypes from "prop-types";
import { TextAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import { useInView } from "react-intersection-observer";
import { floor16to20 } from "../Data/floor16to20.js";
import { floor13to15 } from "../Data/floor13to15.js";
import { floor8to11 } from "../Data/floor8to11.js";
import { floor3to7 } from "../Data/floor3to7.js";
import { officeDataMobile } from "../Data/officeData.js";
import { Modal as LeasingModal } from "antd";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import ReactGA from "react-ga4";

const FloorsByOffice = ({ data, hvcolor, ishovered }) => {
    const { name } = data;
    return (
        <LeasingFloorPlan>
            <LeasingEveryFloor width={"25%"} ishovered={ishovered} hvcolor={hvcolor}>
                {name}
            </LeasingEveryFloor>
            <LeasingHoverFloor width={"143px"} ishovered={ishovered} hvcolor={hvcolor} />
        </LeasingFloorPlan>
    );
};

FloorsByOffice.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        margin: PropTypes.string
    }).isRequired,
    hvcolor: PropTypes.string,
    ishovered: PropTypes.bool
};

const FloorByOffice = ({ name, hvcolor, isHovered }) => {
    return (
        <LeasingFloorPlan>
            <LeasingEveryFloor width={"25%"} ishovered={isHovered} hvcolor={hvcolor}>
                {name}
            </LeasingEveryFloor>
            <LeasingHoverFloor width={"143px"} height={name === "2F" || name === "1F" ? "23px" : ""} ishovered={isHovered} hvcolor={hvcolor}></LeasingHoverFloor>
        </LeasingFloorPlan>
    );
};

FloorByOffice.propTypes = {
    name: PropTypes.string.isRequired,
    hvcolor: PropTypes.string,
    isHovered: PropTypes.bool
};

const generateOfficeComponents = (floors, hvcolor, ishovered) => {
    return floors.map((floor) => <FloorsByOffice key={floor.id.toString()} data={floor} ishovered={ishovered} hvcolor={hvcolor} />);
};

const OfficeMobile = () => {
    const [srcImage, setSRCImage] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const carouselRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const openModal = (src) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Leasing - see image ${src} in building.`
        });
        setModalOpen(true);
        setSRCImage(src);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const [officeRef, inOfficeRef] = useInView();
    const descriptionSpring = TextAnimation(inOfficeRef);
    const [isOpenDropDownTestFit, setIsOpenDropDownTestFit] = useState(false);
    const [isOpenDropDownSpecsSheet, setIsOpenDropDownSpecsSheet] = useState(false);
    const [isOpenModalDropDown, setIsOpenModalDropDown] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const dropDTestFitRef = useRef(null);
    const btnTestFitRef = useRef(null);
    const btnSpecSheetRef = useRef(null);
    const dropDSpecSheetRef = useRef(null);

    const itemsTestFit = [
        { key: 1, name: "Plan A" },
        { key: 2, name: "Plan A-B-C" },
        { key: 3, name: "Plan A-B-C-D-E" },
        { key: 4, name: "Interior Ideas" }
    ];
    const itemsSpecsSheet = [
        { key: 1, name: "English", pdfLink: "./pdf/Specs_sheet_EN.pdf" },
        { key: 2, name: "日本語", pdfLink: "./pdf/Specs_sheet_JP.pdf" },
        { key: 3, name: "Tiếng Việt", pdfLink: "./pdf/Specs_sheet_VI.pdf" }
    ];
    const handleItemClick = (item) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Leasing - See image test fit ${item}`
        });
        setSelectedItem(item);
        setIsOpenModalDropDown(true);
    };

    const imageListInterior = ["interior-ideas-1.png", "interior-ideas-2.png", "interior-ideas-3.png"];

    const [currentHoverIndex, setCurrentHoverIndex] = useState(0);

    const elements = officeDataMobile.map((office, index) => {
        let elementLeasingClassName = office.className;
        let element;

        if (elementLeasingClassName === "floorplan-16_20") {
            element = generateOfficeComponents(floor16to20, office.hvcolor, index === currentHoverIndex);
        } else if (elementLeasingClassName === "floorplan-13_15") {
            element = generateOfficeComponents(floor13to15, office.hvcolor, index === currentHoverIndex);
        } else if (elementLeasingClassName === "floorplan-12") {
            element = <FloorByOffice key={index} name={office.name} hvcolor={office.hvcolor} isHovered={index === currentHoverIndex} />;
        } else if (elementLeasingClassName === "floorplan-8_11") {
            element = generateOfficeComponents(floor8to11, office.hvcolor, index === currentHoverIndex);
        } else if (elementLeasingClassName === "floorplan-3-7") {
            element = generateOfficeComponents(floor3to7, office.hvcolor, index === currentHoverIndex);
        } else if (elementLeasingClassName === "floorplan-2" || elementLeasingClassName === "floorplan-1") {
            element = <FloorByOffice key={index} name={office.name} hvcolor={office.hvcolor} isHovered={index === currentHoverIndex} />;
        }

        return (
            <ElementLeasing key={index} className={office.className} height={office.height} width={office.width} top={office.top} position={office.position} left={office.left} onClick={() => openModal(office.pathModal)}>
                {element}
            </ElementLeasing>
        );
    });
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentHoverIndex((prevIndex) => (prevIndex + 1) % officeDataMobile.length);
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [officeDataMobile]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDTestFitRef.current && !dropDTestFitRef.current.contains(event.target) && btnTestFitRef.current && !btnTestFitRef.current.contains(event.target)) {
                setIsOpenDropDownTestFit(false);
            }

            if (dropDSpecSheetRef.current && !dropDSpecSheetRef.current.contains(event.target) && btnSpecSheetRef.current && !btnSpecSheetRef.current.contains(event.target)) {
                setIsOpenDropDownSpecsSheet(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const DropdownListOfficeSpecSheet = useCallback(({ refProp, width, openDD, items }) => {
        const handleDownload = () => {
            return;
            // ReactGA.event({
            //     category: "Download",
            //     action: "Download",
            //     label: `Leasing - Download Spec Sheet ${itemName}`
            // });
        };
        return (
            <DropdownList ref={refProp} width={width} opendd={openDD ? "true" : "false"}>
                {items.map((item, index) => (
                    <DropdownItem key={index}>
                        <LinkItem languagecode={currentLanguageCode} href={item.pdfLink} download onClick={() => handleDownload(item.name ?? item)}>
                            {item.name ?? item}
                        </LinkItem>
                    </DropdownItem>
                ))}
            </DropdownList>
        );
    }, []);
    const DropdownListOffice = useCallback(({ refProp, width, openDD, items, onItemClick }) => {
        return (
            <DropdownList ref={refProp} width={width} opendd={openDD ? "true" : "false"}>
                {items.map((item, index) => (
                    <DropdownItem key={index} onClick={() => onItemClick(item.key)}>
                        {item.name ?? item}
                    </DropdownItem>
                ))}
            </DropdownList>
        );
    }, []);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);
    return (
        <LeasingSection id="office" ref={officeRef} className="office-space">
            {/* Popup leasing office */}
            <LeasingModal open={isOpenModalDropDown} onCancel={() => setIsOpenModalDropDown(false)} footer={null} width={"100vw"} zIndex={"999999999"} centered className="fullscreen-modal custom-modal" closeIcon={true}>
                {selectedItem === 4 ? (
                    <>
                        <LeasingIcon width={"76px"} height={"76px"} src={"./img/arrow-left.png"} position={"absolute"} zindex={"999999999"} top={"50%"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.prev()} />
                        <LeasingIcon width={"76px"} height={"76px"} src={"./img/arrow-right.png"} position={"absolute"} zindex={"999999999"} top={"50%"} right={"0"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.next()} />

                        <SliderShowLeasing dots={false} ref={carouselRef} waitForAnimate={false} autoplay={false}>
                            {imageListInterior.map((image, index) => (
                                <ModalContent key={index}>
                                    <LeasingModalImage width="100%" height="54vw" src={`./img/${image}`} objectfit={"contain"} alt={`Image ${index}`} />
                                </ModalContent>
                            ))}
                        </SliderShowLeasing>
                    </>
                ) : (
                    <LeasingModalImage width="100%" height="67vw" src={`./img/testfit-${selectedItem}.png`} objectfit={"contain"} />
                )}
            </LeasingModal>

            <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} width={"100%"} height={"84px"} fweight={"700"} fsize={"32px"} linehg={"40px"} padding={"35px 0px 0px 0px"}>
                <animated.div style={descriptionSpring}>{t("OFFICE SPACE FEATURES")}</animated.div>
            </LeasingParagraphs>

            {/* list office */}
            <LeasingGrid className="feature" padding={"50px 20px 30px 20px"} justify={"center"}>
                {/* 2.7m of Clearance */}
                <LeasingDivElement height={"130px"} className="measure" align={"center"}>
                    <LeasingGroup>
                        <LeasingImage src="./img/measure.png" height="60px" width="60px" />
                    </LeasingGroup>
                    <LeasingParagraphs languagecode={currentLanguageCode} fsize={"12px"} linehg={"18px"} padding={"10px 10px 0px 0px"} color="#2C53AF">
                        {parse(t("2.7m of Clearance Ceiling Height Allows Better Air Circulation"))}
                    </LeasingParagraphs>
                </LeasingDivElement>

                {/* Column Free */}
                <LeasingDivElement height={"130px"} className="expand" align={"center"}>
                    <LeasingGroup>
                        <LeasingImage src="./img/expand.png" height="45px" width="45px" margintop={"9px"} />
                    </LeasingGroup>
                    <LeasingParagraphs languagecode={currentLanguageCode} fsize={"12px"} linehg={"18px"} padding={"16px 10px 0px 0px"} color="#2C53AF">
                        {parse(t("Column Free Layout Maximizes Space use Efficiency"))}
                    </LeasingParagraphs>
                </LeasingDivElement>

                {/* Air Conditioning */}
                <LeasingDivElement height={"130px"} className="air-filter" align={"center"}>
                    <LeasingGroup>
                        <LeasingImage src="./img/air-filter.png" height="50px" width="50px" margintop={"8px"} />
                    </LeasingGroup>
                    <LeasingParagraphs languagecode={currentLanguageCode} fsize={"12px"} linehg={"18px"} padding={"17px 10px 0px 0px"} color="#2C53AF">
                        {parse(t("Air Conditioning System with Air Quality Control"))}
                    </LeasingParagraphs>
                </LeasingDivElement>

                {/* LED Panel Lighting */}
                <LeasingDivElement height={"130px"} className="creative" align={"center"}>
                    <LeasingGroup>
                        <LeasingImage src="./img/creative.png" height="60px" width="60px" />
                    </LeasingGroup>
                    <LeasingParagraphs languagecode={currentLanguageCode} fsize={"12px"} linehg={"18px"} padding={"15px 10px 0px 0px"} color="#2C53AF">
                        {parse(t("LED Panel Lighting on Finished Concealed Ceiling"))}
                    </LeasingParagraphs>
                </LeasingDivElement>

                {/* 100mm Raised Floor */}
                <LeasingDivElement height={"130px"} className="tile" align={"center"}>
                    <LeasingGroup>
                        <LeasingImage src="./img/tile.png" height="60px" width="60px" />
                    </LeasingGroup>
                    <LeasingParagraphs languagecode={currentLanguageCode} fsize={"12px"} linehg={"18px"} padding={"15px 10px 0px 0px"} color="#2C53AF">
                        {parse(t("100m Raised Floor System Eases Out Renovation/ Alteration"))}
                    </LeasingParagraphs>
                </LeasingDivElement>

                {/* 100% Power */}
                <LeasingDivElement height={"130px"} className="backup" align={"center"}>
                    <LeasingGroup>
                        <LeasingImage src="./img/backup.png" height="60px" width="60px" />
                    </LeasingGroup>
                    <LeasingParagraphs languagecode={currentLanguageCode} fsize={"12px"} linehg={"18px"} padding={"15px 10px 0px 0px"} color="#2C53AF">
                        {parse(t("100% Power Backup for Seamless Operation"))}
                    </LeasingParagraphs>
                </LeasingDivElement>
            </LeasingGrid>

            {/* test fit office */}
            <LeasingContent height={"auto"} padding={"20px 0 0 0"} direction={"column"} alignitems={"center"} className="list-button">
                <LeasingDivElement position="relative" width={currentLanguageCode === "jp" ? "280px" : "235px"} className="fit-button" ref={btnTestFitRef}>
                    <LeasingButtonWrapper openbutton={isOpenDropDownTestFit ? "true" : "false"} onClick={() => setIsOpenDropDownTestFit(!isOpenDropDownTestFit)}>
                        <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} color={"#323b4a"} fsize={"18px"} linehg={"21px"} fweight={"600"} padding={"0 0 3px 0"} shadow={"0px 1px 2px #353535"}>
                            <LeasingSpanWrapper>{t("TEST FIT")}&nbsp;&nbsp;&nbsp;</LeasingSpanWrapper>
                            <LeasingSpanWrapper fsize={"28px"} fweight={"800"}>
                                {!isOpenDropDownTestFit ? "+" : "-"}
                            </LeasingSpanWrapper>
                        </LeasingParagraphs>
                    </LeasingButtonWrapper>
                    <DropdownListOffice refProp={dropDTestFitRef} width={currentLanguageCode === "jp" ? "280px" : "235px"} openDD={isOpenDropDownTestFit} items={itemsTestFit} onItemClick={handleItemClick} />
                </LeasingDivElement>
                <LeasingDivElement position="relative" width={currentLanguageCode === "jp" ? "280px" : "235px"} className="specs-button">
                    <LeasingButtonWrapper ref={btnSpecSheetRef} openbutton={isOpenDropDownSpecsSheet ? "true" : "false"} onClick={() => setIsOpenDropDownSpecsSheet(!isOpenDropDownSpecsSheet)}>
                        <LeasingParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} color={"#323b4a"} fsize={"18px"} linehg={"21px"} fweight={"600"} padding={"0 0 3px 0"} shadow={"0px 1px 2px #353535"}>
                            <LeasingSpanWrapper>{t("SPECS SHEET")}&nbsp;&nbsp;&nbsp;&nbsp;</LeasingSpanWrapper>
                            <LeasingSpanWrapper fsize={"25px"} fweight={"800"}>
                                {!isOpenDropDownSpecsSheet ? "+" : "-"}
                            </LeasingSpanWrapper>
                        </LeasingParagraphs>
                    </LeasingButtonWrapper>
                    <DropdownListOfficeSpecSheet refProp={dropDSpecSheetRef} width={currentLanguageCode === "jp" ? "280px" : "235px"} openDD={isOpenDropDownSpecsSheet} items={itemsSpecsSheet} />
                </LeasingDivElement>
            </LeasingContent>

            {/* building */}
            <LeasingContent width={"360px"} gap={""} height={"680px"} justify={"center"} alignitems={"center"} position={"relative"} className="group-building-floor">
                <LeasingDivElement width={"100%"} margin={"212px 0 0 0"} height={"680px"} zindex={"1000"} position={"absolute"}>
                    {elements}
                </LeasingDivElement>
                <LeasingDivElement width={"100%"} zindex={"999"} position={"absolute"}>
                    <LeasingImage src="./img/stackplan.png" fit="contain" width={"360px"} />
                </LeasingDivElement>
            </LeasingContent>
            <ElementLeasing className="floorplan_typical" height={"auto"} position={""} width={"100%"} border={"1px solid #707070"}>
                <LeasingImage className="img_floorplan_typical" src="./img/floorplan_typical.png" height={"auto"} width={"100%"} />
            </ElementLeasing>
            <LeasingModal open={isModalOpen} onCancel={closeModal} footer={null} width={"100%"} zIndex={"999999999"} centered className="fullscreen-modal" closeIcon={false}>
                <LeasingModalImage src={srcImage} height={"auto"} />
            </LeasingModal>
        </LeasingSection>
    );
};
export default OfficeMobile;
