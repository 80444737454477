import React, { useEffect } from "react";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { OverviewMobile, OverviewDisplayAlignItems, OverviewInfoMobile, OverviewTitleMobile, OverviewDescriptionMobile, OverviewButtonContact, OverviewBuildingImage, OverviewImageCustom, ConceptTitleMobile, OverviewCarousel, OverviewImage } from "../../../style/OverviewScreen";
import parse from "html-react-parser";

import { useTranslation } from "react-i18next";

const ConceptMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);
    let logoConceptImages = [
        {
            id: 1,
            path: "./img/tsquare-hanoi-logo.png"
        },
        {
            id: 2,
            path: "./img/tsquare-tool.png"
        },
        {
            id: 3,
            path: "./img/japan-flag.png"
        }
    ];
    const renderlogoConceptImages = () => {
        return logoConceptImages.map((img, index) => <OverviewImage key={index} objfit={"contain"} height={"auto"} src={img.path} />);
    };
    return (
        <OverviewMobile id="concept">
            <OverviewDisplayAlignItems>
                <OverviewInfoMobile>
                    <OverviewTitleMobile languagecode={currentLanguageCode} margin={"33px 0px 0px 0px"}>
                        {t("PROJECT CONCEPT")}
                    </OverviewTitleMobile>
                    <OverviewDescriptionMobile languagecode={currentLanguageCode} align={"justify"}>
                        {parse(t("PROJECT CONCEPT DESCRIPTION"))}
                    </OverviewDescriptionMobile>
                    <ConceptTitleMobile languagecode={currentLanguageCode} margin={"10px 0 0 0"} fsize={"17px"}>
                        {t("BUILDING DESIGN CONCEPT")}
                    </ConceptTitleMobile>
                    <OverviewDescriptionMobile languagecode={currentLanguageCode} align={"justify"}>
                        {t("BUILDING DESIGN CONCEPT DESCRIPTION")}
                    </OverviewDescriptionMobile>
                    <OverviewButtonContact>
                        <Link to="/contact">
                            <ContactUsButtonComponent width={"100%"} text={t("CONTACT US")} />
                        </Link>
                    </OverviewButtonContact>
                </OverviewInfoMobile>
                <OverviewBuildingImage>
                    <OverviewImageCustom className="building-concept" alt="building-concept" src="./img/building-concept.png" />
                </OverviewBuildingImage>
                <OverviewInfoMobile>
                    <ConceptTitleMobile languagecode={currentLanguageCode} margin={"10px 0 0 0"} fsize={"17px"}>
                        {t("LOGO CONCEPT")}
                    </ConceptTitleMobile>
                    <OverviewDescriptionMobile languagecode={currentLanguageCode} align={"justify"}>
                        {parse(t("LOGO CONCEPT DESCRIPTION"))}
                    </OverviewDescriptionMobile>
                    <OverviewButtonContact>
                        <Link to="/contact">
                            <ContactUsButtonComponent width={"100%"} text={t("CONTACT US")} />
                        </Link>
                    </OverviewButtonContact>
                </OverviewInfoMobile>
                <OverviewCarousel margin={"20px 0 20px 0"}>{renderlogoConceptImages()}</OverviewCarousel>
            </OverviewDisplayAlignItems>
        </OverviewMobile>
    );
};

export default ConceptMobile;
