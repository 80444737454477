import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import HeroButtonComponent from "../../../components/ContactUsButton";
import { HeroLanding, TheBuildingParagraphs, TheBuildingJustifyContent } from "../../../style/TheBuildingScreen";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const HeroSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);

    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);
    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 500);
        }
    };

    return (
        <HeroLanding id="hero" ref={sectionRef} bgurl="2-hero.png">
            <TheBuildingParagraphs languagecode={currentLanguageCode} padding={"0 0 30px 0"} linehg={"70px"} fweight={"600"} font={"Barlow, Helvetica"} shadow={"3px 3px 6px #353535"} fsize={"70px"} color={"#f4f4f4"} align={"center"} mgtop={"155px"}>
                {parse(t("EMBRACE THE POWER OF THE SQUARE"))}
            </TheBuildingParagraphs>
            <TheBuildingJustifyContent width={"150px"} height={"40px"}>
                <Link to="/leasing#office" onClick={() => scrollToSection("office")}>
                    <HeroButtonComponent width={"150px"} text={"EXPLORE"} color={"#F4F4F4"} />
                </Link>
            </TheBuildingJustifyContent>
        </HeroLanding>
    );
};

HeroSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default HeroSection;
