export const floor8to11 = [
    {
        id: 1,
        name: "11F",
        height: "37px"
    },
    {
        id: 2,
        name: "10F",
        height: "37px"
    },
    {
        id: 3,
        name: "9F",
        height: "37px"
    },
    {
        id: 4,
        name: "8F",
        height: "35px"
    }
];
