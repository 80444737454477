import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const savedLang = localStorage.getItem("selectedLang");
    const [selectedLang, setSelectedLang] = useState(savedLang || "en");

    return <LanguageContext.Provider value={{ selectedLang, setSelectedLang }}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const useLanguage = () => {
    const context = React.useContext(LanguageContext);
    return context;
};
