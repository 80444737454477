import PropTypes from "prop-types";
import { VirtualTourLanding } from "../../../style/VirtualTourScreen";
import MatterportViewer from "../../../components/MatterportViewer";

const VirtualTourMobile = ({ matterPortUrl }) => {
    return <VirtualTourLanding id="virtualtour">{matterPortUrl ? <MatterportViewer width="100%" height="100%" src={matterPortUrl} /> : null}</VirtualTourLanding>;
};

VirtualTourMobile.propTypes = {
    matterPortUrl: PropTypes.string
};

export default VirtualTourMobile;
