import React, { useState } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import * as MobileSections from "./Mobile.Sections";
import { useWindowWidth } from "../../breakpoints";

const TheBuildingScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };

    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight - 145}px`}>
                    <Sections.HeroSection onHeightChange={handleHeightChange} />
                    <Sections.KeyFeaturesSection onHeightChange={handleHeightChange} />
                    <Sections.AmenitiesSection onHeightChange={handleHeightChange} />
                    <Sections.SustainabilitySection onHeightChange={handleHeightChange} />
                </DesktopLayout>
            ) : (
                <>
                    <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                        <MobileSections.HeroMobile />
                        <MobileSections.KeyFeaturesMobile />
                        <MobileSections.AmenitiesMobile />
                        <MobileSections.SustainabilityMobile />
                    </DesktopLayout>
                </>
            )}
        </>
    );
};

export default TheBuildingScreen;
