import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { DeveloperImage, DeveloperWrapper, HeadLineWrapper, DeveloperSpan, VerticalLine } from "../../../style/DeveloperScreen";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const PartnersSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    return (
        <DeveloperWrapper id="partners" height={"790px"} direction={"column"} align={"center"} ref={sectionRef}>
            <HeadLineWrapper languagecode={currentLanguageCode} align={"center"} wspace={"nowrap"} fsize={"47px"} linehg={"57px"} fweight={"700"} margin={"100px 0 0 0"}>
                {t("OUR PARTNERS")}
            </HeadLineWrapper>

            <DeveloperWrapper width={"100%"} padding={"60px 82px 0 82px"} gap={"35px"}>
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-vinata.png" />
                    <DeveloperSpan languagecode={currentLanguageCode}>{t("Main Contractor & Architect")}</DeveloperSpan>
                </DeveloperWrapper>
                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-vncc.png" />
                    <DeveloperSpan languagecode={currentLanguageCode}>{t("Design Consultant")}</DeveloperSpan>
                </DeveloperWrapper>

                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"}>
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-kinden.png" />
                    <DeveloperSpan languagecode={currentLanguageCode}>{t("M&E Contractor KINDEN")}</DeveloperSpan>
                </DeveloperWrapper>

                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-yurtec.png" />
                    <DeveloperSpan languagecode={currentLanguageCode}>{t("M&E Contractor YURTEC")}</DeveloperSpan>
                </DeveloperWrapper>

                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-ibst.png" />
                    <DeveloperSpan languagecode={currentLanguageCode}>{parse(t("Project Management & Site Supervision"))}</DeveloperSpan>
                </DeveloperWrapper>
            </DeveloperWrapper>

            <DeveloperWrapper margin={"70px 0 90px 0"} gap="45px">
                <DeveloperWrapper direction={"column"} align={"center"} width={"190px"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-jll.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} margin={"6px 0 0 0"}>
                        {parse(t("Strategic Leasing & Marketing Consultant"))}
                    </DeveloperSpan>
                </DeveloperWrapper>

                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"} width={"190px"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-visaho.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} margin={"6px 0 0 0"}>
                        {parse(t("Property Management Support"))}
                    </DeveloperSpan>
                </DeveloperWrapper>

                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"} width={"190px"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-alsok.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} margin={"6px 0 0 0"}>
                        {t("Security Service")}
                    </DeveloperSpan>
                </DeveloperWrapper>

                <VerticalLine />
                <DeveloperWrapper direction={"column"} align={"center"} width={"190px"}>
                    {" "}
                    <DeveloperImage width={"190px"} height={"115px"} fit={"contain"} transform={""} src="./img/p-meibi.png" />
                    <DeveloperSpan languagecode={currentLanguageCode} margin={"6px 0 0 0"}>
                        {t("Cleaning Service")}
                    </DeveloperSpan>
                </DeveloperWrapper>
            </DeveloperWrapper>
        </DeveloperWrapper>
    );
};

PartnersSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default PartnersSection;
