import PropTypes from "prop-types";

const MatterportViewer = ({ src, width, height }) => {
    return <iframe title="virtual-tour-materport" width={width} height={height} src={src} allowFullScreen allow="xr-spatial-tracking"></iframe>;
};

MatterportViewer.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired
};

export default MatterportViewer;
