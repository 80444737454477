import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { MediaDivWrapper, MediaDisplay, MediaParagraphs, ParagraphTitleStyled } from "../../../style/MediaScreen";
import { fetchMediaById } from "../../../api/media";
import { Spin } from "antd";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const ellipsTitleMobile = {
    rows: 2,
    expandable: false
};

const PostModal = ({ data, isMobile = false }) => {
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(true);
    if (!data) {
        return null;
    }
    const { category, title, postDated, id } = data;
    const getMedia = async () => {
        const fetchedContent = await fetchMediaById(id);
        setContent(fetchedContent);
        setLoading(false);
    };

    useEffect(() => {
        if (data) {
            ReactGA.event({
                category: "Click",
                action: "Click",
                label: `Media - ${category}: ${title}`
            });
            setContent("");
            setLoading(true);
            getMedia();
        }
    }, [id]);

    const { i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    if (isMobile) {
        return (
            <Spin spinning={loading} tip="Loading...">
                <MediaDisplay direction={"column"} height={"auto"} width={"100%"} padding={"30px 20px"}>
                    <MediaParagraphs languagecode={currentLanguageCode} width={"100%"} fsize={"18px"} align={"center"} linehg={"18px"} fweight={"700"} wspace={""}>
                        <ParagraphTitleStyled width={"100%"} height={"auto"} ellipsis={ellipsTitleMobile}>
                            {parse(title)}
                        </ParagraphTitleStyled>
                    </MediaParagraphs>
                    <MediaDisplay direction={"row"} justify={"center"} padding={"10px 0"}>
                        <MediaParagraphs languagecode={currentLanguageCode} color={"#2C53AF"} fsize={"16px"} align={"center"} linehg={"16px"} fweight={"700"} padding={"0 5px 0 0"}>
                            {parse(`[${category}]`)}
                        </MediaParagraphs>
                        <MediaParagraphs languagecode={currentLanguageCode} color={"#A5D9FB"} fsize={"16px"} align={"center"} linehg={"16px"} fweight={"700"}>
                            {parse(postDated)}
                        </MediaParagraphs>
                    </MediaDisplay>

                    <MediaDisplay direction={"column"} height={"100%"} align={"center"} width={"100%"}>
                        <MediaDivWrapper width={"100%"} height={"100%"}>
                            <MediaParagraphs languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} width={"100%"} height={"100%"} fsize={"18px"} align={"left"} linehg={currentLanguageCode === "jp" ? 1.2 : 1.15} fweight={"400"} wspace={""}>
                                {parse(content)}
                            </MediaParagraphs>
                        </MediaDivWrapper>
                    </MediaDisplay>
                </MediaDisplay>
            </Spin>
        );
    } else {
        return (
            <Spin spinning={loading} tip="Loading...">
                <MediaDisplay direction={"column"} height={"auto"} width={"100%"} padding={"60px 40px"}>
                    <MediaParagraphs languagecode={currentLanguageCode} fsize={"25px"} linehg={"22px"} fweight={"700"}>
                        {parse(title)}
                    </MediaParagraphs>
                    <MediaDisplay direction={"row"} justify={"center"} padding={"10px 0 30px 0px"}>
                        <MediaParagraphs languagecode={currentLanguageCode} color={"#2C53AF"} fsize={"21px"} align={"center"} linehg={"21px"} fweight={"700"} padding={"0 10px 0 0"}>
                            {parse(`[${category}]`)}
                        </MediaParagraphs>
                        <MediaParagraphs languagecode={currentLanguageCode} color={"#A5D9FB"} fsize={"21px"} align={"center"} linehg={"21px"} fweight={"700"}>
                            {parse(postDated)}
                        </MediaParagraphs>
                    </MediaDisplay>

                    <MediaDisplay direction={"column"} height={"100%"} align={"center"} width={"100%"}>
                        <MediaDivWrapper width={"80%"} height={"100%"}>
                            <MediaParagraphs languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} width={"100%"} height={"100%"} fsize={"18px"} align={"left"} linehg={currentLanguageCode === "jp" ? 1.2 : 1.15} fweight={"400"} wspace={""}>
                                {parse(content)}
                            </MediaParagraphs>
                        </MediaDivWrapper>
                    </MediaDisplay>
                </MediaDisplay>
            </Spin>
        );
    }
};

PostModal.propTypes = {
    data: PropTypes.object,
    isMobile: PropTypes.bool
};

export default PostModal;
