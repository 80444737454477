import styled from "styled-components";

export const FooterMenu = styled.footer`
    background-color: #1b2e59;
    height: ${(props) => props.height ?? "140px"};
    position: absolute;
    bottom: ${(props) => props.bottom ?? ""};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 8px;

    @media (min-width: 768px) and (max-width: 1279px) {
        height: 170px;
    }
`;

export const FooterContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${(props) => props.width ?? "100%"};
    height: ${(props) => props.height ?? "40px"};
    margin: 0 0 20px 0;
`;

export const ParagraphsContent = styled.p`
    color: #ffffff;
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    text-align: center;
    white-space: ${(props) => props.wspace ?? "nowrap"};
`;

export const SpanContent = styled.span`
    font-family: inherit;
    color: ${(props) => props.color ?? "#f4f4f4"};
    font-size: 12px;
    line-height: 15px;
    font-weight: ${(props) => props.fweight ?? "300"};
`;

export const FooterIcon = styled.div`
    display: flex;
    justify-content: center;
    height: 30px;
    width: 100%;
    margin-bottom: 10px;
`;

export const IconWrapper = styled.div`
    background-image: url("./img/${(props) => props.iconimage}");
    background-position: 50% 50%;
    background-size: cover;
    height: 30px;
    width: 30px;
    margin-right: 10px;
`;
