{
    "REACH OUT TO US": "LIÊN HỆ VỚI CHÚNG TÔI",
    "HOTLINE": "ĐƯỜNG DÂY NÓNG",
    "INQUIRY": "HỘP THƯ ĐIỆN TỬ",
    "ADDRESS": "ĐỊA CHỈ",
    "UPCOMING LEASING EVENT CONTACT": "SỰ KIỆN CHO THUÊ SẮP DIỄN RA",
    "SEND A MESSAGE": "GỬI LỜI NHẮN",
    "NAME": "TÊN",
    "SALUTATION": "DANH XƯNG",
    "EMAIL": "EMAIL",
    "PHONE NO.": "SỐ ĐIỆN THOẠI",
    "YOUR MESSAGE": "LỜI NHẮN",
    "SEND": "GỬI",
    "PRIVACY POLICY": "chính sách bảo mật",
    "POLICY CONTENT": "Đánh dấu ô này để đồng ý với ",
    "PRIVACY POLICY CUSTOM": " của chúng tôi và gửi lời nhắn."
}
