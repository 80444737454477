import styled from "styled-components";

export const PolicyDisplay = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
    height: ${(props) => props.height ?? "auto"};
    margin-top: 100px;
`;

export const PolicyContent = styled.div`
    width: ${(props) => props.width ?? ""};
    margin: ${(props) => props.margin ?? ""};
    padding: ${(props) => props.padding ?? ""};
`;

export const PolicyParagraph = styled.div`
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: ${(props) => props.align ?? "center"};
    font-family: ${(props) => props.font ?? "Segoe UI, Helvetica"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "20px"};
    text-shadow: ${(props) => props.shadow ?? ""};
    padding: ${(props) => props.padding ?? ""};
`;
