export const officeDataMobile = [
    { name: "20F", hvcolor: "#cbe7f2", className: "floorplan-16_20", width: "100%", position: "", pathModal: "./img/floorplan-16_20.png" },
    { name: "15F", hvcolor: "#cbe7f2", className: "floorplan-13_15", width: "100%", position: "", pathModal: "./img/floorplan-13_15.png" },
    { name: "12F", hvcolor: "#cbe7f2", className: "floorplan-12", width: "100%", position: "", pathModal: "./img/floorplan-12.png" },
    { name: "11F", hvcolor: "#cbe7f2", className: "floorplan-8_11", width: "100%", position: "", pathModal: "./img/floorplan-8_11.png" },
    { name: "7F", hvcolor: "#cbe7f2", className: "floorplan-3-7", width: "100%", position: "", pathModal: "./img/floorplan-3_7.png" },
    { name: "2F", hvcolor: "#ebfcb0", className: "floorplan-2", width: "100%", position: "", pathModal: "./img/floorplan-2-retail.png" },
    { name: "1F", hvcolor: "#ebfcb0", className: "floorplan-1", width: "100%", position: "", pathModal: "./img/floorplan-1.png" }
];

export const officeDataDesktop = [
    { name: "20F", hvcolor: "#cbe7f2", className: "floorplan-16_20", width: "495px", height: "182px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-16_20.png" },
    { name: "15F", hvcolor: "#cbe7f2", className: "floorplan-13_15", width: "108px", height: "108px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-13_15.png" },
    { name: "12F", hvcolor: "#cbe7f2", className: "floorplan-12", width: "37px", height: "37px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-12.png" },
    { name: "11F", hvcolor: "#cbe7f2", className: "floorplan-8_11", width: "145px", height: "145px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-8_11.png" },
    { name: "7F", hvcolor: "#cbe7f2", className: "floorplan-3-7", width: "145px", height: "182px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-3_7.png" },
    { name: "2F", hvcolor: "#ebfcb0", className: "floorplan-2", width: "40px", height: "42px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-2-retail.png" },
    { name: "1F", hvcolor: "#ebfcb0", className: "floorplan-1", width: "40px", height: "42px", top: "0", position: "relative", left: "0", pathModal: "./img/floorplan-1.png" }
];
