export const achivementsInVN = [
    {
        id: 1,
        image: "./img/roygent.png",
        title: "ROYGENT PARKS HANOI",
        date: "2018",
        description: "ROYGENT PARKS HANOI CONTENT"
    },
    {
        id: 2,
        image: "./img/noibait2.png",
        title: "T2 NOI BAI INTL AIRPORT",
        date: "2015",
        description: "T2 NOI BAI INTL AIRPORT CONTENT"
    },
    {
        id: 3,
        image: "./img/canthobrg.png",
        title: "CAN THO BRIDGE",
        date: "2010",
        description: "CAN THO BRIDG CONTENT"
    }
];
