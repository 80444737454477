{
    "EMBRACE THE POWER OF THE SQUARE": "EMBRACE <br /> THE POWER OF THE SQUARE",
    "EMBRACE THE POWER OF THE SQUARE MOBILE": "EMBRACE <br /> THE POWER OF THE SQUARE",
    "KEY FEATURES": "KEY FEATURES",
    "KEY FEATURES DESCRIPTION": "To provide true Japanese quality, not only we applied Japanese brands for our main equipment but we also joined with Taisei design and construction team to consider every detail design and planning in order to commit the quality and to provide best comfort to the office workers and visitors.",

    "Column Free": "Column Free",
    "Column Free Title": "INTERNATIONAL OFFICE STANDARD OF 2.7M CLEAR CEILING HEIGHT FOR TENANT'S SPACE",
    "Column Free Description": "<p>A clearance height of 2.7m from grid ceiling surface to finished floor allows better air circulation in tenant's space. </p> <p style='padding-top: 10px;'>Developed with Japanese standards, we provide our tenants concealed ceiling of clean and neat look, fully equipped with LED panel lighting, air-con grills, double sprinkler system, smoke detectors, PA speakers, etc.</p> <p style='padding-top: 10px;'>Combined with the free-column layout, tenants can utilize the most of each square meter to create a healthy yet efficient workspace.</p>",

    "Panoramic View": "Panoramic <br /> View",
    "Panoramic View Title": "FULL HEIGHT GLASS WINDOW OFFERS PANORAMIC VIEW TO THE PARK AND ABUNDANT NATURAL LIGHT",
    "Panoramic View Description": "<p>With the building located opposite Thanh Xuan Park, tenants can enjoy the magnificent view of lush greenery along the elegant curves of Nhan Chinh Detention Basin while working in the office.</p>  <p style='padding-top: 10px;'>The full glass facade design allow maximum natural light into tenant&#39;s space, saving energy for illumination during the day following our commitment to sustainability.</p>",

    "Air Quality": "Air <br /> Quality",
    "Air Quality Title": "HIGHLY EFFICIENT VRF AIR-CONDITIONING SYSTEM PROVIDES BEST INDOOR AIR QUALITY",
    "Air Quality Description": "<p>VRF Air-conditioning System from PANASONIC with regional temperature control within tenant space, which efficiently adjusts air temperature to fit each and every group of users.</p> <p style='padding-top: 10px;'>On top of that, the ventilation system is equipped with PM2.5 filters that efficiently prevent dust particles in the air. Paired with Panasonic's nanoe™ X technology which is able to perform excellent effectiveness on inhibiting not only airborne bacteria and virus but even longer-living adhered ones, Taisei Square Hanoi provides the best indoor air quality for its tenants.</p>",

    "Raised Floor": "Raised <br /> Floor",
    "Raised Floor Title": "RAISED ACCESS FLOOR SYSTEM WITH EXCELLENT COST PERFORMANCE",
    "Raised Floor Description": "<p>100mm raised panels from NICHIAS with high-strength reinforced concrete panel and two tiered rubber gasket lock system that create a sturdy OA floor is essential a secondary floor surface with superior walking comfort.</p><p style='padding-top: 10px;'>These accessible flooring system allows cost and time reduction during renovation and alteration.</p>",

    "Elevator": "Elevator",
    "Elevator Title": "HIGH SPEED ELEVATORS ENSURE SWIFT AND SAFE VERTICAL TRANSPORTATION",
    "Elevator Description": "<p>08 passenger lifts, 01 basement lift and 01 cargo lift by FUJITEC with smart destination control system, the building elevator planning promises to minimize wait time and deliver smooth, efficient experiences for its users.</p><p style='padding-top: 10px;'>Besides, our commitment to tenants&#39; wellness is also reflected in elevator system: touchless buttons protect passengers from transmitted diseases by surface adhered bacteria and viruses; IONFULL technology developed by Sharp purifies the air in elevator car by using plasmacluster ions.</p>",

    "Power Backup": "100% Power <br /> Backup",
    "Power Backup Title": "100% POWER BACKUP ALLOWS SEAMLESS OPERATION",
    "Power Backup Description": "With 3 MITSUBISHI 1,650 KVA generators which were catered for 24 hour full power backup including air-conditioning, our tenants can rest assure of uninterrupted business operation during power shortages that are not rare in Hanoi in hot season.",

    "Water Quality": "Water <br /> Quality",
    "Water Quality Title": "THREE-PHASE WATER FILTRATION SYSTEM APPLIED FOR WHOLE BUILDING",
    "Water Quality Description": "Taisei Square Hanoi considers water is another important element that affects greatly to tenants' wellness. Therefore, we adapted NAKAGAWA CHEMICAL water treatment system to the whole building, including water for cleaning and washing. Water used in our building is free of impurities larger than 10 micron, harmful organic compounds and residual chlorine.",

    "AMENITIES": "AMENITIES",
    "AMENITIES DESCRIPTION": "The building proudly offers thoughtfully crafted services for its tenants as well as all visitors with Japanese hospitality 'omotenashi', ease of use and comfortableness.",

    "Grand Entrance": "Grand<br />Entrance",
    "Grand Entrance Title": "SPACIOUS SHELTERED DROP-OFF AREA",
    "Grand Entrance Description": "<p>Tenants and visitors can enjoy the comfort of easy and safe transition from getting off vehicles to enter the building regardless of the weather thanks to the large canopy that cover both cars and passengers.</p><p style='padding-top: 10px;'>Another feature of Taisei Square Hanoi, which is rare among buildings, is the thoughtful vestibule - an antechamber that regulates temperature difference between indoor and outdoor space and prevents wind from entering the interior space in extreme weather.</p>",

    "Welcome Lobby": "Welcome<br />Lobby",
    "Welcome Lobby Title": "GRAND WELCOME MAIN LOBBY WITH SOPHISTICATED JAPANESE STYLED INTERIOR",
    "Welcome Lobby Description": "<p>Getting through the entrance, Taisei Square Hanoi welcomes its visitors with a grand, yet modest lobby in a warm earthtone interior of wood and natural stone, accented with daily groomed plants.</p>  <p style='padding-top: 10px;'>Tenants and visitors can wait, talk or have informal meetings at casual seatings planned around the lobby.<p>",

    "Retail Podium": "Retail<br />Podium",
    "Retail Podium Title": "CONVENIENT RETAIL SHOPS FOR ALL YOUR WORK PROFESSIONALS' NEEDS",
    "Retail Podium Description": "In retail podium, there is a variety of shops to name: cafe, restaurant, minimart, bank, etc. that not only meet the need and convenience of upper office tenants but also form a lively micro community that attracts visitors from surrounding neighbourhood and nearby areas.",

    "Japanese Standard Restroom": "Japanese<br />Standard<br />Restroom",
    "Japanese Standard Restroom Title": "JAPANESE STANDARD RESTROOM",
    "Japanese Standard Restroom Description": "<p>From design, finishing materials to TOTO sanitary fixtures, all restrooms in the building follow Japanese standards.</p> <p style='padding-top: 10px;'>Especially, the automatic bidet in toilet booths can be considered the most significant uniquely Japan feature here, which is proven to aid in greatly personal hygiene level, thus improve users' health.</p><p style='padding-top: 10px;'>Another notable one is the dedicated powder corner in female toilets, we care for ladies.</p>",

    "Nursing Room": "Nursing<br />Room",
    "Nursing Room Title": "NURSING ROOM FOR WORKING MOMS",
    "Nursing Room Description": "<p>A clean, comfortable, and private space for women, where working moms can effectively express for storing breastmilk while at work or breastfeeding visitors can take care of their baby safely.</p><p style='padding-top: 10px;'>At Taisei Square Hanoi, we cater for all of our users' need.</p>",

    "Wet Pantry": "Wet<br />Pantry",
    "Wet Pantry Title": "ROOMY WET PANTRY ON ALL OFFICE FLOORS",
    "Wet Pantry Description": "Our tenants do not need to sacrifice precious square meters of office space and allocate expense planning their own kitchen sink, or utilizing restroom's faucets as washing station, since the building provides a nice and convenient common-use wet pantry with hot water and tabletop by the window on every office floor, where tenants can come for a short break which helps refresh the mind anytime. With frequent cleaning schedule, we promise to maintain best service for customers.",

    "Parking": "Parking",
    "Parking Title": "AMPLE PARKING CAPACITY IN 4 LARGE BASEMENTS",
    "Parking Description": "<p>With the characteristic of using personal vehicles for daily commuting needs of tenants, Taisei Square Hanoi was designed and developed with 4 large basement floors of more than 10,000 square meters dedicated for parking.</p><p style='padding-top: 10px;'>Other than that, outdoor parking space was planned thoughtfully for the ease and convenience of temporary visitors.</p>",

    "DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE": "DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE",
    "LEED CERTIFICATE": "LEED CERTIFICATE",
    "LEED CERTIFICATE DESCRIPTION": "Leadership in Energy and Environmental Design (LEED) is the most widely used and well recognized green building rating system internationally. LEED certification is now used for practically all buildings, both commercial and residential, and project types – from new construction and interior fit-outs to sustainable operations and maintenance upgrades.",
    "Taisei Square Hanoi Description": "<p>At Taisei Square Hanoi, we thrive to create a healthy, highly efficient and cost-saving green building by reducing the carbon footprint from construction phase to daily operation and improving the indoor environmental qualities for our tenants' wellness.</p><p style='padding-top: 10px;'>As a proof of our commitment, Taisei Square Hanoi was certified with LEED Silver, and proudly being the first globally accredited sustainable office building in West Hanoi.</p>",

    "First Taisei Square Hanoi": "At Taisei Square Hanoi, we thrive to create a healthy, highly efficient and cost-saving green building by reducing the carbon footprint from construction phase to daily operation and improving the indoor environmental qualities for our tenants' wellness.",
    "Second Taisei Square Hanoi": "As a proof of our commitment, Taisei Square Hanoi was certified with LEED Silver, and proudly being the first globally accredited sustainable office building in West Hanoi.",

    "Eco Construction": "Eco- <br />Construction",
    "Eco Construction Title": "ECO-CONSTRUCTION",
    "Eco Construction Description": "At the building construction stage, construction activity pollution prevention plan, demolition waste management plan, indoor air quality management plan has been made and the contractor have been following these plans to minimize the environmental impact due to the construction activity. Also, we conducted the buildings' life cycle assessment and the analysis result showed 410t CO2.e reduction due to reduced emissions from structural design and material selection.",

    "Energy Efficiency": "Energy<br />Efficiency",
    "Energy Efficiency Title": "ENERGY EFFICIENCY",
    "Facade": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>Facade</span>&nbsp;&nbsp;Low-E (low emission) double-glazed glass provides supreme insulating power for the building&#39;s windows, which keep the interior warmer in winter by preventing heat lost and reduce sun radiation in hot summer hence cooler indoor air. Both effects save energy for heating and cooling, therefore reduce the building&#39;s carbon footprint.",
    "Ventilation System": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>Ventilation System</span>&nbsp;&nbsp;The application of the innovative Heat Exchanger design efficiently utilize the indoor return air to cool down the hot outdoor fresh air at air intake, resulting in saving energy by reducing cooling load for Air-conditioning system.",
    "Automatic Lighting Control": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>Automatic Lighting Control</span>&nbsp;&nbsp;Lighting in emergency staircases and toilet areas was equipped with motion sensors that turn off the lights when there are nobody using the place.",

    "Water Efficiency": "Water<br />Efficiency",
    "Water Efficiency Title": "WATER EFFICIENCY",
    "Water Efficiency Description": "Our building has benchmarked our sustainability goal to reduce 42% of indoor water consumption by fitting water saving sanitary fixtures in toilets and employing smart building management system which allows staff to monitor the water consumption and manage leakage.",

    "Accessible Design": "Accessible<br />Design",
    "Accessible Design Title": "ACCESSIBLE DESIGN",
    "Accessible Design Description": "As Taisei Square Hanoi's commitment to all of our customers, the podium is specially designed with accessible slopes from street level to the main lobby, universal toilets and designated elevator that aid people with physical limitation or reduced mobility.",

    "CONTACT US": "CONTACT US",
    "EXPLORE": "EXPLORE"
}
