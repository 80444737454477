import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { DeveloperSection, DeveloperImage, DeveloperWrapper, HeadLineWrapper, Retangle, ParagraphWrapper, ImageContainer } from "../../../style/DeveloperScreen";
import { achivementsInVN } from "../Data/achivementsInVN";
import { achivementsInJP } from "../Data/achivementsInJP";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Achivement = ({ data }) => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const { image, title, date, description } = data;

    return (
        <DeveloperWrapper className="achivement-column" width={"394px"} direction={"column"} gap={"20px"}>
            <ImageContainer>
                <DeveloperImage height={"244px"} width={"394px"} src={image} />
            </ImageContainer>
            <DeveloperWrapper gap={"9px"} align={"flex-end"}>
                <Retangle mgbottom={title === "T2 NOI BAI INTL AIRPORT" && (currentLanguageCode === "vi" || currentLanguageCode === "jp") ? "2px" : "2px"} />
                <HeadLineWrapper languagecode={currentLanguageCode} margin={"0 10px 0 0"} fsize={"18px"} fweight={"700"}>
                    {parse(t(title))}
                </HeadLineWrapper>{" "}
            </DeveloperWrapper>
            <ParagraphWrapper languagecode={currentLanguageCode} align={"justify"}>
                <HeadLineWrapper languagecode={currentLanguageCode} color={"#122658"} font={"'Segoe UI', Helvetica"} fsize={"18px"} linehg={"24px"} fweight={"700"} padding={"0 10px 0 0"}>
                    {currentLanguageCode === "jp" ? `${date} 年` : date}
                </HeadLineWrapper>
                {t(description)}
            </ParagraphWrapper>
        </DeveloperWrapper>
    );
};

Achivement.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired
};

let achivementsInVNComponent = [];
achivementsInVN.forEach((achive) => {
    achivementsInVNComponent.push(<Achivement key={achive.id} data={achive} />);
});

let achivementsInJPComponent = [];
achivementsInJP.forEach((achive) => {
    achivementsInJPComponent.push(<Achivement key={achive.id} data={achive} />);
});

const AchievementSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    return (
        <DeveloperSection id="investor" height={"1170px"} ref={sectionRef}>
            <DeveloperWrapper margin={"60px 0 0 0"} direction={"column"}>
                <HeadLineWrapper languagecode={currentLanguageCode} wspace={"nowrap"} fsize={"32px"} fweight={"700"} linehg={"32px"}>
                    {t("NOTABLE ACHIEVEMENTS IN VIETNAM")}
                </HeadLineWrapper>
                <DeveloperWrapper gap="20px" margin="10px 0 0 0">
                    {" "}
                    <HeadLineWrapper languagecode={currentLanguageCode} width="394px" wspace={"nowrap"} fsize={"21px"} fweight={"700"}>
                        | {t("Developed and Constructed by Taisei")}
                    </HeadLineWrapper>{" "}
                    <DeveloperWrapper width="394px">
                        <HeadLineWrapper languagecode={currentLanguageCode} width="394px" wspace={"nowrap"} fsize={"21px"} fweight={"700"}>
                            | {t("Constructed by Taisei")}
                        </HeadLineWrapper>
                    </DeveloperWrapper>
                </DeveloperWrapper>
                <DeveloperWrapper className="achievement-list-in-vn" margin={"10px 0 0 0"} gap={"20px"}>
                    {achivementsInVNComponent}
                </DeveloperWrapper>
                <HeadLineWrapper languagecode={currentLanguageCode} fsize={"32px"} fweight={"700"} wspace={"nowrap"} lineh="35px" margin={"44px 0 0 0"}>
                    {t("NOTABLE ACHIEVEMENTS IN JAPAN")}
                </HeadLineWrapper>
                <DeveloperWrapper gap="20px" margin="10px 0 0 0" justify={"space-between"}>
                    {" "}
                    <HeadLineWrapper languagecode={currentLanguageCode} width="394px" wspace={"nowrap"} fsize={"21px"} fweight={"700"}>
                        | {t("Developed and Constructed by Taisei")}
                    </HeadLineWrapper>{" "}
                    <DeveloperWrapper width="394px">
                        <HeadLineWrapper languagecode={currentLanguageCode} width="394px" fsize={"21px"} fweight={"700"} wspace={"nowrap"}>
                            | {t("Constructed by Taisei")}
                        </HeadLineWrapper>
                    </DeveloperWrapper>
                </DeveloperWrapper>
                <DeveloperWrapper className="achievement-list-in-jp" margin={"10px 0 0 0"} gap={"20px"}>
                    {achivementsInJPComponent}
                </DeveloperWrapper>
            </DeveloperWrapper>
        </DeveloperSection>
    );
};

AchievementSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default AchievementSection;
