import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { VirtualTourLanding } from "../../../style/VirtualTourScreen";
import MatterportViewer from "../../../components/MatterportViewer";

const VirtualTourSection = ({ onHeightChange, matterPortUrl }) => {
    const sectionRef = useRef(null);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);

    return (
        <VirtualTourLanding id="virtualtour" ref={sectionRef}>
            {matterPortUrl ? <MatterportViewer width="100%" height="100%" src={matterPortUrl} /> : null}
        </VirtualTourLanding>
    );
};

VirtualTourSection.propTypes = {
    onHeightChange: PropTypes.func,
    matterPortUrl: PropTypes.string
};

export default VirtualTourSection;
