{
    "REACH OUT TO US": "REACH OUT TO US",
    "HOTLINE": "ホットライン",
    "INQUIRY": "メールアドレス",
    "ADDRESS": "住所",
    "UPCOMING LEASING EVENT CONTACT": "リーシングイベント情報",
    "SEND A MESSAGE": "お問い合わせ",
    "NAME": "お名前",
    "SALUTATION": "SALUTATION",
    "EMAIL": "メールアドレス",
    "PHONE NO.": "電話番号",
    "YOUR MESSAGE": "お問い合わせ内容",
    "SEND": "送信",
    "PRIVACY POLICY": "privacy policy (個人情報の取り扱いに関する規約)",
    "POLICY CONTENT": "このボックスにチェックを入れると、弊社の ",
    "PRIVACY POLICY CUSTOM": " に同意したものとみなされます。"
}
