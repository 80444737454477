{
    "EMBRACE THE POWER OF THE SQUARE": "NẮM BẮT <br /> ĐIỂM MẠNH CỦA 'THE SQUARE'",
    "EMBRACE THE POWER OF THE SQUARE MOBILE": "NẮM BẮT <br /> ĐIỂM MẠNH <br /> CỦA 'THE SQUARE'",
    "KEY FEATURES": "ĐẶC ĐIỂM NỔI BẬT",
    "KEY FEATURES DESCRIPTION": "Nhằm cung cấp chất lượng Nhật Bản thực thụ, chúng tôi không chỉ sử dụng các trang thiếtbị Nhật mà còn kết hợp với đội ngũ thiết kế, thi công của Taisei chăm chút, tính toán đến từng chi tiết để mang sự tiện nghi và chất lượng cam kết đến mọi khách thuê và người sử dụng.",

    "Column Free": "Mặt bằng <br /> không cột",
    "Column Free Title": "TRẦN CAO 2.7M THEO TIÊU CHUẨN VĂN PHÒNG QUỐC TẾ",
    "Column Free Description": "<p>Chiều cao thông thủy 2.7m từ trần hoàn thiện tới mặt sàn nâng giúp đối lưu không khí trong văn phòng tốt hơn.</p><p style='padding-top: 10px;'>Là nhà phát triển dự án chuẩn Nhật, chúng tôi cung cấp cho khách thuê mặt bằng với mặt trần thạch cao khung chìm hoàn thiện được trang bị đầy đủ hệ thống chiếu sáng đèn LED tấm, điều hòa âm trần, hệ thống đầu phun chữa cháy kép, đầu báo khói và loa thông báo khẩn cấp, v.v...</p> <p style='padding-top: 10px;'>Với thiết kế không cột, khách thuê có thể tận dụng tối đa từng mét vuông văn phòng tạo nên những không gian làm việc hiệu quả và trong lành.</p>",

    "Panoramic View": "Tầm nhìn <br /> khoáng đạt",
    "Panoramic View Title": "CỬA SỔ KÍNH RỘNG CHO TẦM NHÌN TOÀN CẢNH RA CÔNG VIÊN VÀ GIÚP LẤY ÁNH SÁNG TỰ NHIÊN TỐI ĐA",
    "Panoramic View Description": "<p>Với vị trí nằm đối diện công viên Thanh Xuân, từ văn phòng của mình khách thuê có thể ngắm nhìn khung cảnh xanh mướt của cây cối dọc theo những đường cong tao nhã của hồ điều hòa Nhân Chính.</p>  <p style='padding-top: 10px;'>Thiết kế mặt tiền toàn bộ bằng kính giúp cho không gian văn phòng luôn tràn ngập ánh sáng tự nhiên, tiết kiệm năng lượng chiếu sáng vào ban ngày, phù hợp với xu hướng phát triển bền vững mà chúng tôi cam kết.</p>",

    "Air Quality": "Chất lượng <br /> không khí",
    "Air Quality Title": "HỆ THỐNG ĐIỀU HÒA KHÔNG KHÍ VRF VỚI HIỆU NĂNG CAO MANG ĐẾN CHẤT LƯỢNG KHÔNG KHÍ TRONG NHÀ TỐT NHẤT",
    "Air Quality Description": "<p>Hệ thống điều hòa không khí VRF của PANASONIC với khả năng kiểm soát nhiệt độ theo từng khu vực trong văn phòng, giúp điều chỉnh nhiệt độ một cách hiệu quả để phù hợp với từng nhóm người dùng.</p> <p style='padding-top: 10px;'>Hơn hết, hệ thống thông gió được trang bị bộ lọc PM2.5 giúp ngăn chặn hiệu quả bụi mịn trong không khí. Kết hợp với công nghệ nanoe™ X của Panasonic với hiệu quả vượt trội trong việc ức chế không chỉ vi khuẩn và vi rút trong không trung mà cả những vi khuẩn bám lâu hơn trên bề mặt, Taisei Square Hanoi sẽ mang đến chất lượng không khí trong nhà tốt nhất cho khách thuê.</p>",

    "Raised Floor": "Sàn nâng",
    "Raised Floor Title": "HỆ THỐNG SÀN NÂNG MANG TỚI HIỆU SUẤT CHI PHÍ VƯỢT TRỘI",
    "Raised Floor Description": "<p>Sàn nâng 100mm của NICHIAS với những tấm bê tông cốt thép cường độ cao và hệ thống chân đỡ có khóa gioăng cao su kép tạo nên một bề mặt sàn phụ vững chãi, đi lại êm và đằm chân như trên mặt sàn chính.</p><p style='padding-top: 10px;'>Hệ thống sàn tháo lắp này cho phép giảm chi phí và thời gian khi cải tạo và thay đổi trong quá trình làm việc, đồng thời tạo ra lớp cách nhiệt giúp giảm bớt cái lạnh khi tiếp xúc trực tiếp với sàn bê tông vào mùa đông, tăng sự tiện nghi cho khách thuê.</p>",

    "Elevator": "Thang máy",
    "Elevator Title": "THANG MÁY TỐC ĐỘ CAO ĐẢM BẢO VẬN CHUYỂN NHANH CHÓNG VÀ AN TOÀN",
    "Elevator Description": "<p>Với 08 thang khách, 01 thang hầm và 01 thang hàng của FUJITEC kèm hệ thống điều khiển thông minh, Taisei Square Hanoi hứa hẹn giảm thiểu thời gian chờ đợi và mang lại trải nghiệm mượt mà, hiệu quả nhất cho người sử dụng.</p><p style='padding-top: 10px;'>Bên cạnh đó, như một phần cam kết của chúng tôi đối với sức khỏe của khách thuê, các nút điều khiển cảm ứng không chạm của thang máy có thể bảo vệ người dùng khỏi các bệnh lây truyền qua bề mặt. Ngoài ra, công nghệ IONFULL do Sharp phát triển với các ion plasmacluster sẽ thanh lọc không khí trong buồng thang, mang đến bầu không khí trong lành hơn cho người dùng.</p>",

    "Power Backup": "100% điện <br /> dự phòng",
    "Power Backup Title": "HỆ THỐNG ĐIỆN DỰ PHÒNG 100% CHO PHÉP VẬN HÀNH LIỀN MẠCH",
    "Power Backup Description": "Với 3 máy phát điện MITSUBISHI 1650 KVA có khả năng cung cấp điện năng dự phòng trong 24h bao gồm cả hệ thống điều hòa, khách thuê có thể yên tâm làm việc, hoạt động kinh doanh không bị gián đoạn ngay cả trong thời điểm thiếu điện thường xuyên ở Hà Nội vào mùa nắng nóng.",

    "Water Quality": "Chất lượng nước",
    "Water Quality Title": "HỆ THỐNG LỌC NƯỚC BA BƯỚC CHO TOÀN BỘ NƯỚC SỬ DỤNG TRONG TÒA NHÀ",
    "Water Quality Description": "Ở Taisei Square Hanoi, chúng tôi coi nước là một yếu tố quan trọng ảnh hưởng lớn đến sức khỏe của khách thuê. Vì vậy, chúng tôi đã lắp đặt hệ thống xử lý và lọc nước của NAKAGAWA CHEMICAL cho toàn bộ tòa nhà, bao gồm cả nước để làm sạch và cọ rửa. Nước sử dụng trong tòa nhà hoàn toàn không chứa tạp chất lớn hơn 10 micron, các hợp chất hữu cơ có hại và dư lượng clo.",

    "AMENITIES": "TIỆN ÍCH",
    "AMENITIES DESCRIPTION": "Tòa nhà tự hào cung cấp các dịch vụ được thiết kế chu đáo cho khách thuê cũng như tất cả khách vãng lai với tinh thần hiếu khách kiểu Nhật 'omotenashi', dễ sử dụng và thoải mái.",

    "Grand Entrance": "Lối vào <br /> chính",
    "Grand Entrance Title": "NƠI ĐÓN TRẢ KHÁCH RỘNG RÃI CÓ MÁI CHE",
    "Grand Entrance Description": "<p>Khách thuê và khách vãng lai có thể tận hưởng sự chuyển tiếp nhẹ nhàng và an toàn từ khi xuống xe đến khi bước chân vào tòa nhà bất kể thời tiết, nhờ mái che lớn bao phủ cả ôtô và hành khách.</p><p style='padding-top: 10px;'>Một đặc điểm khác biệt hiếm thấy ở các tòa nhà khác của Taisei Square Hanoi đó là việc bố trí phòng đệm ở cửa chính ra vào, là không gian điều hòa sự chênh lệch nhiệt độ giữa trong nhà và ngoài trời, ngăn gió lùa trực tiếp dưới thời tiết khắc nghiệt.</p>",

    "Welcome Lobby": "Sảnh đón",
    "Welcome Lobby Title": "SẢNH TIẾP ĐÓN SANG TRỌNG MANG CHẤT TINH TẾ CỦA NGƯỜI NHẬT",
    "Welcome Lobby Description": "<p>Bước qua cửa chính, Taisei Square Hanoi chào đón khách bằng không gian sảnh rộng lớn nhưng vẫn thật khiêm tốn với tông màu hài hòa kết hợp sự ấm áp của gỗ và chất thô mộc của đá tự nhiên, với điểm nhấn là những chậu cây thanh nhã được chăm sóc tỉ mỉ hàng ngày.</p>  <p style='padding-top: 10px;'>Bên cạnh đó, khách thuê và khách vãng lai có thể chờ đợi, nói chuyện hoặc trao đổi công việc ngắn gọn ở những ghế ngồi thoải mái được bố trí phù hợp trong sảnh.<p>",

    "Retail Podium": "Khu <br /> thương mại",
    "Retail Podium Title": "NHỮNG  DỊCH VỤ TIỆN LỢI CHO MỌI NHU CẦU CỦA DÂN VĂN PHÒNG",
    "Retail Podium Description": "Khối đế thương mại bao gồm nhiều dịch vụ như: cafe, nhà hàng, minimart, ngân hàng… không chỉ đáp ứng nhu cầu, sự tiện lợi cho khách thuê văn phòng ở các tầng trên mà còn hình thành một cộng đồng nhỏ nhưng không kém phần nhộn nhịp, thu hút cư dân và khách vãng lai từ các tòa chung cư, văn phòng xung quanh và các khu vực lân cận khác.",

    "Japanese Standard Restroom": "Nhà vệ sinh <br /> chuẩn Nhật",
    "Japanese Standard Restroom Title": "NHÀ VỆ SINH CHUẨN NHẬT",
    "Japanese Standard Restroom Description": "<p>Từ thiết kế, vật liệu hoàn thiện đến thiết bị vệ sinh TOTO, toàn bộ khu vệ sinh trong tòa nhà đều tuân theo tiêu chuẩn Nhật Bản.</p><p style='padding-top: 10px;'>Đặc biệt, bệ rửa tự động đặc trưng của Nhật Bản trang bị cho bồn cầu đã được chứng minh là hỗ trợ rất nhiều cho việc nâng cao vệ sinh cá nhân, cải thiện sức khỏe của người dùng. Một điều đáng chú ý nữa là góc trang điểm dành riêng cho chị em ở nhà vệ sinh nữ, đây cũng là một minh chứng cho sự quan tâm đến các quý cô.</p>",

    "Nursing Room": "Phòng <br /> dưỡng nhi",
    "Nursing Room Title": "NƠI CHĂM SÓC EM BÉ CHO NHỮNG BÀ MẸ VĂN PHÒNG",
    "Nursing Room Description": "<p>Một không gian sạch sẽ, tiện nghi và riêng tư dành cho phụ nữ, nơi các bà mẹ văn phòng có thể vắt để trữ sữa mẹ một cách hiệu quả khi đi làm. Khách vãng lai đang cho con bú cũng có thể chăm sóc em bé an toàn, lịch sự và kín đáo tại đây.</p><p style='padding-top: 10px;'>Ở Taisei Square Hanoi, chúng tôi đáp ứng nhu cầu của mọi đối tượng người sử dụng.</p>",

    "Wet Pantry": "Pantry ướt",
    "Wet Pantry Title": "PANTRY RỘNG RÃI Ở CÁC TẦNG VĂN PHÒNG",
    "Wet Pantry Description": "Khách thuê không phải hy sinh những mét vuông không gian văn phòng quý giá và phân bổ chi phí để làm pantry riêng, hay tận dụng vòi nước trong khu vệ sinh để rửa đồ, bởi tòa nhà đã trang bị phòng pantry sạch đẹp, tiện lợi, có bồn rửa và cả nước nóng dùng chung ở mỗi tầng văn phòng. Khách có thể sử dụng để pha trà, rửa cốc chén hay chỉ là ghé qua thư giãn đầu óc giữa những giờ làm việc căng thẳng. Với lịch vệ sinh thường xuyên, chúng tôi đảm bảo duy trì dịch vụ tốt nhất cho khách hàng.",

    "Parking": "Chỗ để xe",
    "Parking Title": "4 TẦNG HẦM RỘNG RÃI ĐẢM BẢO BỐ TRÍ CHỖ ĐỂ XE CHO KHÁCH THUÊ",
    "Parking Description": "<p>Với đặc điểm sử dụng phương tiện cá nhân cho nhu cầu đi lại hàng ngày của người Việt Nam, Taisei Square Hanoi được thiết kế và xây dựng với 4 tầng hầm rộng rãi, gồm hơn 10.000 m2 dành riêng cho việc để xe.</p><p style='padding-top: 10px;'>Ngoài ra, khu vực để xe ngoài trời được sắp xếp rất chu đáo và thuận tiện nhằm phục vụ nhu cầu tạm thời của khách vãng lai ghé vào khu thương mại, dịch vụ.</p>",

    "DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE": "THIẾT KẾ CHO TÍNH BỀN VỮNG, THIẾT KẾ CHO TƯƠNG LAI",
    "LEED CERTIFICATE": "CHỨNG CHỈ MÔI TRƯỜNG LEED",
    "LEED CERTIFICATE DESCRIPTION": "Leadership in Energy and Environmental Design (LEED) là hệ thống xếp hạng công trình xanh uy tín, được công nhận và sử dụng rộng rãi nhất trên toàn thế giới. Chứng chỉ LEED hiện được dùng để đánh giá hầu hết các loại công trình, từ thương mại và nhà ở, hay hạng mục thi công từ xây dựng mới và lắp đặt nội thất tới hoạt động vận hành bền vững và nâng cấp bảo trì.",
    "Taisei Square Hanoi Description": "<p>Tại Taisei Square Hanoi, chúng tôi nỗ lực làm nên một tòa nhà xanh, hiệu quả cao và tiết kiệm chi phí bằng cách giảm lượng khí thải carbon từ giai đoạn xây dựng đến quá trình vận hành hàng ngày, cải thiện chất lượng môi trường trong nhà để đảm bảo và nâng cao sức khỏe cho khách thuê.</p><p style='padding-top: 10px;'>Như một minh chứng cho cam kết của chúng tôi, Taisei Square Hanoi đã được chứng nhận LEED Bạc và tự hào sẽ là tòa nhà văn phòng bền vững được quốc tế công nhận đầu tiên ở Tây Hà Nội.</p>",

    "First Taisei Square Hanoi": "Tại Taisei Square Hanoi, chúng tôi nỗ lực làm nên một tòa nhà xanh, hiệu quả cao và tiết kiệm chi phí bằng cách giảm lượng khí thải carbon từ giai đoạn xây dựng đến quá trình vận hành hàng ngày, cải thiện chất lượng môi trường trong nhà để đảm bảo và nâng cao sức khỏe cho khách thuê.",
    "Second Taisei Square Hanoi": "Như một minh chứng cho cam kết của chúng tôi, Taisei Square Hanoi đã được chứng nhận LEED Bạc và tự hào sẽ là tòa nhà văn phòng bền vững được quốc tế công nhận đầu tiên ở Tây Hà Nội.",

    "Eco Construction": "Xây dựng <br /> xanh",
    "Eco Construction Title": "XÂY DỰNG XANH",
    "Eco Construction Description": "Ngay từ giai đoạn thi công xây dựng, chúng tôi đã lập kế hoạch ngăn ngừa ô nhiễm trong hoạt động xây dựng, quản lý chất thải xây dựng, chất lượng không khí trong nhà và đảm bảo các nhà thầu tuân thủ và thực hiện nghiêm chỉnh để giảm thiểu tác động môi trường do hoạt động xây dựng gây ra. Ngoài ra, chúng tôi đã tiến hành phân tích đánh giá vòng đời của tòa nhà và kết quả cho thấy giảm được 410 tấn CO2.e nhờ giải pháp thiết kế kết cấu và lựa chọn vật liệu.",

    "Energy Efficiency": "Hiệu suất <br /> năng lượng",
    "Energy Efficiency Title": "HIỆU SUẤT NĂNG LƯỢNG",
    "Facade": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>Mặt ngoài</span>&nbsp;&nbsp;Sử dụng kính hộp Low-E (phát xạ thấp) mang đến khả năng cách nhiệt tối ưu cho các cửa sổ của tòa nhà, giữ cho nhiệt độ trong nhà ấm hơn vào mùa đông nhờ ngăn ngừa thất thoát nhiệt và mát hơn vào mùa hè nhờ việc giảm bức xạ mặt trời. Cả hai tác dụng trên đều giúp tiết kiệm năng lượng trong việc điều hòa không khí, do đó làm giảm lượng khí thải carbon của tòa nhà.",
    "Ventilation System": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>Hệ thống thông gió</span>&nbsp;&nbsp;Ứng dụng công nghệ trao đổi nhiệt tiên tiến, tận dụng hiệu quả luồng gió hồi trong nhà để làm mát luồng khí tươi từ ngoài trời nóng vào, giảm tải cho hệ thống làm mát của điều hòa không khí và từ đó giúp tiết kiệm thêm năng lượng.",
    "Automatic Lighting Control": "<span style='color: #2c53af; font-family: SegoeUI-Bold, Helvetica; font-weight: 700;'>Chiếu sáng tự động</span>&nbsp;&nbsp;Hệ thống chiếu sáng ở cầu thang thoát hiểm và khu vệ sinh được trang bị cảm biến chuyển động để tắt đèn khi không có người sử dụng.",

    "Water Efficiency": "Hiệu năng <br /> sử dụng nước",
    "Water Efficiency Title": "HIỆU NĂNG SỬ DỤNG NƯỚC",
    "Water Efficiency Description": "Tòa nhà đã ghi dấu thêm một mục tiêu bền vững là giảm được 42% lượng nước tiêu thụ trong nhà bằng việc lắp các thiết bị vệ sinh tiết kiệm nước ở khu vệ sinh và sử dụng hệ thống quản lý tòa nhà thông minh cho phép nhân viên giám sát mức tiêu thụ để kịp thời phát hiện rò rỉ thất thoát nước.",

    "Accessible Design": "Thiết kế nâng cao <br /> khả năng tiếp cận",
    "Accessible Design Title": "THIẾT KẾ DÀNH CHO MỌI ĐỐI TƯỢNG",
    "Accessible Design Description": "Đúng như cam kết của Taisei Square Hanoi đối với tất cả khách hàng, khối đế được thiết kế đặc biệt với độ dốc dễ tiếp cận liền mạch từ mặt đường đến sảnh chính, nhà vệ sinh có hỗ trợ và thang máy dành cho xe lăn để những người bị hạn chế về thể chất hoặc suy giảm khả năng vận động như người già, người tàn tật, trẻ em... đều có thể thoải mái sử dụng mọi tiện nghi và công năng của tòa nhà.",

    "CONTACT US": "LIÊN HỆ",
    "EXPLORE": "EXPLORE"
}
