export const achivementsInJP = [
    {
        id: 1,
        image: "./img/okura.png",
        title: "THE OKURA TOKYO",
        date: "2019",
        description: "THE OKURA TOKYO CONTENT"
    },
    {
        id: 2,
        image: "./img/solacity.png",
        title: "OCHANOMIZU SOLACITY",
        date: "2013",
        description: "OCHANOMIZU SOLACITY CONTENT"
    },
    {
        id: 3,
        image: "./img/nationalstadium.png",
        title: "NATIONAL STADIUM",
        date: "2019",
        description: "NATIONAL STADIUM CONTENT"
    }
];
