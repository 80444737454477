import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VirtualTourDisplay, ContentWrapper, TitleWrapper, ImageContainer } from "../../../style/VirtualTourScreen";
import { ModalContent, GalleryContent, VirtualTourImage, ImageWrapperHover, SliderShowVirtualTour } from "../../../style/VirtualTourScreen";
import { Modal as ModalGallery } from "antd";
import { useTranslation } from "react-i18next";

const GallerySection = ({ onHeightChange, selectedLanguage, hasMatterport, datas }) => {
    const sectionRef = useRef(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const galleries = datas ? datas.galleries : [];
    const interiorCount = datas ? datas.interiorCount : 12;
    const carouselRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const openSlide = (index) => {
        setTimeout(() => {
            carouselRef.current.goTo(index, true);
        }, 10);
        setModalOpen(true);
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        hasMatterport ? onHeightChange((prevHeight) => prevHeight + sectionHeight) : onHeightChange(sectionHeight);
    }, [onHeightChange]);

    return (
        <VirtualTourDisplay id="gallery" ref={sectionRef}>
            <TitleWrapper languagecode={currentLanguageCode} color={"#353535"} fsize={"50px"} lineheight={"50px"} padding={hasMatterport ? "100px 0 43px 0" : "150px 0 43px 0"}>
                {t("PHOTO GALLERY")}
            </TitleWrapper>
            <GalleryContent margin={"43px"} padding={"0px 82px"}>
                <ContentWrapper className="INTERIOR" bgcolor={"#A5D9FB"}>
                    <TitleWrapper languagecode={currentLanguageCode} padding={"20px 35px"} color={"#f4f4f4"} fsize={"30px"} fweight={"600"} lineheight={"30px"}>
                        {t("INTERIOR")}
                    </TitleWrapper>
                </ContentWrapper>
                {galleries.map((image, index) =>
                    index !== interiorCount ? (
                        <ImageContainer key={index}>
                            <ImageWrapperHover className={`virtual-image-${index}`} justify={"center"} items={"center"} bgurl={`${process.env.REACT_APP_CMS}${image.imageUrl}`} onClick={() => openSlide(index)}>
                                <TitleWrapper color={"#f4f4f4"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} wspace={""} align={"center"} padding={"0px 30px"} fweight={"600"} fsize={"15px"} lineheight={"15px"}>
                                    {selectedLanguage === "en" ? image.caption : selectedLanguage === "jp" ? image.captionJP : image.captionVI}
                                </TitleWrapper>
                            </ImageWrapperHover>
                        </ImageContainer>
                    ) : (
                        <>
                            <ContentWrapper className="EXTERIOR" key={"exterior"} bgcolor={"#A5D9FB"}>
                                <TitleWrapper languagecode={currentLanguageCode} padding={"20px 35px"} color={"#f4f4f4"} fsize={"30px"} fweight={"600"} lineheight={"30px"}>
                                    {t("EXTERIOR")}
                                </TitleWrapper>
                            </ContentWrapper>
                            <ImageContainer key={index}>
                                <ImageWrapperHover className={`virtual-image-${index}`} justify={"center"} items={"center"} bgurl={`${process.env.REACT_APP_CMS}${image.imageUrl}`} onClick={() => openSlide(index)}>
                                    <TitleWrapper color={"#f4f4f4"} languagecode={currentLanguageCode} font={"'Segoe UI', Helvetica"} wspace={""} align={"center"} padding={"0px 30px"} fweight={"600"} fsize={"15px"} lineheight={"15px"}>
                                        {selectedLanguage === "en" ? image.caption : selectedLanguage === "jp" ? image.captionJP : image.captionVI}
                                    </TitleWrapper>
                                </ImageWrapperHover>
                            </ImageContainer>
                        </>
                    )
                )}
            </GalleryContent>

            <ModalGallery open={isModalOpen} onCancel={handleCancel} footer={null} width="90vw" padding={"10px"} zIndex={"999999999"} centered className="fullscreen-modal" closeIcon={true}>
                <VirtualTourImage width={"76px"} height={"76px"} src={"./img/arrow-left.png"} position={"absolute"} zindex={"999999999"} top={"50%"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.prev()} />
                <VirtualTourImage width={"76px"} height={"76px"} src={"./img/arrow-right.png"} position={"absolute"} zindex={"999999999"} top={"50%"} right={"0"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.next()} />

                <SliderShowVirtualTour dots={false} ref={carouselRef} waitForAnimate={false} autoplay={false}>
                    {galleries.map((image, index) => (
                        <ModalContent key={index}>
                            <VirtualTourImage fit={"contain"} width="100%" height="90vh" src={`${process.env.REACT_APP_CMS}${image.imageUrl}`} alt={`Image ${index}`} />
                        </ModalContent>
                    ))}
                </SliderShowVirtualTour>
            </ModalGallery>
        </VirtualTourDisplay>
    );
};

GallerySection.propTypes = {
    onHeightChange: PropTypes.func,
    selectedLanguage: PropTypes.string,
    hasMatterport: PropTypes.bool,
    datas: PropTypes.object
};

export default GallerySection;
