{
    "SEIZE THE SPACE EMPOWER YOUR VISION": "SEIZE THE SPACE,<br />EMPOWER<br />YOUR VISION",
    "OFFICE SPACE FEATURES": "OFFICE SPACE FEATURES",
    "2.7m of Clearance Ceiling Height Allows Better Air Circulation": "2.7m of Clearance <br />Ceiling Height Allows <br />Better Air Circulation",
    "Column Free Layout Maximizes Space use Efficiency": "Column Free<br />Layout Maximizes<br />Space Use Efficiency",
    "Air Conditioning System with Air Quality Control": " Air Conditioning<br />System with<br />Air Quality Control",
    "LED Panel Lighting on Finished Concealed Ceiling": "LED Panel Lighting<br />on Finished <br /> Concealed Ceiling",
    "100m Raised Floor System Eases Out Renovation/ Alteration": "100mm Raised Floor<br />System Eases Out<br />Renovation/ Alteration",
    "100% Power Backup for Seamless Operation": "100% Power<br />Backup for<br />Seamless Operation",
    "TEST FIT": "TEST FIT",
    "SPECS SHEET": "SPECS SHEET",
    "CONTACT US": "CONTACT US",
    "RETAIL SPACE": "RETAIL SPACE"
}
