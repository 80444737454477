import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendMailEvent } from "../../../api/contact";
import { ContactSpan, ContactDivScreen, ContactDisplay, ContactDivContent, ContactInfoDisplay, ContactIcon, ContactParagraphs, ContactImage, FormGroup, InputControl, TextAreaControl, ContactSendButton, ContactJustifyContent, SelectionControl, Option } from "../../../style/ContactScreen";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const AddressMobile = ({ datas }) => {
    const openNewTabPolicy = () => {
        const currentURL = window.location.href;
        const newURL = currentURL.replace("/contact", "/privacyPolicy");
        const newTab = window.open(newURL, "_blank");
        newTab.focus();
    };
    const itemsSalution = [
        { label: "Mr.", value: "Mr." },
        { label: "Ms.", value: "Ms." }
    ];
    const [fullname, setFullName] = useState("");
    const [salution, setSalution] = useState(itemsSalution[0].value);
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [message, setMessage] = useState("");
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [isSentStatus, setIsSentStatus] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const handleSelectChange = (e) => {
        setSalution(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "fullname") {
            setFullName(value);
        } else if (name === "email") {
            setEmail(value);
        } else if (name === "phoneNo") {
            setPhoneNo(value);
        } else if (name === "message") {
            setMessage(value);
        }
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (!isSentStatus) setIsButtonDisable(!e.target.checked);
    };

    const sendMail = async () => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const phonePattern = /^\d{10}$/;
        if (!isChecked) return toast.error("Please check you have agreed to our privacy policy!");
        if (fullname == "" || fullname == null) return toast.error("Invalid Name.");
        if (email == "" || email == null) {
            return toast.error("Email is required.");
        } else if (!emailPattern.test(email)) {
            return toast.error("Invalid Email.");
        }
        if (message == "" || message == null) return toast.error("Your message is required.");
        if (phoneNo !== "" && !phonePattern.test(phoneNo)) return toast.error("Invalid Phone No.");

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute("6LdPCg0pAAAAAPi6j8Kr_XDoa8R1f1lJtNGjTEWx", { action: "submit" }).then(async () => {
                if (!isButtonDisable) {
                    if (fullname && salution && email && message) {
                        try {
                            ReactGA.event({
                                category: "Email",
                                action: "Send",
                                label: "Contact form send mail"
                            });

                            toast.info("Sending email!");
                            setIsSentStatus(true);
                            setIsButtonDisable(true);
                            const messageSendEmail = await sendMailEvent(fullname, salution, email, phoneNo, message);
                            if (messageSendEmail.success) {
                                toast.success(messageSendEmail.message);
                                toast.info("Please wait for 2 minutes to proceed with sending the email.");
                                setTimeout(
                                    () => {
                                        toast.info("You can now send email.");
                                        setIsSentStatus(false);
                                        setIsButtonDisable(false);
                                    },
                                    2 * 60 * 1000
                                );
                            } else {
                                toast.error(messageSendEmail.message);
                                setIsButtonDisable(false);
                            }
                        } catch (error) {
                            toast.error(error.message);
                            setIsButtonDisable(false);
                        }
                    }
                }
            });
        });
    };

    useEffect(() => {
        if (isChecked) {
            if (!isSentStatus) setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
        }
    }, [isChecked, isSentStatus]);

    return (
        <ContactDivScreen>
            <ContactDisplay>
                <ContactDivContent padding={"30px 20px 0px 20px"}>
                    <ContactInfoDisplay justify={"center"}>
                        <ContactIcon src="./img/email-send.png" />
                        <ContactParagraphs languagecode={currentLanguageCode} font={"Barlow, Helvetica"} tablet={"47px"} padding={"0px 5px"} fweight={"700"} fsize={"17px"} linehg={"24px"}>
                            {t("SEND A MESSAGE")}
                        </ContactParagraphs>
                    </ContactInfoDisplay>
                    <ContactInfoDisplay>
                        <ContactInfoDisplay width={"100%"} direction={"row"}>
                            <ContactDivContent width={"72%"}>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"11px"} linehg={"14px"}>
                                    {t("NAME")} *
                                </ContactParagraphs>
                                <FormGroup padding={"0px 6px 0px 0px"}>
                                    <InputControl type="text" name="fullname" value={fullname} onChange={handleInputChange} />
                                </FormGroup>
                            </ContactDivContent>
                            <ContactDivContent width={"28%"}>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"11px"} linehg={"14px"}>
                                    {t("SALUTATION")}
                                </ContactParagraphs>
                                <FormGroup>
                                    <SelectionControl value={salution || itemsSalution[0].value} onChange={handleSelectChange}>
                                        {itemsSalution.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </SelectionControl>
                                </FormGroup>
                            </ContactDivContent>
                        </ContactInfoDisplay>
                    </ContactInfoDisplay>
                    <ContactDivContent>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"11px"} linehg={"14px"}>
                            {t("EMAIL")} *
                        </ContactParagraphs>
                        <FormGroup>
                            <InputControl type="text" name="email" value={email} onChange={handleInputChange} />
                        </FormGroup>
                    </ContactDivContent>
                    <ContactDivContent>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"11px"} linehg={"14px"}>
                            {t("PHONE NO.")}
                        </ContactParagraphs>
                        <FormGroup>
                            <InputControl type="text" name="phoneNo" value={phoneNo} onChange={handleInputChange} />
                        </FormGroup>
                    </ContactDivContent>
                    <ContactDivContent>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"11px"} linehg={"14px"}>
                            {t("YOUR MESSAGE")} *
                        </ContactParagraphs>
                        <FormGroup height={"260px"}>
                            <TextAreaControl type="text" name="message" value={message} onChange={handleInputChange} />
                        </FormGroup>
                    </ContactDivContent>
                    <ContactInfoDisplay alignitems={"flex-start"} height={"auto"} margin={"10px 0px 0px 0px"}>
                        <ContactDivContent height={"100%"}>
                            <InputControl width={"20px"} height={"20px"} type={"checkbox"} checked={isChecked} onChange={handleCheckboxChange} />
                        </ContactDivContent>
                        <ContactDivContent width={"92%"} margin={"0 0 0 8px"}>
                            <ContactParagraphs align={"left"} padding={"1px 0 0 0"}>
                                {t("POLICY CONTENT")}
                                <ContactSpan color={"#2C53AF"} underline={"underline"} cursor={"pointer"} onClick={openNewTabPolicy}>
                                    {t("PRIVACY POLICY")}
                                </ContactSpan>
                                {currentLanguageCode !== "en" && <ContactSpan>{t("PRIVACY POLICY CUSTOM")}</ContactSpan>}
                            </ContactParagraphs>
                        </ContactDivContent>
                    </ContactInfoDisplay>
                    <ContactJustifyContent padding={"20px 0px 0px 0px"}>
                        <ContactSendButton onClick={sendMail} disabled={isButtonDisable}>
                            <ContactParagraphs languagecode={currentLanguageCode} font={"Barlow, Helvetica"} align={"center"} padding={"8px 0px 0px 0px"} color={isButtonDisable ? "#353535" : "#F4F4F4"} fweight={"600"} fsize={"18px"} linehg={"21px"}>
                                {t("SEND")}
                            </ContactParagraphs>
                        </ContactSendButton>
                    </ContactJustifyContent>
                </ContactDivContent>
                <ContactDivContent padding={"40px 30px 0px 20px"}>
                    <ContactDivContent padding={"0px 0px 20px 0px"}>
                        <ContactInfoDisplay>
                            <ContactIcon tabheight={"30px"} tabwidth={"30px"} width={"25px"} height={"25px"} src="./img/receiver.png" />
                            <ContactParagraphs tabletpad={"0px 5px"} languagecode={currentLanguageCode} font={"Barlow, Helvetica"} tablet={"27px"} fweight={"600"} fsize={"17px"} linehg={"24px"}>
                                {t("HOTLINE")}
                            </ContactParagraphs>
                        </ContactInfoDisplay>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"0px 30px"} fweight={"600"} fsize={"13px"} linehg={"21px"}>
                            0888.247.289
                        </ContactParagraphs>
                    </ContactDivContent>
                    <ContactDivContent padding={"0px 0px 20px 0px"}>
                        <ContactInfoDisplay>
                            <ContactIcon tabheight={"30px"} tabwidth={"30px"} width={"25px"} height={"25px"} src="./img/email.png" />
                            <ContactParagraphs tabletpad={"0px 5px"} languagecode={currentLanguageCode} font={"Barlow, Helvetica"} padding={"0px 5px"} fweight={"600"} fsize={"17px"} linehg={"24px"}>
                                {t("INQUIRY")}
                            </ContactParagraphs>
                        </ContactInfoDisplay>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"0px 30px"} fweight={"600"} fsize={"13px"} linehg={"21px"}>
                            inquiry@taiseidh.vn
                        </ContactParagraphs>
                    </ContactDivContent>
                    <ContactDivContent padding={"0px 0px 20px 0px"}>
                        <ContactInfoDisplay>
                            <ContactIcon tabheight={"30px"} tabwidth={"30px"} width={"25px"} height={"25px"} src="./img/location.png" />
                            <ContactParagraphs tabletpad={"0px 5px"} languagecode={currentLanguageCode} font={"Barlow, Helvetica"} padding={"0px 5px"} fweight={"600"} fsize={"17px"} linehg={"24px"}>
                                {t("ADDRESS")}
                            </ContactParagraphs>
                        </ContactInfoDisplay>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"0px 30px"} fweight={"600"} fsize={"13px"} linehg={"21px"}>
                            <p>289 Khuat Duy Tien</p>
                            <p>Trung Hoa, Cau Giay</p>
                            <p>Hanoi</p>
                        </ContactParagraphs>
                    </ContactDivContent>
                    <ContactDivContent padding={"0px 0px 20px 0px"}>
                        <ContactInfoDisplay>
                            <ContactIcon tabheight={"30px"} tabwidth={"30px"} width={"25px"} height={"25px"} src="./img/location-1.png" />
                            <ContactParagraphs tabletpad={"0px 5px"} languagecode={currentLanguageCode} font={"Barlow, Helvetica"} padding={"0px 5px"} fweight={"600"} fsize={"17px"} linehg={"24px"} align={"left"}>
                                {t("UPCOMING LEASING EVENT CONTACT")}
                            </ContactParagraphs>
                        </ContactInfoDisplay>
                        <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"0px 30px"} fweight={"600"} fsize={"13px"} linehg={"21px"}>
                            {datas ? datas.title : `[Name of Event]`}
                        </ContactParagraphs>
                    </ContactDivContent>
                </ContactDivContent>
                <ContactDivContent padding={"20px 20px"}>
                    <ContactImage src={datas ? `${process.env.REACT_APP_CMS}${datas.imageUrl}` : "./img/6-hero.png"} />
                </ContactDivContent>
            </ContactDisplay>
            <ContactDivContent>
                <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.7546690087215!2d105.79377679999999!3d21.0024691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad460ef012e1%3A0x73874de77f3e7407!2sTaisei%20Square%20Hanoi!5e0!3m2!1sen!2sus!4v1698807410826!5m2!1sen!2sus" width={"100%"} height={"569px"} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </ContactDivContent>
        </ContactDivScreen>
    );
};

AddressMobile.propTypes = {
    datas: PropTypes.object,
    onHeightChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    sendMail: PropTypes.func,
    handleSelectChange: PropTypes.func,
    itemsSalution: PropTypes.array,
    messageSendEmail: PropTypes.object
};

export default AddressMobile;
