import React, { useEffect } from "react";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { ContactDisplay, HeroLanding, ContactDivContent, ContactParagraphsHero, ContactButtonContact } from "../../../style/ContactScreen";
import { useTranslation } from "react-i18next";

const HeroMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <HeroLanding id="hero" bgurl={"7-hero.png"}>
            <ContactDisplay>
                <ContactDivContent padding={"20px 30px 10px 30px"}>
                    <ContactParagraphsHero languagecode={currentLanguageCode} color={"#f4f4f4"} fsize={"43px"} linehg={"57px"} fweight={"600"} shadow={"0px 3px 6px #353535"} wspace={""}>
                        {t("REACH OUT TO US")}
                    </ContactParagraphsHero>
                </ContactDivContent>
                <ContactButtonContact ptop={"10px"}>
                    <Link to="/overview">
                        <ContactUsButtonComponent text={"VISIT US"} color={"#F4F4F4"} />
                    </Link>
                </ContactButtonContact>
            </ContactDisplay>
        </HeroLanding>
    );
};

export default HeroMobile;
