import { Modal } from "antd";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { Carousel as CarouselSlide } from "antd";

export const FadeInOut = styled.div`
    animation: fadeIn 2.3s;
`;

export const HeroLanding = styled.div`
    background-image: url(./img/${(props) => props.bgurl});
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1366px) and (min-width: 1281px) {
        height: 768px;
    }

    @media (max-width: 1280px) {
        height: 100vh;
    }

    @media screen and (min-width: 1367px) {
        height: 100vh;
    }
`;

export const LeasingSectionBackground = styled.div`
    width: 100vw;
    background-color: ${(props) => props.bgcolor ?? "#f4f4f4"};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LeasingSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LeasingDisplay = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    align-items: ${(props) => props.align ?? ""};
    justify-content: ${(props) => props.justify ?? ""};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
`;

export const LeasingGrid = styled.div`
    display: grid;
    grid-template-columns: 49% 51%;
    row-gap: 20px;
    padding: ${(props) => props.padding ?? ""};
    @media (min-width: 768px) and (max-width: 1279px) {
        display: flex;
    }
`;

export const LeasingContent = styled.div`
    height: ${(props) => props.height ?? "auto"};
    width: ${(props) => props.width ?? "100%"};
    display: flex;
    flex-direction: ${(props) => props.direction ?? "row"};
    align-items: ${(props) => props.alignitems ?? ""};
    justify-content: ${(props) => props.justify ?? ""};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
    position: ${(props) => props.position ?? ""};
    border: ${(props) => props.border ?? ""};
    gap: ${(props) => props.gap ?? "13px"};
    @media (min-width: 768px) and (max-width: 1279px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`;

export const LeasingJustifyContent = styled.div`
    height: ${(props) => props.height ?? "auto"};
    width: ${(props) => props.width ?? "100%"};
    display: flex;
    justify-content: center;
    padding: ${(props) => props.padding ?? ""};
    flex-direction: ${(props) => props.direction};
    flex-wrap: wrap;
    gap: 60px;

    @media (min-width: 768px) and (max-width: 1279px) {
        padding: ${(props) => props.tablet};
    }
`;

export const LeasingDivElement = styled.div`
    border: ${(props) => props.border ?? ""};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height};
    text-align: ${(props) => props.align ?? ""};
    z-index: ${(props) => props.zindex ?? ""};
    position: ${(props) => props.position ?? ""};
`;

export const LeasingParagraphsHero = styled.div`
    -webkit-text-stroke: ${(props) => props.stroke ?? "2px #1b2e59"};
    color: #cbe7f2;
    letter-spacing: 0;
    text-align: center;
    font-family: "Barlow", Helvetica;
    font-size: ${(props) => props.fsize ?? "70px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "70px"};
    text-shadow: ${(props) => props.shadow ?? "0px 3px 8px #353535"};
    white-space: nowrap;
    padding: ${(props) => props.padding ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 70px;
        line-height: 79px;
    }
    @media (max-width: 360px) {
        font-size: 39px;
    }
`;

export const LeasingParagraphs = styled.div`
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: ${(props) => props.align ?? "center"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? "16px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "16px"};
    text-shadow: ${(props) => props.shadow ?? ""};
    padding: ${(props) => props.padding ?? ""};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

export const LeasingWrapper = styled.div`
    height: ${(props) => props.height ?? ""};
    width: ${(props) => props.width ?? "1366px"};
    z-index: ${(props) => props.zindex ?? "9999"};
`;

export const ElementLeasing = styled.div`
    height: ${(props) => props.height};
    left: ${(props) => props.left ?? "0px"};
    position: ${(props) => props.position ?? "absolute"};
    top: ${(props) => props.top ?? "0px"};
    width: ${(props) => props.width ?? "1366px"};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
    border: ${(props) => props.border ?? ""};
`;

export const ContentLeasing = styled.div`
    height: ${(props) => props.height};
    left: ${(props) => props.left ?? "0px"};
    position: ${(props) => props.position ?? "absolute"};
    top: ${(props) => props.top ?? "0px"};
    width: ${(props) => props.width ?? "1366px"};
    ${ElementLeasing}:hover & {
        cursor: pointer;
        background-color: ${(props) => props.hvcolor ?? "#cbe7f2"};
        opacity: 0.5;
    }
`;

export const DivWrapperLeasing = styled.div`
    color: ${(props) => props.color ?? "#353535"};

    font-family: "Segoe UI", Helvetica;
    font-size: ${(props) => props.size};
    font-weight: ${(props) => props.weight ?? "700"};
    left: ${(props) => props.left ?? "0px"};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? props.size};
    position: absolute;
    top: ${(props) => props.top};
    text-align: ${(props) => props.align ?? ""};
    white-space: nowrap;
    width: ${(props) => props.width};
    ${ElementLeasing}:hover & {
        cursor: pointer;
        color: ${(props) => props.hvcolor ?? "#cbe7f2"};
    }
`;

export const LeasingGroup = styled.div`
    height: ${(props) => props.height ?? ""};
    width: ${(props) => props.width ?? ""};
    margin: auto;
`;

export const LeasingImage = styled.img`
    height: ${(props) => props.height ?? ""};
    object-fit: ${(props) => props.fit ?? "cover"};
    width: ${(props) => props.width ?? ""};
    margin-top: ${(props) => props.margintop};
`;

export const LeasingButton = styled.div`
    box-shadow: 0px 1px 2px #353535;
    border: 3px solid #323b4a;
    width: ${(props) => props.width ?? "200px"};
    height: 40px;
`;

export const LeasingButtonWrapper = styled.div`
    border: 3px solid #323b4a;
    width: ${(props) => props.width ?? "100%"};
    height: 40px;
    border-bottom: ${(props) => (props.openbutton === "true" ? "none" : "3px solid")};
    box-shadow: ${(props) => (props.openbutton === "true" ? "none" : "0px 1px 2px #353535")};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => (props.openbutton === "true" ? "none" : "#cbe7f2")};
    }
`;

export const LeasingSpanWrapper = styled.span`
    font-family: inherit;
    font-weight: ${(props) => props.fweight ?? "600"};
    text-align: ${(props) => props.align};
    font-size: ${(props) => props.fsize};
`;

export const LeasingLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    border: none;
`;

export const LeasingFloorPlan = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    //margin: 0 0 0 60px;
`;

export const LeasingEveryFloor = styled.div`
    color: ${(props) => (props.ishovered ? props.hvcolor : props.color ?? "#333333")};
    font-family: "Segoe UI", Helvetica;
    padding: 3.5px 0 0 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 0;
    white-space: nowrap;
    text-align: right;
    margin: 0px 0px 0px 0px;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

export const LeasingHoverFloor = styled.div`
    margin: 0 0 0 65px;
    background-color: ${(props) => (props.ishovered ? props.hvcolor : "")};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    opacity: 0.5;
`;

export const LeasingModalImage = styled.img`
    width: ${(props) => props.width ?? "100%"};
    height: ${(props) => props.height ?? "700px"};
    object-fit: ${(props) => props.objectfit};
    padding: ${(props) => props.padding};
`;

export const LeasingIcon = styled.img`
    height: ${(props) => props.height};
    left: ${(props) => props.left};
    object-fit: ${(props) => props.fit ?? "cover"};
    top: ${(props) => props.top};
    width: ${(props) => props.width};
    align-self: ${(props) => props.self};
    position: ${(props) => props.position};
    right: ${(props) => props.right};
    bottom: ${(props) => props.bottom};
    z-index: ${(props) => props.zindex};
    transform: ${(props) => props.transform};
    border: ${(props) => props.border};
    margin: ${(props) => props.margin};
    transition: transform 0.3s;

    @media (min-width: 768px) and (max-width: 1279px) {
        width: ${(props) => props.wtablet ?? "100%"};
        height: ${(props) => props.htablet ?? "300px"};
    }
`;

export const SliderShowLeasing = styled(CarouselSlide)`
    position: relative;
    .slick-dots li.slick-active button {
        background: #154dac;

        width: 50px;
    }
    .slick-dots li button {
        background: #444444;
        width: 50px;
    }
    .slick-dots li {
        width: 50px;
    }
    .slick-dots li.slick-active {
        width: 50px;
    }
    .slick-dots {
        margin: 25px 0 0 0 !important;
        position: relative !important;
    }
`;

export const ModalContent = styled.div`
    position: relative;
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    display: flex;
`;

export const CloseButtonWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    text-align: center;
    background-color: #353535;
    box-shadow: 0px 2px 4px #353535;
    margin: 0 0 20px 0;
    cursor: pointer;
`;

export const ButtonCloseSlider = styled.div`
    padding: 3px 0 0 0;
    width: 130px;
    height: 40px;
    box-shadow: 0px 2px 4px #353535;
    background-color: #858585;
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    font-family: "Barlow, Helvetica";
    letter-spacing: 0px;
    color: #f4f4f4;
    opacity: 1;

    @media (max-width: 360px) {
        font-size: 21px;
    }
`;

export const CloseButtonMobile = styled.div`
    position: relative;
    z-index: 9999;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0 0 10px 0;
    cursor: pointer;
`;

export const DropdownButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
export const DropdownList = styled.ul`
    left: 0;
    background-color: white;
    border: 3px solid #323b4a;
    list-style: none;
    padding: 0;
    margin: 0;
    display: ${(props) => (props.opendd === "true" ? "block" : "none")};
    padding: 3px 30px 0 30px;
    border-top: none;
    animation: ${(props) => (props.opendd === "true" ? fadeIn : fadeOut)} 0.3s ease-in-out;
    transition: display 0.3s ease-in-out;
    position: absolute;
    width: ${(props) => props.width};
    z-index: 9999999;
`;

export const DropdownItem = styled.li`
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    border-top: solid 3px #b4b4b4;
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : "'Segoe UI', Helvetica")};
    font-size: 18px;
    font-weight: 700;
    color: #353535;
    &:hover {
        color: #2c53af;
    }
`;

export const LinkItem = styled.a`
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : "'Segoe UI', Helvetica")};
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    color: #353535;
    &:hover {
        color: #2c53af;
    }
`;

export const ImageWrapper = styled.div`
    width: ${(props) => props.width ?? "293px"};
    height: ${(props) => props.height ?? "193px"};
    background-size: cover;
    display: flex;
    justify-content: ${(props) => props.justify};
    align-items: ${(props) => props.items};
    background-image: url("./img/${(props) => props.bgurl}");
    background-color: ${(props) => props.bgcolor};
    @media (max-width: 360px) {
        margin: 0;
        justify-content: center;
    }
`;
export const CustomIcon = styled.div`
    display: ${(props) => props.display ?? "flex"};
    justify-content: ${(props) => props.justify ?? "center"};
    align-items: ${(props) => props.items ?? "center"};
    width: ${(props) => props.width ?? "30px"};
    height: ${(props) => props.height ?? "30px"};
    background-color: ${(props) => props.bgcolor ?? "black"};
    border-radius: ${(props) => props.bdradius};
    text-align: center;
    border: ${(props) => props.border};
    color: ${(props) => props.color};
    font-weight: ${(props) => props.fweight};
`;
export const ModalPopUpLeasing = styled(Modal)`
    .ant-modal-footer {
        display: flex;
        justify-content: center;
        margin: 10px 0 31px 0;
        padding-bottom: 31px;
    }
    width: 900px !important;
`;
export const ButtonCloseModal = styled.div`
    position: absolute;
    margin-bottom: 25px;
    margin-top: 10px;
    padding: 3px 0 0 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 40px;
    font-weight: 600;
    box-shadow: 0px 2px 4px #353535;
    background-color: #858585;
    text-align: center;
    font-size: 21px;
    font-family: "Barlow";
    letter-spacing: 0px;
    color: #f4f4f4;
    opacity: 1;
    border: none;
    cursor: pointer;
`;
