import axios from "./customize-axios";

const fetchMediasByType = (type, languageCode) => {
    return axios.get(`/api/media/getMediasByType?type=${type}&languageCode=${languageCode}`);
};
const fetchMediaById = (id) => {
    return axios.get(`/api/media/getMediaContentById?id=${id}`);
};

export { fetchMediasByType, fetchMediaById };
