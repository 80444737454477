import React, { useState, useEffect } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import * as MobileSections from "./Mobile.Sections";
import { useWindowWidth } from "../../breakpoints";
import { getUpComingLeasingEvent } from "../../api/contact";
import { useLanguage } from "../../components/LanguageContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);
    const [leasingEvent, setLeasingEvent] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { selectedLang } = useLanguage();
    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };

    const getLeasingEvent = async () => {
        const upcomingLeasing = await getUpComingLeasingEvent(selectedLang);
        if (upcomingLeasing) {
            setLeasingEvent(upcomingLeasing);
            setDataLoaded(true);
        }
    };

    useEffect(() => {
        if (selectedLang || !dataLoaded) {
            getLeasingEvent();
        }
    }, [selectedLang, dataLoaded]);

    useEffect(() => {
        const loadReCaptchaScript = () => {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=6LdPCg0pAAAAAPi6j8Kr_XDoa8R1f1lJtNGjTEWx";
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        };

        loadReCaptchaScript();
    }, []);

    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight}px`}>
                    <Sections.HeroSection onHeightChange={handleHeightChange} />
                    <Sections.AddressSection onHeightChange={handleHeightChange} datas={leasingEvent} />
                </DesktopLayout>
            ) : (
                <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                    <MobileSections.HeroMobile />
                    <MobileSections.AddressMobile datas={leasingEvent} />
                </DesktopLayout>
            )}
            <ToastContainer position="top-right" autoClose={5000} theme="light" style={{ marginTop: "100px" }} />
        </>
    );
};

export default ContactScreen;
