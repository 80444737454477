import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HeroLanding, HeroWrapperMobile, HeroWrapper, HeroTitleMobile, HeroTagMobile, OverviewButtonDisplay, ContactButtonComponent } from "../../../style/OverviewScreen";
import { useTranslation } from "react-i18next";

const HeroMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
                const sectionFt = document.getElementById(sectionId);
                if (sectionFt) {
                    const yOffset = sectionFt.getBoundingClientRect().top;
                    window.scrollTo({ top: yOffset, behavior: "smooth" });
                }
            }, 500);
        }
    };
    return (
        <HeroLanding id="hero" position="relative" bgurl="1building_hero.png">
            <HeroWrapper>
                <HeroWrapperMobile>
                    <HeroTitleMobile>TAISEI SQUARE HANOI</HeroTitleMobile>
                    <HeroTagMobile>{t("A Japanese dedication to the first LEED certified Grade A office building in West Hanoi ")}</HeroTagMobile>
                </HeroWrapperMobile>
                <OverviewButtonDisplay bottom={"13%"}>
                    <Link to="/leasing#office" onClick={() => scrollToSection("office")}>
                        <ContactButtonComponent>{t("EXPLORE")}</ContactButtonComponent>
                    </Link>
                </OverviewButtonDisplay>
            </HeroWrapper>
        </HeroLanding>
    );
};

export default HeroMobile;
