import React from "react";
import PropTypes from "prop-types";

class ImageBlocker extends React.Component {
    componentDidMount() {
        window.addEventListener("dragstart", this.handleDragStart);
        window.addEventListener("copy", this.handleCopy);
        window.addEventListener("contextmenu", this.handleContextMenu);
    }

    componentWillUnmount() {
        window.removeEventListener("dragstart", this.handleDragStart);
        window.removeEventListener("copy", this.handleCopy);
        window.removeEventListener("contextmenu", this.handleContextMenu);
    }

    handleDragStart = (e) => {
        e.preventDefault();
    };

    handleCopy = (e) => {
        e.preventDefault();
    };

    handleContextMenu = (e) => {
        e.preventDefault();
    };

    render() {
        return <div>{this.props.children}</div>;
    }
}

ImageBlocker.propTypes = {
    children: PropTypes.object
};

export default ImageBlocker;
