import axios from "./customize-axios";

const getUpComingLeasingEvent = (languageCode) => {
    return axios.get(`/api/contact/getUpComingLeasingEvent?languageCode=${languageCode}`);
};
const sendMailEvent = (fullname, salution, email, phoneNo, message) => {
    return axios.post(`/api/contact/sendMail?fullname=${fullname}&salution=${salution}&email=${email}&phoneNo=${phoneNo}&message=${message}`);
};

const getSocialNetworkContact = () => {
    return axios.get(`/api/Contact/getSocialNetworkContact`);
};
export { getUpComingLeasingEvent, sendMailEvent, getSocialNetworkContact };
