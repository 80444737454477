{
    "A JAPANESE DEDICATION TO WORKPLACE AND WELLBEING OF THE PROFESSIONALS": "A JAPANESE DEDICATION TO WORKPLACE AND WELLBEING OF THE PROFESSIONALS",
    "The first LEED certified Grade A office building in West Hanoi": "ハノイ西部エリア初の LEED 認証・Ａグレードオフィスビル",
    "OVERVIEW": "概要",
    "OVERVIEW DESCRIPTION": "Taisei Square Hanoi は、ハノイ西部・Cau Giay（コウザイ）区においてオフィスの新しいスタンダードを打ち立てます。大成建設が投資・開発を進めるプロジェクトであり、お客様にジャパンクオリティのオフィス環境とプロフェッショナルなサービスをお約束します。",
    "18floors": "オフィス <br /> 18 フロア",
    "Floorplate": "基準階面積 <br /> 1,300 ㎡",
    "2floors": "店舗 <br /> 2 フロア",
    "4basement": "地下駐車場 <br /> 4 フロア",
    "PROJECT CONCEPT": "プロジェクトコンセプト",
    "PROJECT CONCEPT DESCRIPTION": "本プロジェクトはジャパンクオリティの快適性を有し、且つ、環境にも配慮したワークプレイスの提供を目指すもので、投資、設計・施工、運営に大成建設グループが一丸となって取組んでおります。またビルの運営管理についても、オぺレーションチームがベトナムでの豊富な経験を有する日系パートナー企業と協力し、日本品質のサービスをお届けします。",
    "BUILDING DESIGN CONCEPT": "デザインコンセプト",
    "BUILDING DESIGN CONCEPT DESCRIPTION": "水平・垂直の線で構成された端正で抑制の効いた日本建築のデザインをモチーフとし、モノトーンのフォーマルな雰囲気の中に、木調や石目調の素材を組み合わせることで、落ち着きと温かみのあるオフィス空間としています。",
    "LOGO CONCEPT": "ロゴデザインコンセプト",
    "LOGO CONCEPT DESCRIPTION": "「SQUARE」はシンプルデザインな建物形状（四角）と共に、Hanoi 西部エリアのハブ機能（広場）を表します。また赤いドットは日本を、T の字はアナログ時代の建築設計必須ツールであったT定規を表します。ものづくり精神を大切にしながら、日本品質のオフィス環境を創出する想いを込めました。",
    "LOCATION": "ロケーション",
    "LOCATION DESCRIPTION": "Taisei Square Hanoi は環状 3 号線に面しており、ノイバイ国際空港や主要工業団地、ハノイ市内の他の商業・ビジネスエリアへのアクセスも抜群の好立地です。",
    "30 MINS": "30 分",
    "NOI BAI INTERNATIONAL AIRPORT": "ノイバイ国際空港 <br /> まで",
    "HOAN KIEM DISTRICT": "Hoan Kiem 区 <br /> まで",
    "15 MINS": "15 分",
    "DONG DA AND BA DINH DISTRICT": "Dong Da 区・Ba Dinh 区 <br /> まで",
    "3 MINS": "3 分",
    "LANG HOA LAC HIGHWAY": "Lang-Hoa Lac 高速道路 <br /> まで",
    "CONTACT US": "お問い合わせ",
    "EXPLORE": "EXPLORE"
}
