export const floor16to20 = [
    {
        id: 1,
        name: "20F",
        height: "40px"
    },
    {
        id: 2,
        name: "19F",
        height: "40px"
    },
    {
        id: 3,
        name: "18F",
        height: "37px"
    },
    {
        id: 4,
        name: "17F",
        height: "37px"
    },
    {
        id: 5,
        name: "16F",
        height: "37px"
    }
];
