import React, { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { TextAnimation, DescriptionAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { ConceptSectionWrapper, ConceptTitle, ConceptDescription, ConceptWrapper, OverviewDisplay, OverviewParagraphs, OverviewDisplayJustify, OverviewImage, OverviewCarousel } from "../../../style/OverviewScreen";
import PropTypes from "prop-types";
import parse from "html-react-parser";

import { useTranslation } from "react-i18next";

const ConceptSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [inViewRef, inView] = useInView();
    const descriptionSpring = TextAnimation(inView);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const [logoDesignRef, logoDesignView] = useInView();
    const descriptionLogoSpring = DescriptionAnimation(logoDesignView);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);
    let logoConceptImages = [
        {
            id: 1,
            path: "./img/tsquare-hanoi-logo.png"
        },
        {
            id: 2,
            path: "./img/tsquare-tool.png"
        },
        {
            id: 3,
            path: "./img/japan-flag.png"
        }
    ];
    const renderlogoConceptImages = () => {
        return logoConceptImages.map((img, index) => <OverviewImage key={index} width={"300px"} height={"500px"} src={img.path} />);
    };
    return (
        <ConceptSectionWrapper id="concept" height={currentLanguageCode === "vi" ? "1495px" : "1465px"} ref={sectionRef}>
            <ConceptWrapper ref={inViewRef}>
                <ConceptTitle languagecode={currentLanguageCode} as={animated.div} style={descriptionSpring}>
                    {t("PROJECT CONCEPT")}
                </ConceptTitle>
                <ConceptDescription languagecode={currentLanguageCode} as={animated.div} style={descriptionSpring} align={"center"} height={"105px"} width={"1000px"}>
                    {parse(t("PROJECT CONCEPT DESCRIPTION"))}
                </ConceptDescription>

                {/* building design concept */}
                <OverviewDisplay direction={"row"} margin={currentLanguageCode === "vi" ? "90px 0 60px 82px" : "60px 0 60px 82px"}>
                    <OverviewDisplayJustify display={"block"} direction={"column"} width={"40%"} justify={"center"} align={"flex-start"}>
                        <OverviewParagraphs languagecode={currentLanguageCode} font={"Barlow, Helvetica"} fsize={"32px"} linehg={"32px"}>
                            <animated.div style={descriptionSpring}>{t("BUILDING DESIGN CONCEPT")}</animated.div>
                        </OverviewParagraphs>

                        <OverviewParagraphs languagecode={currentLanguageCode} padding={"30px 35px 0 0 "} align={"left"} fweight={"400"} fsize={"16px"} linehg={"24px"}>
                            <animated.div style={descriptionSpring}>
                                {t("BUILDING DESIGN CONCEPT DESCRIPTION")}
                                <OverviewDisplayJustify padding={"30px 0px"}>
                                    <Link to="/contact">
                                        <ContactUsButtonComponent text={t("CONTACT US")} />
                                    </Link>
                                </OverviewDisplayJustify>
                            </animated.div>
                        </OverviewParagraphs>
                    </OverviewDisplayJustify>
                    <OverviewDisplayJustify width={"60%"}>
                        <OverviewImage height={"500px"} width={"779px"} cursor={"pointer"} border={"1px solid #353535"} src="./img/building-concept.png" />
                    </OverviewDisplayJustify>
                </OverviewDisplay>

                {/* logo concept */}
                <OverviewDisplay direction={"row"} margin={"0 82px 60px 0"} ref={logoDesignRef}>
                    <OverviewDisplayJustify width={"60%"}>
                        <OverviewCarousel width={"750px"} autoplay autoplaySpeed={2000}>
                            {renderlogoConceptImages()}
                        </OverviewCarousel>
                    </OverviewDisplayJustify>
                    <OverviewDisplayJustify width={"40%"} zindex={"99"} padding={"0 0 0 35px"} direction={"column"} justify={"start"} align={"flex-start"} as={animated.div} style={descriptionLogoSpring}>
                        <OverviewParagraphs languagecode={currentLanguageCode} font={"Barlow, Helvetica"} fsize={"32px"} linehg={"32px"}>
                            {t("LOGO CONCEPT")}
                        </OverviewParagraphs>

                        <OverviewParagraphs languagecode={currentLanguageCode} padding={"30px 0 0 0"} align={"left"} fweight={"400"} fsize={"16px"} linehg={"24px"} as={animated.div} style={descriptionLogoSpring}>
                            {parse(t("LOGO CONCEPT DESCRIPTION"))}
                            <OverviewDisplayJustify padding={"30px 0px"}>
                                <Link to="/contact">
                                    <ContactUsButtonComponent text={t("CONTACT US")} />
                                </Link>
                            </OverviewDisplayJustify>
                        </OverviewParagraphs>
                    </OverviewDisplayJustify>
                </OverviewDisplay>
            </ConceptWrapper>
        </ConceptSectionWrapper>
    );
};

ConceptSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default ConceptSection;
