import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { policies } from "../Data/policies";
import { PolicyDisplay, PolicyContent, PolicyParagraph } from "../../../style/PrivacyPolicy";

const PolicyMobile = ({ languageCode }) => {
    const [content, setContent] = useState("");

    const getContentById = (code) => {
        const policy = policies.find((item) => item.id === code);
        setContent(policy ? policy.content : "");
    };

    useEffect(() => {
        getContentById(languageCode);
    }, [languageCode]);

    return (
        <PolicyDisplay>
            <PolicyContent width={"100vw"} padding={"20px"}>
                <PolicyParagraph align={"left"} fweight={"400"}>
                    {parse(content)}
                </PolicyParagraph>
            </PolicyContent>
        </PolicyDisplay>
    );
};

PolicyMobile.propTypes = {
    languageCode: PropTypes.string
};

export default PolicyMobile;
