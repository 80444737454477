import styled from "styled-components";

export const HeroLanding = styled.div`
    background-image: url(./img/${(props) => props.bgurl});
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1366px) and (min-width: 1281px) {
        height: 768px;
    }

    @media (max-width: 1280px) {
        height: 100vh;
    }

    @media screen and (min-width: 1367px) {
        height: 100vh;
    }
`;

export const ContactDisplay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContactInfoDisplay = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "row"};
    padding: ${(props) => props.padding ?? ""};
    margin: ${(props) => props.margin ?? ""};
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    align-items: ${(props) => props.alignitems ?? ""};
    justify-content: ${(props) => props.justify ?? ""};
`;

export const ContactDisplayPadding = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "row"};
    padding: ${(props) => props.padding ?? ""};
`;

export const ContactJustifyContent = styled.div`
    height: ${(props) => props.height ?? "auto"};
    width: ${(props) => props.width ?? "100%"};
    display: flex;
    justify-content: center;
    padding: ${(props) => props.padding ?? ""};
`;

export const ContactButtonContact = styled.div`
    width: 150px;
    height: 40px;
    margin: 20px 0px;
    padding-top: ${(props) => props.ptop ?? ""};
`;

export const ContactSendButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.color ?? "#353535"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-weight: 600;
    font-size: 18px;
    background-color: ${(props) => (props.disabled ? "#f1f1f2" : "#2c53af")};
    text-align: center;
    height: 40px;
    width: 120px;
    box-shadow: 0px 2px 4px #353535;
    &:hover {
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
        background-color: ${(props) => (props.disabled ? "#f1f1f2" : "#0e2150")};
    }
`;

export const ContactDivContent = styled.div`
    margin: ${(props) => props.margin ?? ""};
    padding: ${(props) => props.padding ?? ""};
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    background-color: ${(props) => props.bgcolor ?? ""};
`;

export const ContactGrid = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.template ?? "auto auto"};
    row-gap: 60px;
    column-gap: 0px;
    text-align: center;
    padding: ${(props) => props.padding ?? ""};
    width: ${(props) => props.width ?? ""};
`;

export const FormGroup = styled.div`
    box-shadow: inset 1px 1px 2px #00000029;
    height: ${(props) => props.height ?? "40px"};
    width: ${(props) => props.width ?? "100%"};
    padding: ${(props) => props.padding ?? ""};
`;

export const InputControl = styled.input`
    background-color: #f4f4f4;
    border: 1px solid;
    border-color: #707070;
    font-family: "Segoe UI", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
    padding: 0 10px;
    height: ${(props) => props.height ?? "40px"};
    width: ${(props) => props.width ?? "100%"};
`;

export const TextAreaControl = styled.textarea`
    background-color: #f4f4f4;
    border: 1px solid;
    border-color: #707070;
    font-family: "Segoe UI", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
    padding: 0 10px;
    height: 100%;
    width: 100%;
`;

export const SelectionControl = styled.select`
    background-color: #f4f4f4;
    color: #353535;
    border: 1px solid;
    border-color: #707070;
    font-family: "Segoe UI", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
    height: 40px;
    width: 100%;
    border-radius: 0;
`;

export const Option = styled.option`
    font-family: "Segoe UI", Helvetica;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
`;

export const ContactImage = styled.img`
    transition: transform 0.3s ease;
    object-fit: cover;
    height: ${(props) => props.height ?? "auto"};
    width: ${(props) => props.width ?? "100%"};
`;

export const ContactIcon = styled.img`
    transition: transform 0.3s ease;
    object-fit: cover;
    height: ${(props) => props.height ?? "30px"};
    width: ${(props) => props.width ?? "30px"};
    @media (min-width: 768px) and (max-width: 1279px) {
        height: ${(props) => props.tabheight ?? "50px"};
        width: ${(props) => props.tabwidth ?? "50px"};
    }
`;

export const ContactDivScreen = styled.div`
    width: 100%;
    position: relative;
    padding: ${(props) => props.padding ?? ""};
`;

export const ContactParagraphsHero = styled.div`
    -webkit-text-stroke: ${(props) => props.stroke};
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: center;
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-size: ${(props) => props.fsize ?? "16px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "16px"};
    text-shadow: ${(props) => props.shadow ?? ""};
    white-space: ${(props) => props.wspace ?? "nowrap"};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 69px;
        line-height: 79px;
    }
`;

export const ContactParagraphs = styled.div`
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: ${(props) => props.align ?? "justify"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'HiraKaku', sans-serif" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? "13px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? 1.15};
    text-shadow: ${(props) => props.shadow ?? ""};
    padding: ${(props) => props.padding ?? ""};
    width: ${(props) => props.width ?? ""};
    margin: ${(props) => props.margin ?? ""};
    &:hover {
        text-decoration: ${(props) => props.underline ?? ""};
        color: ${(props) => props.hvcolor ?? ""};
        cursor: ${(props) => props.cursor ?? ""};
    }

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "18px"};
        line-height: ${(props) => props.tablet ?? "27px"};
        margin: ${(props) => props.tabletmargin};
    }
`;

export const ContactSpan = styled.span`
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: ${(props) => props.align ?? "center"};
    font-family: inherit;
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "inherit"};
    text-shadow: ${(props) => props.shadow ?? ""};
    padding: ${(props) => props.padding ?? ""};
    text-decoration: ${(props) => props.underline ?? ""};
    cursor: ${(props) => props.cursor ?? ""};
`;

export const ContactTag = styled.span`
    color: ${(props) => props.color ?? "#353535"};
    font-weight: ${(props) => props.fweight ?? ""};
`;
