import React, { useState } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import * as MobileSections from "./Mobile.Sections";
import { useWindowWidth } from "../../breakpoints";

const DeveloperScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };
    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight + 140}px`}>
                    <Sections.HeroSection onHeightChange={handleHeightChange} />
                    <Sections.AchievementSection onHeightChange={handleHeightChange} />
                    <Sections.DevelopmentSection onHeightChange={handleHeightChange} />
                    <Sections.PartnersSection onHeightChange={handleHeightChange} />
                </DesktopLayout>
            ) : (
                <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                    <MobileSections.HeroMobile />
                    <MobileSections.AchievementMobile />
                    <MobileSections.DevelopmentMobile />
                    <MobileSections.PartnersMobile />
                </DesktopLayout>
            )}
        </>
    );
};

export default DeveloperScreen;
