import React, { useEffect, useState } from "react";
import { DeveloperSection, DeveloperImage, DeveloperWrapper, HeadLineWrapper, Retangle, ParagraphWrapper, DeveloperCarousel } from "../../../style/DeveloperScreen";
import PropTypes from "prop-types";
import { achivementsInVN } from "../Data/achivementsInVN";
import { achivementsInJP } from "../Data/achivementsInJP";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Achivement = ({ data }) => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const { image, title, date, description } = data;
    return (
        <DeveloperWrapper className="achivement-item" height={"80%"} width={"100%"} direction={"column"} gap={"15px"}>
            <DeveloperImage height={"50vh"} width={"100%"} fit={"cover"} src={image} />
            <DeveloperWrapper align={"flex-end"} margin={"5px 0 0 0"}>
                <Retangle mgbottom={title === "T2 NOI BAI INTL AIRPORT" && (currentLanguageCode === "vi" || currentLanguageCode === "jp") ? "32px" : "2px"} />
                <HeadLineWrapper languagecode={currentLanguageCode} margin={"0 0 0 10px"} fsize={"17px"} linehg={"24px"} fweight={"700"}>
                    {parse(t(title))}
                </HeadLineWrapper>{" "}
            </DeveloperWrapper>
            <ParagraphWrapper languagecode={currentLanguageCode} linehgtablet={"27px"} fsize={"13px"} linehg={"21px"} align={"justify"} fweight={"400"}>
                <HeadLineWrapper languagecode={currentLanguageCode} color={"#2C53AF"} font={"'Segoe UI', Helvetica"} fsize={"13px"} linehg={"21px"} fweight={"700"} padding={"0 5px 0 0"}>
                    {currentLanguageCode === "jp" ? `${date} 年` : date}
                </HeadLineWrapper>{" "}
                {t(description)}
            </ParagraphWrapper>
        </DeveloperWrapper>
    );
};

Achivement.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired
};

let achivementsInVNComponent = [];
achivementsInVN.forEach((achive) => {
    achivementsInVNComponent.push(<Achivement key={achive.id} data={achive} />);
});

let achivementsInJPComponent = [];
achivementsInJP.forEach((achive) => {
    achivementsInJPComponent.push(<Achivement key={achive.id} data={achive} />);
});

const AchievementMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const constructedInVN = ["T2 NOI BAI INTL AIRPORT", "CAN THO BRIDGE"];
    const constructedInJP = ["NATIONAL STADIUM"];
    const [currentAchivement, setCurrentAchivement] = useState(0);
    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <DeveloperSection id="investor">
            <DeveloperWrapper margin={"30px 22px"} direction={"column"} align={"center"} justify={"center"}>
                <HeadLineWrapper languagecode={currentLanguageCode} tablet={"47px"} align={"center"} wspace={""} fsize={"27px"} fweight={"700"} linehg={"27px"}>
                    {t("NOTABLE ACHIEVEMENTS IN VIETNAM")}
                </HeadLineWrapper>
                <DeveloperWrapper justify={"center"} direction={"column"} height={"50px"} align={"flex-start"} margin={"10px 0"}>
                    {" "}
                    <HeadLineWrapper languagecode={currentLanguageCode} wspace={""} fsize={"17px"} linehg={"24px"} fweight={"700"}>
                        {!constructedInVN.includes(currentAchivement) && `| ${t("Developed and")}`}
                    </HeadLineWrapper>{" "}
                    <DeveloperWrapper>
                        <HeadLineWrapper languagecode={currentLanguageCode} wspace={""} fsize={"17px"} linehg={"24px"} fweight={"700"}>
                            | {t("Constructed by Taisei")}
                        </HeadLineWrapper>
                    </DeveloperWrapper>
                </DeveloperWrapper>

                <DeveloperWrapper className="achievement-list-in-vn" width={"100%"} justify={"center"} height={"auto"} margin={"10px 0"}>
                    <DeveloperCarousel dots={true} afterChange={(index) => setCurrentAchivement(achivementsInVN[index].title)}>
                        {achivementsInVNComponent}
                    </DeveloperCarousel>
                </DeveloperWrapper>

                <HeadLineWrapper languagecode={currentLanguageCode} tablet={"47px"} align={"center"} wspace={""} fsize={"30px"} fweight={"700"} linehg={"35px"}>
                    {t("NOTABLE ACHIEVEMENTS IN JAPAN")}
                </HeadLineWrapper>
                <DeveloperWrapper direction={"column"} justify={"center"} align={"flex-start"} height={"40px"} margin={"10px 0"}>
                    {" "}
                    <HeadLineWrapper languagecode={currentLanguageCode} wspace={""} fsize={"17px"} linehg={"24px"} fweight={"700"}>
                        {!constructedInJP.includes(currentAchivement) && `| ${t("Developed and")}`}{" "}
                    </HeadLineWrapper>{" "}
                    <DeveloperWrapper>
                        <HeadLineWrapper languagecode={currentLanguageCode} wspace={""} fsize={"17px"} linehg={"24px"} fweight={"700"}>
                            | {t("Constructed by Taisei")}
                        </HeadLineWrapper>
                    </DeveloperWrapper>
                </DeveloperWrapper>

                <DeveloperWrapper className="achievement-list-in-jp" width={"100%"} justify={"center"} height={"auto"} margin={"10px 0"}>
                    <DeveloperCarousel dots={true} afterChange={(index) => setCurrentAchivement(achivementsInJP[index].title)}>
                        {achivementsInJPComponent}
                    </DeveloperCarousel>
                </DeveloperWrapper>
            </DeveloperWrapper>
        </DeveloperSection>
    );
};

export default AchievementMobile;
