import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { DeveloperImage, DeveloperWrapper, HeadLineWrapper, ParagraphWrapper } from "../../../style/DeveloperScreen";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { useTranslation } from "react-i18next";

const DevelopmentMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <DeveloperWrapper id="developer" direction={"column"} bgcolor={"#f4f4f4"} align={"center"}>
            <DeveloperWrapper margin={"30px 22px"} direction={"column"} align={"center"}>
                <HeadLineWrapper languagecode={currentLanguageCode} tablet={"47px"} color={"#2C53AF"} align={"center"} wspace={""} fsize={"27px"} linehg={"36px"} fweight={"700"}>
                    {t("TAISEI DEVELOPMENT HANOI")}
                </HeadLineWrapper>
                <DeveloperWrapper margin={"20px 0 0 0"} width={"100%"} height={"80%"} bgcolor={"#fff"} border={"solid 1px #707070"}>
                    <DeveloperImage className="thd-team" src="img/THD-team.png" height={"100%"} fit={"cover"} transform={""} />
                </DeveloperWrapper>
                <ParagraphWrapper languagecode={currentLanguageCode} linehgtablet={"27px"} margin={"20px 0 0 0"} align={"justify"} fsize={"13px"} linehg={"21px"}>
                    {t("TAISEI DEVELOPMENT HANOI CONTENT")}
                </ParagraphWrapper>
                <DeveloperWrapper margin={"20px 0 0 0"}>
                    <Link to="/contact">
                        <ContactUsButtonComponent width={"190px"} text={t("CONTACT US")} hovered />
                    </Link>
                </DeveloperWrapper>
            </DeveloperWrapper>
        </DeveloperWrapper>
    );
};

export default DevelopmentMobile;
