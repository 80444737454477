import PropTypes from "prop-types";
import { ContactUsButton, ContactUsText } from "../style/Button";
import { useTranslation } from "react-i18next";

const ContactUsButtonComponent = ({ text, width = "170px", color = "#323b4a" }) => {
    const { i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <ContactUsButton width={width} color={color}>
            <ContactUsText languagecode={currentLanguageCode} color={color} width={width}>
                {text}
            </ContactUsText>
        </ContactUsButton>
    );
};

ContactUsButtonComponent.propTypes = {
    text: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string
};

export default ContactUsButtonComponent;
