{
    "NEWS & EVENTS": "NEWS & EVENTS",
    "NEWS": "ニュース",
    "PAST NEWS": "過去のニュース",
    "READ MORE": "詳細",
    "PREV": "PREV",
    "NEXT": "NEXT",
    "UPCOMING LEASING EVENT": "今後のイベント",
    "PAST EVENTS": "過去のイベント"
}
