import React, { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { TextAnimation } from "../../../breakpoints";
import { animated } from "@react-spring/web";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { OverviewBuildingProperty, OverviewBuildingPropertyDescription, OverviewBuildingPropertyImage, OverviewSectionWrapper, OverviewMainTextWrapper, OverviewImage, OverviewGrid, OverviewDisplayJustify, OverviewDisplay, OverviewParagraphs, OverviewGroupContent } from "../../../style/OverviewScreen";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const OverviewProperties = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    return (
        <OverviewGrid template={"220px 220px 220px 220px"}>
            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/office.png" height="100px" width="100px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode}>{parse(t("18floors"))}</OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>

            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/floorplate.png" height="100px" width="100px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode}>{parse(t("Floorplate"))}</OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>

            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/retail.png" height="100px" width="100px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode}>{parse(t("2floors"))}</OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>

            <OverviewBuildingProperty>
                <OverviewGroupContent>
                    <OverviewBuildingPropertyImage src="./img/basement.png" height="100px" width="100px" />
                </OverviewGroupContent>
                <OverviewBuildingPropertyDescription languagecode={currentLanguageCode}>{parse(t("4basement"))}</OverviewBuildingPropertyDescription>
            </OverviewBuildingProperty>
        </OverviewGrid>
    );
};

const OverviewSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const [inViewRef, inView] = useInView();
    const descriptionSpring = TextAnimation(inView);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    return (
        <OverviewSectionWrapper id="overview" ref={sectionRef}>
            <OverviewMainTextWrapper ref={inViewRef}>
                <OverviewDisplay direction={"row"} margin={"100px 82px 60px 0"}>
                    <OverviewDisplayJustify width={"62%"}>
                        <OverviewImage height="586px" width="779px" cursor={"pointer"} alt="overview.png" src="./img/overview.png" className="imgfrontfacede" />
                    </OverviewDisplayJustify>
                    <OverviewDisplayJustify padding={"0 0 0 35px"} direction={"column"} width={"38%"} justify={"start"} align={"flex-start"}>
                        <OverviewParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} fsize={"47px"} linehg={"47px"} as={animated.div} style={descriptionSpring}>
                            {t("OVERVIEW")}
                        </OverviewParagraphs>

                        <OverviewParagraphs languagecode={currentLanguageCode} padding={"30px 0"} align={"left"} fweight={"400"} fsize={"16px"} linehg={"24px"} as={animated.div} style={descriptionSpring}>
                            {t("OVERVIEW DESCRIPTION")}
                            <OverviewDisplayJustify padding={"30px 0 0 0"}>
                                <Link to="/contact">
                                    <ContactUsButtonComponent text={t("CONTACT US")} />
                                </Link>
                            </OverviewDisplayJustify>
                        </OverviewParagraphs>
                    </OverviewDisplayJustify>
                </OverviewDisplay>
                <OverviewDisplayJustify width={"100%"}>
                    <OverviewProperties />
                </OverviewDisplayJustify>
            </OverviewMainTextWrapper>
        </OverviewSectionWrapper>
    );
};

OverviewSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default OverviewSection;
