{
    "A JAPANESE DEDICATION TO WORKPLACE AND WELLBEING OF THE PROFESSIONALS": "A JAPANESE DEDICATION TO WORKPLACE AND WELLBEING OF THE PROFESSIONALS",
    "The first LEED certified Grade A office building in West Hanoi": "The first LEED certified Grade A office building in West Hanoi",
    "OVERVIEW": "OVERVIEW",
    "OVERVIEW DESCRIPTION": "Taisei Square Hanoi is setting new standards for office market in the bustling Cau Giay area of West Hanoi. This international Grade A building is proudly 100% invested and developed by the prestige Japanese construction company - Taisei Corporation, which promises its clients non-compromised quality and professional services.",
    "18floors": "18 floors of office <br /> for lease",
    "Floorplate": "Floor plate of <br /> 1,300 m²",
    "2floors": "2 floors of retail <br /> podium",
    "4basement": "4 basement floors <br /> for parking",
    "PROJECT CONCEPT": "PROJECT CONCEPT",
    "PROJECT CONCEPT DESCRIPTION": "The Project aims to offer local market a comfortable yet sustainable workplace of Japan-quality. Taisei's international subordinate companies joined hands as a team through the investment, design, construction, and management of the Project. Together with other Japanese maintenance service providers and a professional operation team, Taisei Square Hanoi promises its users true Japan experiences.",
    "BUILDING DESIGN CONCEPT": "BUILDING DESIGN CONCEPT",
    "BUILDING DESIGN CONCEPT DESCRIPTION": "Inspired by the refined and restrained design of Japanese architecture, characterized by horizontal and vertical lines underpinned by craftsmanship of Japan, this office space embodies a sense of tranquility and warmth. Set within a monochromatic formal ambiance, the design integrates wood and stone pattern materials, imparting a harmonious blend of serenity and inviting comfort.",
    "LOGO CONCEPT": "NAME & LOGO CONCEPT",
    "LOGO CONCEPT DESCRIPTION": "<p>&quot;SQUARE&quot; represents the simple building shape and the hub function of the western area of Hanoi.</p><p style='padding-top: 10px;'>The “Red dot” represents the Japanese flag, and the letter &quot;T&quot; represents the T-Square, an essential architectural tool in the analog era. It contains the desire to create an office environment of Japan-quality while valuing the spirit of manufacturing.</p>",
    "LOCATION": "LOCATION",
    "LOCATION DESCRIPTION": "Facing Ring Road No.3, Taisei Square Hanoi situated in a prime location that offers outstanding connectivity to other commercial and business hubs in Hanoi as well as Noi Bai international Airport and major industrial parks.",
    "30 MINS": "30 MINS",
    "NOI BAI INTERNATIONAL AIRPORT": "to <br /> Noi Bai <br /> International Airport",
    "HOAN KIEM DISTRICT": "to <br /> Hoan Kiem District",
    "15 MINS": "15 MINS",
    "DONG DA AND BA DINH DISTRICT": "to <br /> Dong Da and <br /> Ba Dinh District",
    "3 MINS": "3 MINS",
    "LANG HOA LAC HIGHWAY": "to <br /> Lang - Hoa Lac <br /> Highway",
    "CONTACT US": "CONTACT US",
    "EXPLORE": "EXPLORE"
}
