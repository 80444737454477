import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VirtualTourDisplay, TitleWrapper, VirtualTourGrid, VirtualTourGridItem, ModalContent, VirtualTourImage, SliderShowVirtualTour } from "../../../style/VirtualTourScreen";
import { Modal as ModalGallery } from "antd";
import { useTranslation } from "react-i18next";

const GalleryMobile = ({ hasMatterport, datas }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const carouselRef = useRef(null);
    const galleries = datas ? datas.galleries : [];
    const interiorCount = datas ? datas.interiorCount : 12;
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const openSlide = (index) => {
        setTimeout(() => {
            carouselRef.current.goTo(index, true);
        }, 10);
        setModalOpen(true);
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const handleCancel = () => {
        setModalOpen(false);
    };

    return (
        <VirtualTourDisplay id="gallery">
            <TitleWrapper languagecode={currentLanguageCode} color={"#353535"} fsize={"32px"} lineheight={"35px"} padding={hasMatterport ? "30px 0" : "150px 0 30px 0"}>
                {t("PHOTO GALLERY")}
            </TitleWrapper>
            <VirtualTourGrid className="gallery-grid">
                <VirtualTourGridItem className="INTERIOR" bgcolor={"#A5D9FB"}>
                    <TitleWrapper languagecode={currentLanguageCode} tablet={"18px"} padding={"10px 20px"} color={"#f4f4f4"} fsize={"17px"} fweight={"700"} lineheight={"20px"}>
                        {t("INTERIOR")}
                    </TitleWrapper>
                </VirtualTourGridItem>
                {galleries.map((image, index) =>
                    index !== interiorCount ? (
                        <VirtualTourImage className={`virtual-image-${index}`} border="none" key={index} width={"100%"} height={"100px"} src={`${process.env.REACT_APP_CMS}${image.imageUrl}`} onClick={() => openSlide(index)} alt={image.imageUrl} />
                    ) : (
                        <>
                            <VirtualTourGridItem className="EXTERIOR" key={"exterior"} bgcolor={"#A5D9FB"}>
                                <TitleWrapper languagecode={currentLanguageCode} tablet={"18px"} padding={"10px 20px"} color={"#f4f4f4"} fsize={"17px"} fweight={"700"} lineheight={"20px"}>
                                    {t("EXTERIOR")}
                                </TitleWrapper>
                            </VirtualTourGridItem>
                            <VirtualTourImage className={`virtual-image-${index}`} border="none" key={index} width={"100%"} height={"100px"} src={`${process.env.REACT_APP_CMS}${image.imageUrl}`} onClick={() => openSlide(index)} alt={image.imageUrl} />
                        </>
                    )
                )}
            </VirtualTourGrid>

            <ModalGallery open={isModalOpen} onCancel={handleCancel} footer={null} width="100vw" padding={"0px"} zIndex={"999999999"} centered className="fullscreen-modal custom-modal" closeIcon={true}>
                <VirtualTourImage htablet={"76px"} wtablet={"76px"} width={"76px"} height={"76px"} src={"./img/arrow-left.png"} position={"absolute"} zindex={"999999999"} top={"50%"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.prev()} />
                <VirtualTourImage htablet={"76px"} wtablet={"76px"} width={"76px"} height={"76px"} src={"./img/arrow-right.png"} position={"absolute"} zindex={"999999999"} top={"50%"} right={"0"} transform={"translateY(-50%)"} onClick={() => carouselRef.current.next()} />

                <SliderShowVirtualTour dots={false} ref={carouselRef} waitForAnimate={false} autoplay={false}>
                    {galleries.map((image, index) => (
                        <ModalContent key={index}>
                            <VirtualTourImage htablet={"500px"} fit={"contain"} width="100%" height="100vw" src={`${process.env.REACT_APP_CMS}${image.imageUrl}`} alt={`Image ${index}`} />
                        </ModalContent>
                    ))}
                </SliderShowVirtualTour>
            </ModalGallery>
        </VirtualTourDisplay>
    );
};

GalleryMobile.propTypes = {
    hasMatterport: PropTypes.bool,
    datas: PropTypes.object
};

export default GalleryMobile;
