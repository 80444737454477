{
    "NEWS & EVENTS": "TIN TỨC & SỰ KIỆN",
    "NEWS": "TIN TỨC",
    "PAST NEWS": "TIN ĐÃ ĐĂNG",
    "READ MORE": "CHI TIẾT",
    "PREV": "TRƯỚC",
    "NEXT": "SAU",
    "UPCOMING LEASING EVENT": "SỰ KIỆN CHO THUÊ MỚI NHẤT",
    "PAST EVENTS": "SỰ KIỆN ĐÃ DIỄN RA"
}
