import React, { useEffect } from "react";
import ReactDOMClient from "react-dom/client";
import OverviewScreen from "./screens/Overview";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TheBuildingScreen from "./screens/TheBuilding";
import LeasingScreen from "./screens/Leasing";
import ContactScreen from "./screens/Contact";
import DeveloperScreen from "./screens/Developer";
import VirtualTourScreen from "./screens/VirtualTour";
import MediaScreen from "./screens/Media";
import PrivacyPolicy from "./screens/Policy";
import "./i18n";
import { LanguageProvider } from "./components/LanguageContext";
import Header from "./components/Header/Header";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const App = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <LanguageProvider>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<OverviewScreen />} />
                    <Route exact path="/overview" element={<OverviewScreen />} />
                    <Route exact path="/thebuilding" element={<TheBuildingScreen />} />
                    <Route exact path="/leasing" element={<LeasingScreen />} />
                    <Route exact path="/virtualtour" element={<VirtualTourScreen />} />
                    <Route exact path="/developer" element={<DeveloperScreen />} />
                    <Route exact path="/media" element={<MediaScreen />} />
                    <Route exact path="/contact" element={<ContactScreen />} />
                    <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
                </Routes>
            </BrowserRouter>
        </LanguageProvider>
    );
};

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<App />);
