import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
//header & footer
import dataHeaderVI from "./lng/locales/header/vi/translation.json";
import dataHeaderEN from "./lng/locales/header/en/translation.json";
import dataHeaderJP from "./lng/locales/header/jp/translation.json";
//overview
import dataOverviewLngVN from "./lng/locales/overview/vi/translation.json";
import dataOverviewLngEN from "./lng/locales/overview/en/translation.json";
import dataOverviewLngJP from "./lng/locales/overview/jp/translation.json";
//thebuilding
import dataTheBuildingLngVN from "./lng/locales/thebuilding/vi/translation.json";
import dataTheBuildingLngEN from "./lng/locales/thebuilding/en/translation.json";
import dataTheBuildingLngJP from "./lng/locales/thebuilding/jp/translation.json";
//leasing
import dataLeasingLngVN from "./lng/locales/leasing/vi/translation.json";
import dataLeasingLngEN from "./lng/locales/leasing/en/translation.json";
import dataLeasingLngJP from "./lng/locales/leasing/jp/translation.json";
//virtual tour
import dataVirtualTourVI from "./lng/locales/virtualtour/vi/translation.json";
import dataVirtualTourEN from "./lng/locales/virtualtour/en/translation.json";
import dataVirtualTourJP from "./lng/locales/virtualtour/jp/translation.json";
//developer
import dataDeveloperVI from "./lng/locales/developer/vi/translation.json";
import dataDeveloperEN from "./lng/locales/developer/en/translation.json";
import dataDeveloperJP from "./lng/locales/developer/jp/translation.json";
//media
import dataMediaVI from "./lng/locales/media/vi/translation.json";
import dataMediaEN from "./lng/locales/media/en/translation.json";
import dataMediaJP from "./lng/locales/media/jp/translation.json";
//contact
import dataContactVI from "./lng/locales/contact/vi/translation.json";
import dataContactEN from "./lng/locales/contact/en/translation.json";
import dataContactJP from "./lng/locales/contact/jp/translation.json";
const resources = {
    vi: {
        translation: {
            ...dataHeaderVI,
            ...dataVirtualTourVI,
            ...dataDeveloperVI,
            ...dataMediaVI,
            ...dataContactVI,
            ...dataOverviewLngVN,
            ...dataTheBuildingLngVN,
            ...dataLeasingLngVN
        }
    },
    en: {
        translation: {
            ...dataHeaderEN,
            ...dataVirtualTourEN,
            ...dataDeveloperEN,
            ...dataMediaEN,
            ...dataContactEN,
            ...dataOverviewLngEN,
            ...dataTheBuildingLngEN,
            ...dataLeasingLngEN
        }
    },
    jp: {
        translation: {
            ...dataHeaderJP,
            ...dataVirtualTourJP,
            ...dataDeveloperJP,
            ...dataMediaJP,
            ...dataContactJP,
            ...dataOverviewLngJP,
            ...dataTheBuildingLngJP,
            ...dataLeasingLngJP
        }
    }
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        ns: ["common", "translation"],
        lng: "en",
        fallbackLng: "en",
        resources
    });

export default i18n;
