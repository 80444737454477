import React, { useState } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Policy from "./PrivacyPolicy";
import { useWindowWidth } from "../../breakpoints";
import { useLanguage } from "../../components/LanguageContext";

const PrivacyPolicy = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);
    const { selectedLang } = useLanguage();

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };

    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout isPolicyLayout={true} screenWidth={screenWidth} screenHeight={`${totalHeight + 250}px`}>
                    <Policy.PrivacyPolicy onHeightChange={handleHeightChange} languageCode={selectedLang} />
                </DesktopLayout>
            ) : (
                <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                    <Policy.PolicyMobile languageCode={selectedLang} />
                </DesktopLayout>
            )}
        </>
    );
};

export default PrivacyPolicy;
