export const sustainabilitys = [
    {
        key: "construction",
        icon: {
            title: "Eco Construction",
            normal: "./img/construction.png",
            activated: "./img/construction-h.png",
            className: ""
        },
        image: "./img/sus-eco-construction.png",
        title: "Eco Construction Title",
        description: "Eco Construction Description"
    },
    {
        key: "energy-efficiency",
        icon: {
            title: "Energy Efficiency",
            normal: "./img/sus-renewable-energy.png",
            activated: "./img/sus-renewable-energy-h.png",
            className: ""
        },
        image: "./img/sus-lowe-glass.png",
        title: "Energy Efficiency Title",
        description: "Energy Efficiency Description"
    },
    {
        key: "water",
        icon: {
            title: "Water Efficiency",
            normal: "./img/sus-save-water.png",
            activated: "./img/sus-save-water-h.png",
            className: ""
        },
        image: "./img/sus-water.png",
        title: "Water Efficiency Title",
        description: "Water Efficiency Description"
    },
    {
        key: "accessible",
        icon: {
            title: "Accessible Design",
            normal: "./img/sus-disabilities.png",
            activated: "./img/sus-disabilities-h.png",
            className: ""
        },
        image: "./img/sus-access.png",
        title: "Accessible Design Title",
        description: "Accessible Design Description"
    }
];
