import React, { useState, useEffect } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import { useWindowWidth } from "../../breakpoints";
import * as MobileSections from "./Mobile.Sections";
import { getMatterPort, getGallery } from "../../api/virtualTour";
import { useLanguage } from "../../components/LanguageContext";

const VirtualTourScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);
    const [matterPortUrl, setMatterPortUrl] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [dataGallery, setDataGallery] = useState(null);
    const { selectedLang } = useLanguage();

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };

    const getDataVirtualTour = async () => {
        const matterUrl = await getMatterPort();
        if (matterUrl) {
            setMatterPortUrl(matterUrl);
        }
        const data = await getGallery();
        if (data) {
            setDataGallery(data);
        }
        setDataLoaded(true);
    };

    useEffect(() => {
        if (!dataLoaded) {
            getDataVirtualTour();
        }
    }, [selectedLang, dataLoaded]);

    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight + 160}px`}>
                    {matterPortUrl ? <Sections.VirtualTourSection onHeightChange={handleHeightChange} matterPortUrl={matterPortUrl} /> : null}
                    <Sections.GallerySection onHeightChange={handleHeightChange} selectedLanguage={selectedLang} hasMatterport={matterPortUrl ? true : false} datas={dataGallery} />
                </DesktopLayout>
            ) : (
                <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                    {matterPortUrl ? <MobileSections.VirtualTourMobile matterPortUrl={matterPortUrl} /> : null}
                    <MobileSections.GalleryMobile hasMatterport={matterPortUrl ? true : false} datas={dataGallery} />
                </DesktopLayout>
            )}
        </>
    );
};

export default VirtualTourScreen;
